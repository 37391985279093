<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="first"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核<el-badge :value="waitDataTotal" class="item" /></div> -->
        <waitNumber :waitDataTotalNumber="waitDataTotal"></waitNumber>
        <div class="titleTopCenterLeftBox" @click="reload">新建患者</div>
        <div style="padding: 0 20px" v-if="todayStepIndex == 1">
          <el-input placeholder="搜索今日患者" prefix-icon="el-icon-search" v-model="todayPatient" clearable @keyup.enter.native="getTodayPatientData">
            <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="getTodayPatientData"> </el-button>
          </el-input>
        </div>
        <!-- <div style="padding: 0 20px" v-if="todayStepIndex == 2">
          <el-input placeholder="搜索暂存列表" prefix-icon="el-icon-search" v-model="todayPatient1" clearable>
            <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="getMZDiagnoseListPatientData"> </el-button>
          </el-input>
        </div> -->
        <div v-if="stepIndex == 1">
          <div class="noflex-l-s">
            <div :class="todayStepIndex == 1 ? 'todayTitle' : 'todayTitleno'" @click="gotodayChange(1)"><i class="el-icon-user-solid"></i>今日就诊</div>
            <div :class="todayStepIndex == 2 ? 'todayTitle' : 'todayTitleno'" @click="gotodayChange(2)"><i class="el-icon-user-solid"></i>暂存列表</div>
          </div>

          <div class="todayDetail" v-if="todayStepIndex == 1">
            <div class="todayTitleTh todayTitleThTtitle">
              <div class="thwidth">患者姓名</div>

              <div class="thwidth">最近就诊</div>
            </div>
            <div v-if="todayPatientList.length > 0">
              <div class="todayTitleTh todayTitleThDetail" v-for="(itemToday, indexToday) in todayPatientList" :key="indexToday" @click="goTodayDetail(itemToday)">
                <div class="thwidth">{{ itemToday.patientName }}</div>

                <div class="thwidth" style="font-size: 14px">{{ itemToday.latestTime }}</div>
              </div>
            </div>
            <div v-else>
              <div class="todayTitleTh todayTitleThDetail">
                <div class="thwidth">暂无数据</div>
              </div>
            </div>
          </div>

          <div class="todayDetail" v-if="todayStepIndex == 2">
            <div class="todayTitleTh todayTitleThTtitle">
              <div class="thwidth">患者姓名</div>
              <!-- <div class="thwidth">就诊次数</div> -->
              <div class="thwidth">最近就诊</div>
            </div>
            <div v-if="ZCPatientList.length > 0">
              <div class="todayTitleTh todayTitleThDetail" v-for="(itemzc, indexzc) in ZCPatientList" :key="indexzc" @click="goZcDetail(itemzc)">
                <div class="thwidth">{{ itemzc.patient.name }}</div>
                <!-- <div class="thwidth">1</div> -->
                <div class="thwidth" style="font-size: 14px">{{ itemzc.addTime }}</div>
              </div>
              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="ZCpatientSize" @current-change="ZChandleCurrentChange" :current-page.sync="ZCpatientcurrentPage" :total="ZCpatientTotal"> </el-pagination>
              </div>
            </div>
            <div v-else>
              <div class="todayTitleTh todayTitleThDetail">
                <div class="thwidth">暂无数据</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="stepIndex > 1">
          <div class="flex-l-b titleTopLeftTable">
            <div :class="leftTable == 1 ? 'titleTopLeftTableC' : 'notitleTopLeftTableC'" @click="goleft2Patient(1)"><i class="el-icon-user-solid"></i>患者信息</div>
            <div :class="leftTable == 2 ? 'titleTopLeftTableC' : 'notitleTopLeftTableC'" @click="goleft2Patient(2)"><i class="el-icon-s-order"></i>患者历史</div>
          </div>
          <div v-if="leftTable == 1" class="titleTopLeftTable" style="border: none">
            <div class="flex-l">
              <div class="titleTopLeftTableTag"></div>
              <div style="color: #999999">患者基本信息</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px">
              <div></div>
              <div>
                <span>{{ patientDetail.name }}</span
                >，<span>{{ patientDetail.sex ? "男" : "女" }}</span
                >，<span>{{ patientDetail.age }}岁</span>
                <!-- ,<span>初诊</span> -->
              </div>
              <div v-if="patientDetail.diseaseName">基础病:{{ patientDetail.diseaseName }}</div>
            </div>
            <div v-if="showCheckItemsList.length > 0" class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div style="color: #999999">检查治疗项</div>
            </div>
            <div class="flex-l-s checkItemsBox" style="margin-left: 15px; margin-top: 5px" v-for="(itemshowcheckItems, indexshow) in showCheckItemsList" :key="indexshow">
              <div>{{ itemshowcheckItems.checkItemValue.itemName }}</div>
              <!-- <el-input v-model="checkItemsValue" class="stepInput" ></el-input> -->
              <div>{{ itemshowcheckItems.checkItemsNum }}次</div>
              <div>￥{{ itemshowcheckItems.tradingPrice }}</div>
            </div>
            <div v-if="stepIndex > 2">
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">体格检查</div>
              </div>

              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div class="flex-l" style="flex-wrap: wrap">
                  <div style="margin-right: 5px">
                    <span>体温：</span><span>{{ patientFormDes.temperature ? patientFormDes.temperature : "-" }} ℃</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>体重：</span><span>{{ patientFormDes.weight ? patientFormDes.weight : "-" }}kg</span>
                  </div>
                  <!-- <div style="margin-right: 5px">
                    <span>心率：</span><span>{{ patientFormDes.heartRate ? patientFormDes.heartRate : "-" }}次/分</span>
                  </div> -->
                  <div style="margin-right: 5px">
                    <span>血压：</span><span>{{ patientFormDes.heightTension ? patientFormDes.heightTension : "-" }}/{{ patientFormDes.lowTension ? patientFormDes.lowTension : "-" }}mmHg</span>
                  </div>
                </div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div class="flex-l" style="flex-wrap: wrap">
                  <div style="margin-right: 5px">
                    <span>身高：</span><span>{{ patientFormDes.height ? patientFormDes.height : "-" }}CM</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>呼吸：</span><span>{{ patientFormDes.breathe ? patientFormDes.breathe : "-" }}次/分</span>
                  </div>
                  <div style="margin-right: 5px">
                    <span>脉搏：</span><span>{{ patientFormDes.pulse ? patientFormDes.pulse : "-" }}次/分</span>
                  </div>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">诊断</div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div>
                  <span v-for="(item, index) in patientFormDes.diseaseDiagnose" :key="index">{{ item }}</span>
                  <!-- <span v-for="(item,index) in patientFormDes.diseaseDiagnose" :key="index">{{ patientFormDes.diseaseDiagnose ? patientFormDes.diseaseDiagnose : "--" }}</span> -->
                </div>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">病史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">家族病史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div>{{ patientFormDes.family ? patientFormDes.family : "--" }}</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">既往史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div>{{ patientFormDes.past ? patientFormDes.past : "--" }}</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div class="titleTopLeftTableTag1"></div>
                <div style="color: #999999">过敏史</div>
              </div>
              <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                <div>
                  <span>{{ patientFormDes.irritability ? patientFormDes.irritability : "--" }}</span>
                </div>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">主诉</div>
              </div>
              <div style="margin-left: 15px; margin-top: 5px">
                <div></div>
                <div>
                  <span>{{ patientFormDes.main ? patientFormDes.main : "--" }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="leftTable == 2">
            <div class="todayDetail" style="height: 400px; overflow-y: auto">
              <div class="todayTitleTh todayTitleThTtitle">
                <div class="thwidthL">就诊时间</div>
                <div class="thwidthL">诊断结果</div>
              </div>
              <div v-if="patientDetail.patientDiagnose.length != 0">
                <div class="todayTitleTh todayTitleThDetail titleTopLeftTableC" style="padding: 10px 5px" v-for="(item, index) in patientDetail.patientDiagnose" :key="index" @click="godetailNext(item, index)">
                  <div class="thwidthL">{{ item.addTime }}</div>
                  <div class="thwidthL">{{ item.diseaseDiagnose }}</div>
                </div>
              </div>

              <div v-else class="todayTitleTh todayTitleThDetail titleTopLeftTableC" style="padding: 10px 5px">暂无数据</div>
            </div>
            <div class="thirdrightBox" v-if="patientDetail.patientDiagnose.length != 0">
              <div class="flex-l">
                <div class="titleTopLeftTableTag"></div>
                <div style="color: #999999">诊断</div>
              </div>
              <div style="padding: 10px 0">
                <i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px" @click="goAdddiseaseDiagnose(patientDetail.patientDiagnose[diseaseIndex].diseaseDiagnose)"></i>
                {{ patientDetail.patientDiagnose[diseaseIndex].diseaseDiagnose }}
              </div>
            </div>
          </div>
          <div style="text-align: center" v-if="stepIndex == 2">
            <el-button type="primary" round @click="goCheckItems">检查治疗项</el-button>
          </div>
        </div>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop flex-l-s">
          <!-- @click="goNextStep(1)" -->
          <div class="flex-l hardClick">
            <div :class="stepIndex > 0 ? 'sortBox' : 'nosortBox'">01</div>
            <div :class="stepIndex > 0 ? 'sortBoximg' : 'nosortBoximg'">填写患者信息</div>
          </div>
          <!-- @click="goNextStep(2)" -->
          <div class="flex-l hardClick">
            <div :class="stepIndex > 1 ? 'sortBox' : 'nosortBox'">02</div>
            <div :class="stepIndex > 1 ? 'sortBoximg' : 'nosortBoximg'">中医诊断</div>
          </div>
          <!-- @click="goNextStep(3)" -->
          <div class="flex-l hardClick">
            <div :class="stepIndex > 2 ? 'sortBox' : 'nosortBox'">03</div>
            <div :class="stepIndex > 2 ? 'sortBoximg' : 'nosortBoximg'">开具处方</div>
          </div>
        </div>
        <div class="titleTopCenterCenterContent" v-if="stepIndex == 1">
          <div class="Content1Title" style="padding-left: 20%">基本资料</div>
          <div class="Content1Title" style="padding: 10px 20%">
            <el-form :model="patientForm" ref="patientForm" :rules="patientFormRules" label-position="top">
              <div class="flex-l" style="width: 80%">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="patientForm.name"></el-input>
                </el-form-item>
                <el-form-item label="年龄" prop="age" style="margin-left: 20px">
                  <div class="flex-l">
                    <el-input style="width: 30%; margin-right: 10px" v-model="patientForm.age"></el-input>
                    <el-select v-model="ageValue" placeholder="请选择" style="width: 70px">
                      <el-option v-for="item in ageOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="性别" prop="sex" style="margin-left: -90px">
                  <div class="flex-l-s" @click="goAddPatientSex(patientForm.sex)">
                    <div :class="patientForm.sex == true ? 'typeChecked' : 'notypeChecked'">男</div>
                    <div :class="patientForm.sex == false ? 'typeChecked' : 'notypeChecked'" style="margin-left: 10px">女</div>
                  </div>
                </el-form-item>
              </div>
              <el-form-item label="身份证号" prop="idNo" style="margin-top: 10px">
                <div class="flex-l"><el-input class="" v-model="patientForm.idNo" style="margin-right: 10px"></el-input><el-button type="primary" @click.prevent="goIdDomain">读取身份证</el-button></div>
              </el-form-item>
              <el-form-item
                prop="phone"
                label="电话号码"
                style="margin-top: 10px"
                :rules="[
                  { required: true, message: '请输入电话号码', trigger: 'blur' },
                  { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' },
                ]"
              >
                <el-input v-model="patientForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="address" style="margin-top: 10px">
                <el-input v-model="patientForm.address"></el-input>
              </el-form-item>
              <el-form-item label="基础病" prop="diseaseName" style="margin-top: 10px">
                <el-input v-model="patientForm.diseaseName"></el-input>
              </el-form-item>
              <el-form-item style="text-align: right; margin-top: 20px">
                <el-button type="primary" @click="refPatientForm">重置</el-button>
                <el-button v-if="patientForm.id" type="primary" @click="goaddPatientForm">下一步</el-button>
                <el-button v-else type="primary" @click="addPatientForm('patientForm')">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="titleTopCenterCenterContent" v-if="stepIndex == 2">
          <div class="Content1Title" style="padding-top: 10px">
            <el-form class="patientDiv" :model="patientFormDes" ref="patientFormDes" label-position="top">
              <div class="">症状诊断</div>
              <div class="flex-l-b">
                <el-form-item prop="main" label="主诉/现病史" style="width: 100%">
                  <el-input type="textarea" autosize v-model="patientFormDes.main"></el-input>
                </el-form-item>
                <el-form-item prop="irritability" label="过敏史" style="width: 100%; margin-left: 80px">
                  <el-input v-model="patientFormDes.irritability"></el-input>
                </el-form-item>
              </div>
              <div class="flex-l-b">
                <el-form-item prop="past" label="既往史" style="width: 100%">
                  <el-input v-model="patientFormDes.past"></el-input>
                </el-form-item>

                <el-form-item prop="family" label="家族史" style="width: 100%; margin-left: 80px">
                  <el-input v-model="patientFormDes.family"></el-input>
                </el-form-item>
              </div>
              <el-form-item prop="diseaseDiagnose" label="鉴别分析">
                <el-tag  type="info" style="white-space:wrap !important;height:auto !important;line-height:25px !important;padding:5px 10px !important;margin-bottom: 5px" closable v-for="(item,i) in patientFormDes.discriminatoryAnalysisList" :key="i" @close="handleClose(item)">{{item}}</el-tag>
              </el-form-item>
              <el-form-item prop="diseaseDiagnose" label="诊断" :rules="[{ required: true, message: '请输入诊断信息', trigger: 'blur' }]">
                <!-- <el-input  v-model="patientFormDes.diseaseDiagnose"></el-input> -->
                <el-select v-model="patientFormDes.diseaseDiagnose" multiple filterable allow-create remote default-first-option :remote-method="remoteMethod" @change="diseasChange" value-key="diseaseKey" placeholder="请选择疾病名称" style="width: 45%">
                  <el-option v-for="item in diseasOptions" :key="item.diseaseKey" :label="item.diseaseName" :value="item"> </el-option>
                </el-select>
              </el-form-item>
              <div class="flex-l" style="margin-top: 30px">
                <div>体格检查</div>
                <el-button size="mini" type="primary" :icon="Showtemperature ? 'el-icon-minus' : 'el-icon-plus'" style="margin-left: 10px" @click="goShowtemperature"> </el-button>
              </div>
              <div class="flex-l-s" v-if="Showtemperature">
                <el-form-item prop="temperature" label="体温">
                  <div class="flex-l"><el-input class="stepInput" v-model="patientFormDes.temperature" placeholder="0"></el-input>℃</div>
                </el-form-item>
                <el-form-item prop="weight" label="体重">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.weight" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">KG</div>
                  </div>
                </el-form-item>
                <el-form-item prop="height" label="身高">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.height" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">CM</div>
                  </div>
                </el-form-item>
                <!-- <el-form-item prop="heartRate" label="心率">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.heartRate" class="stepInput"></el-input>
                    <div class="inputTag">次/分</div>
                  </div>
                </el-form-item> -->
                <el-form-item prop="breathe" label="呼吸">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.breathe" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">次/分</div>
                  </div>
                </el-form-item>
                <el-form-item prop="pulse" label="脉搏">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.pulse" placeholder="0" class="stepInput"></el-input>
                    <div class="inputTag">次/分</div>
                  </div>
                </el-form-item>
              </div>
              <div class="flex-l-b" v-if="Showtemperature">
                <el-form-item prop="heightTension" label="血压">
                  <div class="flex-l">
                    <el-input v-model="patientFormDes.heightTension" placeholder="0" class="stepInput"></el-input>/<el-input v-model="patientFormDes.lowTension" placeholder="0" class="stepInput" style="margin-left: 10px"></el-input>
                    <div class="inputTag">mmHg</div>
                  </div>
                </el-form-item>
                <el-form-item prop="otherCheck" label="其他体格检查" style="width: 100%; margin-left: 80px">
                  <el-input type="textarea" v-model="patientFormDes.otherCheck"></el-input>
                </el-form-item>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div>症状照片</div>
                <el-button size="mini" type="primary" :icon="ShowPhoto ? 'el-icon-minus' : 'el-icon-plus'" style="margin-left: 10px" @click="goShowPhoto"> </el-button>
              </div>
              <el-dialog :visible.sync="fitDialogVisible">
                <img width="100%" :src="Services.Authorization + fitDialogVisibleImgUrl" alt="" />
              </el-dialog>
              <div v-show="ShowPhoto">
                <div style="font-size: 14px; font-weight: 400; padding-bottom: 10px; padding-top: 10px">最多上传5张图片，最多不超过20M</div>
                <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card" :limit="5" multiple :on-preview="DiagnoseImagePreview" :on-remove="DiagnoseImageRemove" :on-success="DiagnoseImageUpload" :file-list="ImageContentList">
                  <i class="el-icon-plus"></i>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                </el-upload>
                <div class="flex-l">
                  <div v-for="fit in ShowPhotoList" :key="fit" @click="goShowPhotocfCliclk(fit)" style="margin-right: 10px">
                    <el-image style="width: 148px; height: 148px" :src="Services.Authorization + fit.filePath"></el-image>
                  </div>
                </div>

                <el-dialog :visible.sync="Image.dialogVisible">
                  <img width="100%" :src="Image.dialogImageUrl" alt="" />
                </el-dialog>
              </div>
              <div class="flex-l" style="margin-top: 10px">
                <div>上传处方图片</div>
                <el-button size="mini" type="primary" :icon="ShowPhotocf ? 'el-icon-minus' : 'el-icon-plus'" style="margin-left: 10px" @click="goShowPhotocf"> </el-button>
              </div>
              <div v-show="ShowPhotocf">
                <div style="font-size: 14px; font-weight: 400; padding-bottom: 10px; padding-top: 10px">最多上传5张图片，最多不超过20M</div>
                <div class="flex-l">
                  <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card" :limit="5" multiple :on-preview="handleImagePreview" :on-remove="handleImageRemove" :on-success="handleImageUpload" :file-list="ImageContentList">
                    <i class="el-icon-plus"></i>
                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                  </el-upload>
                </div>
                <el-dialog :visible.sync="Image.dialogVisible">
                  <img width="100%" :src="Image.dialogImageUrl" alt="" />
                </el-dialog>
                <div class="flex-l">
                  <div v-for="fit in ShowPhotocfList" :key="fit" @click="goShowPhotocfCliclk(fit)" style="margin-right: 10px">
                    <el-image style="width: 148px; height: 148px" :src="Services.Authorization + fit.filePath"></el-image>
                  </div>
                </div>
              </div>
              <el-form-item style="text-align: right; margin-top: 20px; padding-bottom: 40px">
                <el-button @click="alreadySubmitForm('patientFormDes')">暂存</el-button>
                <el-button type="primary" @click="goNextStep(1)">上一步</el-button>
                <el-button type="primary" @click="addSubmitForm('patientFormDes')">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 体格检查 -->
        <div class="titleTopCenterCenterContent" v-if="stepIndex == 3">
          <div class="Content1Title" style="padding: 10px 5%">
            <!-- :inline="true"  size='mini'-->
            <el-form :model="patientFormPre" ref="patientFormPre" label-position="top">
              <div class="">开具处方</div>
              <el-form-item prop="age" style="width: 100%">
                <div class="flex-l">
                  <div @click="go3tabClick(0)" :class="tab3ClickData == 0 ? 'tag3Checked' : 'tag3'"><i class="el-icon-plus"></i>方剂处方</div>
                  <div @click="go3tabClick(1)" :class="tab3ClickData == 1 ? 'tag3Checked' : 'tag3'"><i class="el-icon-plus"></i>成药处方</div>
                  <div @click="go3tabClick(2)" :class="tab3ClickData == 2 ? 'tag3Checked' : 'tag3'"><i class="el-icon-plus"></i>输液处方</div>
                  <div @click="goComtabClick" class="tag3">用药审查</div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="table3Box" v-if="tab3ClickData == 0">
                  <div class="flex-l-b titleTopright1 titleSize14">
                    <div></div>
                    <div style="margin-right: 15px" @click="goAddcompositionList">添加中药方剂</div>
                  </div>
                  <div v-for="(itemCom, indexCom) in patientFormPre.compositionList" :key="indexCom" style="margin-top: 20px">
                    <el-form-item :prop="'compositionList.' + indexCom + '.prescriptionName'" label="中药方剂名称" :rules="[{ required: true, message: '请输入中药方剂名称', trigger: 'blur' }]">
                      <div class="flex-l-b titleSize14" style="margin-bottom: 20px">
                        <el-input v-model="itemCom.prescriptionName" placeholder="请输入中药方剂名称" style="width: 40%"></el-input>
                        <div class="flex-l titleTopright1 titleSize14">
                          <!-- <div style="margin-right:15px" @click="goAddcompositionList">添加中药方剂</div> -->
                          <div @click="godeleteCompositionList(indexCom)">删除中药方剂</div>
                        </div>
                      </div>
                    </el-form-item>

                    <el-table :data="itemCom.mzPrescriptionDetail" border>
                      <el-table-column type="index" width="50"> </el-table-column>
                      <el-table-column prop="drugOrgId" align="center" label="成分" width="200">
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'compositionList.' + indexCom + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'"
                            :rules="[
                              { required: true, message: '请选择成分', trigger: 'blur' },
                              { pattern: /^(?!0$).*/, message: '请选择成分', trigger: 'blur' },
                            ]"
                          >
                            <div style="position: relative" class="drugOrgNameBox">
                              <el-select v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" @focus="handleFocus" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                                <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                              </el-select>
                              <div class="infoWarn" v-if="scope.row.drugOrgId == '0'" style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="weight" align="center" label="用量">
                        <template slot-scope="scope">
                          <el-form-item :prop="'compositionList.' + indexCom + '.mzPrescriptionDetail.' + scope.$index + '.weight'" :rules="[{ required: true, message: '请选择用量', trigger: 'blur' }]">
                            <div style="position: relative" class="drugOrgNameBox">
                              <el-input v-model="scope.row.weight" size="small" placeholder="用量(g)"> </el-input>
                              <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.weight > scope.row.inventoryNumber">
                                <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                              </el-tooltip>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="recommendDosage" align="center" label="推荐用量" width="120">
                        <template slot-scope="scope">
                          <div style="position: relative">
                            {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" align="center" label="炮制方法" width="120">
                        <template slot-scope="scope">
                          <div style="position: relative">
                            {{ scope.row.remark ? scope.row.remark : "--" }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="decoct" align="center" label="煎药方法">
                        <template slot-scope="scope">
                          <el-form-item>
                            <div class="drugOrgNameBox">
                              <el-select v-model="scope.row.decoct" size="mini" placeholder="煎药方法">
                                <el-option v-for="item in usageOption" :key="item" :label="item" :value="item"> </el-option>
                              </el-select>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column prop="remark" align="center" label="煎药方法">
                        <template slot-scope="scope">
                          <el-form-item>
                            <el-select v-model="scope.row.remark" size="mini" placeholder="煎药方法">
                              <el-option v-for="item in usageOption" :key="item" :label="item" :value="item"> </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column> -->
                      <!-- <el-table-column prop="remark" align="center" label="备注">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'compositionList.'+indexCom + '.mzPrescriptionDetail.'+scope.$index  +'.remark'"
                                    :rules="[{ required: true, message: '请输入备注信息', trigger: 'blur' }]"
                                >
                                <el-input  v-model="scope.row.remark" size="small"
                                        placeholder="输入备注信息">
                                    </el-input>
                            </el-form-item>
                              
                            </template>
                                    </el-table-column> -->
                      <el-table-column width="120" align="center">
                        <template slot-scope="scope">
                          <div class="flex-l">
                            <div>￥{{ scope.row.tradingPrice ? parseFloat(itemCom.dosage * (scope.row.weight ? parseFloat(scope.row.weight) : 0) * 1 * scope.row.tradingPrice).toFixed(2) : "0.00" }}</div>
                            <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="gondeletePre(indexCom, scope.$index)"></i>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPre(indexCom)">添加中药材</div>
                    <div class="flex-l" style="margin-top: 5px">
                      <el-form-item :prop="'compositionList.' + indexCom + '.dosage'" label="剂数" :rules="[{ required: true, message: '请输入剂数', trigger: 'blur' }]"> <el-input-number class="stepInput" v-model="itemCom.dosage" controls-position="right" :min="1"></el-input-number>剂 </el-form-item>

                      <el-form-item :prop="'compositionList.' + indexCom + '.dosageNumber'" label="每日剂量" :rules="[{ required: true, message: '请输入每日剂量', trigger: 'blur' }]">
                        <div class="flex-l"><el-input-number class="stepInput" v-model="itemCom.dosageNumber" controls-position="right" :min="1"></el-input-number>剂</div>
                      </el-form-item>

                      <el-form-item :prop="'compositionList.' + indexCom + '.frequency'" label="用药频率" :rules="[{ required: true, message: '请选择用药频率', trigger: 'blur' }]">
                        <div class="flex-l">
                          <el-select v-model="itemCom.frequency" placeholder="用药频率">
                            <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                          </el-select>
                          <!-- <el-input class="ageInput" v-model="patientFormPre.compositionList.frequency"></el-input>  -->
                        </div>
                      </el-form-item>
                    </div>
                    <div class="flex-l">
                      <el-form-item :prop="'compositionList.' + indexCom + '.usage'" label="用法" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                        <!-- <el-input  v-model="patientFormPre.compositionList[0].usage"></el-input>  -->
                        <el-select v-model="itemCom.usage" placeholder="用法">
                          <el-option v-for="item in usage" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :prop="'compositionList.' + indexCom + '.request'" label="服用要求" :rules="[{ required: true, message: '请输入服用要求', trigger: 'blur' }]" style="margin-left: 45px">
                        <!-- <div class="flex-l">
                                              <el-input  v-model="itemCom.request"></el-input> 
                                          </div> -->
                        <el-select v-model="itemCom.request" placeholder="服用要求">
                          <el-option v-for="item in requestOption" :key="item.id" :label="item.adviceContent" :value="item.adviceContent"> </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="isDenoction" label="是否代煎" style="margin-left: 45px">
                        <div class="flex-l" style="height: 40px">
                          <el-switch v-model="itemCom.isDenoction"></el-switch>
                        </div>
                      </el-form-item>
                      <el-form-item v-if="itemCom.isDenoction" prop="isdeliveryPrice" label="代煎价格" style="margin-left: 65px">
                        <div class="flex-l">￥{{ (parseFloat(itemCom.dosage) * parseFloat(itemCom.isdeliveryPrice)).toFixed(2) }}</div>
                      </el-form-item>
                    </div>
                    <!-- <div class="flex-l" style="margin-top: 5px">
                      <el-form-item prop="isDenoction" label="是否代煎">
                        <div class="flex-l">
                          <el-switch v-model="itemCom.isDenoction"></el-switch>
                        </div>
                      </el-form-item>
                    </div> -->

                    <el-form-item style="text-align: right; margin-top: 20px">
                      <!-- <el-button  @click="patientFormPreResetForm('patientFormPre')">重置</el-button> -->
                      <el-button type="primary" @click="addpatientFormPreForm(indexCom)">存为常用处方</el-button>
                    </el-form-item>
                  </div>
                </div>
                <div class="table3Box" v-if="tab3ClickData == 1">
                  <!-- <div class="flex-l-b titleTopright1 titleSize14">
                    <div></div>
                    <div style="margin-right: 15px" @click="goaddCzcompositionList">添加成药方剂</div>
                  </div> -->
                  <div v-for="(itemComCZ, indexComCZ) in patientFormPre.CZcompositionList" :key="indexComCZ" style="margin-top: 20px">
                    <div class="flex-l-b titleSize14" style="margin-bottom: 20px">
                      <el-input v-model="itemComCZ.prescriptionName" placeholder="请输入成药方剂名称" style="width: 40%"></el-input>
                      <div class="flex-l titleTopright1 titleSize14">
                        <!-- <div style="margin-right:15px" @click="goaddCzcompositionList">添加成药方剂</div>
                          <div @click="godeleteCzList(indexComCZ)">删除成药方剂</div> -->
                      </div>
                    </div>
                    <el-table :data="itemComCZ.mzPrescriptionDetail" border>
                      <el-table-column prop="drugOrgId" align="center" label="药品名称">
                        <template slot-scope="scope">
                          <el-form-item :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'" :rules="[{ required: true, message: '请选择药品', trigger: 'blur' }]">
                            <!-- <el-select v-model="scope.row.drugOrgId"
                                              filterable remote size="small" reserve-keyword placeholder="请输入成分"
                                              ref="selectLabel" :remote-method="SelectComposition"
                                              @change="ConstituteChange($event,scope.row)">
                                              <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                                  :label="item.drugOrgName" :value="item.drugOrgId">
                                              </el-option>
                                  </el-select> -->
                            <div style="position: relative">
                              <el-select v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请选择药品" ref="selectLabel" :remote-method="SelectComposition" @focus="handleFocus" @change="ConstituteChange($event, scope.row)">
                                <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                              </el-select>
                              <div class="infoWarn" v-if="scope.row.drugOrgId == '0'" style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="groupNumber" align="center" label="组号" width="120">
                        <template slot-scope="scope">
                          <el-form-item :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.groupNumber'">
                            <el-input v-model="scope.row.groupNumber" size="small" placeholder="输入组号"> </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="usage" align="center" label="用法" width="130">
                        <template slot-scope="scope">
                          <el-form-item :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.usage'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                            <el-select v-model="scope.row.usage" size="mini" placeholder="用法">
                              <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item"> </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="frequency" align="center" label="用药频率" width="150">
                        <template slot-scope="scope">
                          <el-form-item :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.frequency'" :rules="[{ required: true, message: '请选择用药频率', trigger: 'blur' }]">
                            <el-select v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                              <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                        <template slot-scope="scope">
                          <el-form-item :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.dosageList.dosage'" :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                            <el-input v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                              <el-select style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit" size="mini" placeholder="单位">
                                <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                              </el-select>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="defaultDosage" align="center" label="开药量" width="160">
                        <template slot-scope="scope">
                          <el-form-item :prop="'CZcompositionList.' + indexComCZ + '.mzPrescriptionDetail.' + scope.$index + '.defaultDosageList.defaultDosage'" :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                            <div style="position: relative">
                              <el-input v-model="scope.row.defaultDosageList.defaultDosage" size="small" placeholder="开药量" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')">
                                <el-select style="width: 75px" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size="mini" placeholder="单位">
                                  <el-option v-for="item in kgOption" :key="item" :label="item" :value="item" :disabled="true"> </el-option>
                                </el-select>
                              </el-input>
                              <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.defaultDosageList.defaultDosage > scope.row.inventoryNumber">
                                <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                              </el-tooltip>
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column width="100" align="center">
                        <template slot-scope="scope">
                          <div class="flex-l">
                            <div>￥{{ scope.row.tradingPrice ? parseFloat(scope.row.defaultDosageList.defaultDosage * 1 * scope.row.tradingPrice).toFixed(2) : "0.00" }}</div>
                            <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="gondeletePreCY(indexComCZ, scope.$index)"></i>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="titleSize14 titleTopright1" @click="gonaddPreCY(indexComCZ)">添加药品</div>
                    <el-form-item style="text-align: right; margin-top: 20px">
                      <!-- <el-button  @click="submitForm('patientForm')">重置</el-button> -->
                      <el-button type="primary" @click="addCyFormPreForm(indexComCZ)">存为常用处方</el-button>
                    </el-form-item>
                  </div>
                </div>
                <div class="table3Box" v-if="tab3ClickData == 2">
                  <div class="flex-l-b titleTopright1 titleSize14">
                    <div></div>
                    <div style="margin-right: 15px" @click="goaddSYList">添加输液处方</div>
                  </div>
                  <div v-for="(itemComSY, indexComSY) in patientFormPre.SYcompositionList" :key="indexComSY" style="margin-top: 20px">
                    <el-form-item :prop="'SYcompositionList.' + indexComSY + '.prescriptionName'" label="输液处方名称" :rules="[{ required: true, message: '请输入输液处方名称', trigger: 'blur' }]">
                      <div class="flex-l-b titleSize14">
                        <el-input v-model="itemComSY.prescriptionName" placeholder="请输入输液处方名称" style="width: 40%"></el-input>
                        <div class="flex-l titleTopright1 titleSize14">
                          <!-- <div style="margin-right:15px" @click="goaddSYList">添加输液处方</div> -->
                          <div @click="godeleteSYList(indexComSY)">删除输液处方</div>
                        </div>
                      </div>
                    </el-form-item>
                    <div v-for="(itemComSY1, indexComSY1) in itemComSY.zucompositionList" :key="indexComSY1" style="margin-top: 20px">
                      <div class="flex-l titleSize14">
                        <div class="">组{{ indexComSY1 * 1 + 1 }}</div>
                        <div class="flex-l titleTopright1 titleSize14">
                          <div style="margin-left: 15px" @click="godeleteSYzuList(indexComSY, indexComSY1)">删除改组</div>
                        </div>
                      </div>
                      <el-table :data="itemComSY1.mzPrescriptionTitle" border>
                        <el-table-column prop="usage" align="center" label="用法">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.usage'" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                              <el-select v-model="scope.row.usage" size="mini" placeholder="用法">
                                <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item"> </el-option>
                              </el-select>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="frequency" align="center" label="用药频率">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.frequency'">
                              <el-select v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                                <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                              </el-select>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="drippingSpeedList" align="center" label="滴速">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.drippingSpeedList.drippingSpeed'">
                              <el-input v-model="scope.row.drippingSpeedList.drippingSpeed" size="small" placeholder="单次剂量">
                                <el-select style="width: 75px" slot="append" v-model="scope.row.drippingSpeedList.drippingSpeedUnit" size="mini" placeholder="单位">
                                  <el-option v-for="item in SpeeOption" :key="item" :label="item" :value="item"> </el-option>
                                </el-select>
                              </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="startTime" align="center" label="起始日期">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.startTime'">
                              <el-date-picker v-model="scope.row.startTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd ">
                                <!-- format="yyyy / MM / dd " -->
                              </el-date-picker>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="days" align="center" label="连续天数">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionTitle.' + scope.$index + '.days'">
                              <el-input v-model="scope.row.days" size="small" placeholder="天数"> </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                      </el-table>
                      <el-table :data="itemComSY1.mzPrescriptionDetail" border style="margin-top: 10px">
                        <el-table-column prop="drugOrgId" align="center" label="药品名称">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.drugOrgId'" :rules="[{ required: true, message: '请输入成分', trigger: 'blur' }]">
                              <div style="position: relative">
                                <el-select v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" @focus="handleFocus" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                                  <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                                </el-select>
                                <div class="infoWarn" v-if="scope.row.drugOrgId == '0'" style="color: #fc1414; font-size: 14px"><i class="el-icon-info"></i>该药品不存在您的药品库</div>
                              </div>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="st" align="center" label="皮试">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.st'">
                              <el-input v-model="scope.row.st" size="small" placeholder="皮试"> </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.dosageList.dosage'" :rules="[{ required: true, message: '请输入单次剂量', trigger: 'blur' }]">
                              <el-input v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                                <el-select style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit" size="mini" placeholder="单位">
                                  <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                                </el-select>
                              </el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column prop="defaultDosageList" align="center" label="开药量" width="160">
                          <template slot-scope="scope">
                            <el-form-item :prop="'SYcompositionList.' + indexComSY + '.zucompositionList.' + indexComSY1 + '.mzPrescriptionDetail.' + scope.$index + '.defaultDosageList.defaultDosage'" :rules="[{ required: true, message: '请输入开药量', trigger: 'blur' }]">
                              <div style="position: relative">
                                <el-input v-model="scope.row.defaultDosageList.defaultDosage" size="small" placeholder="开药量" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')">
                                  <el-select style="width: 75px" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size="mini" placeholder="单位">
                                    <el-option v-for="item in kgOption" :key="item" :label="item" :value="item" :disabled="true"> </el-option>
                                  </el-select>
                                </el-input>
                                <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="(scope.row.inventoryNumber == '0' && !scope.row.drugInventoryNumber) || (scope.row.drugInventoryNumber == '0' && !scope.row.inventoryNumber) || scope.row.defaultDosageList.defaultDosage > scope.row.inventoryNumber">
                                  <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                                </el-tooltip>
                              </div>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column width="100" align="center">
                          <template slot-scope="scope">
                            <div class="flex-l">
                              <div>￥{{ scope.row.tradingPrice ? parseFloat(scope.row.defaultDosageList.defaultDosage * 1 * scope.row.tradingPrice).toFixed(2) : "0.00" }}</div>
                              <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="godeletSYzuDrugList(indexComSY, indexComSY1, scope.$index)"></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="flex-l titleSize14">
                        <div class="titleSize14 titleTopright1" @click="goaddSYzuDrugList(indexComSY, indexComSY1)">添加药品</div>
                        <div class="titleSize14 titleTopright1" @click="goaddSYzuList(indexComSY)" style="margin-left: 15px">添加组</div>
                      </div>
                    </div>

                    <!-- <div class="titleSize14 titleTopright1">添加药品</div> -->
                    <el-form-item style="text-align: right; margin-top: 20px">
                      <!-- <el-button  @click="submitForm('patientForm')">重置</el-button> -->
                      <el-button type="primary" @click="addSYFormPreForm(indexComSY)">存为常用处方</el-button>
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>

              <el-form-item prop="age" label="医嘱" style="width: 100%">
                <div class="flex-l"><el-input v-model="patientFormPre.doctorAdvice" class="stepInput"></el-input> <el-button size="mini" type="primary" icon="el-icon-more" @click="goGetDoctorAdviceListData"></el-button></div>
              </el-form-item>

              <div class="flex-l">
                <div>其他收费项目</div>
                <!-- <el-button size='mini'  type="primary" icon="el-icon-plus" style="margin-left:10px"> </el-button> -->
              </div>
              <el-form-item prop="age" label="附加费用" style="width: 100%">
                <div v-for="(itemCharge, indexCharge) in patientFormPre.MZOtherCharges" :key="indexCharge" style="margin-top: 10px">
                  <div class="flex-l">
                    <el-input v-model="itemCharge.chargeName" class="stepInput"></el-input>
                    <div>￥</div>
                    <el-input v-model="itemCharge.money" style="width: 7%; margin: 0 10px"></el-input>
                    <div><i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="deleteCharges(indexCharge)"></i></div>
                  </div>
                </div>

                <el-button size="mini" type="primary" icon="el-icon-plus" @click="goAddCharges"> </el-button>
              </el-form-item>
              <div class="Content3TitleBox">
                <div>合计金额</div>
                <div class="Content3TitleBoxRight">
                  <span>合计：</span><span class="Content3TitleBoxRightSpan">￥{{ parseFloat(allTotalMoney).toFixed(2) }}</span>
                </div>
              </div>
              <el-form-item prop="isSelef" label="取药">
                <div class="flex-l">
                  <el-radio v-model="patientFormPre.isSelef" label="1">自取</el-radio>
                  <el-radio v-model="patientFormPre.isSelef" label="2">快递</el-radio>
                </div>
              </el-form-item>
              <el-form-item v-if="patientFormPre.isSelef == '2'" prop="isSelef" label="快递信息">
                <div>
                  快递费用：<span style="color: #ee1515">￥{{ patientFormPre.isSelefPrice }}</span>
                </div>
                <!-- <div v-if="patientFormPre.isSelefPrice == 0">快递费用：￥<el-input-number style="width: 65%; margin: 0 10px" class="stepInput" v-model="patientFormPre.isSelefPrice" controls-position="right" :min="1"></el-input-number></div> -->
                <div class="adressListBoxTop">
                  <div :class="messageindex == index ? 'adressListBox' : 'adressListBoxno'" v-for="(item, index) in MessageList" :key="index" @click="goIndexmess(item, index)">
                    <div style="display: flex; align-items: center">
                      <div>{{ item.addressee }}</div>
                      <div style="margin-left: 10px">{{ item.phone }}</div>
                      <div style="margin-left: 10px">{{ item.isDefault ? "默认" : "" }}</div>
                    </div>
                    <div>
                      <div>{{ item.fullAddress }}</div>
                    </div>
                  </div>
                </div>
                <div class="adressListBoxTop"><el-button size="mini" @click="goAdress">使用新地址</el-button> <el-button size="mini" @click="refAdress">刷新列表</el-button></div>
              </el-form-item>
              <el-form-item style="text-align: right; margin-top: 20px; padding-bottom: 40px">
                <!-- <div class="flex-l" style="justify-content: flex-end;">
                                <div>选择是否打印：</div>
                                <el-radio v-model="isSelef" label="1">打印病历</el-radio>
                                <el-radio v-model="isSelef" label="2">打印处方</el-radio>
                            </div> -->
                <el-button type="primary" @click="goNextStep(2)">上一步</el-button>
                <el-button v-if="userInfo.isAuditDiagnose == 'True'" type="primary" @click="goNextDataSuc('patientFormPre')">免审核提交</el-button>
                <el-button @click="goNextData('patientFormPre')">提交审核</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="titleTopCenterRight" v-if="stepIndex == 1">
        <div class="titleTopCenterRightBox">
          <el-tabs v-model="activerightName">
            <el-tab-pane label="患者列表" name="1"></el-tab-pane>
            <!-- <el-tab-pane label="患者列表" name="second"></el-tab-pane>
                    <el-tab-pane label="个人常用处方" name="third"></el-tab-pane>
                    <el-tab-pane label="处方管理" name="fourth"></el-tab-pane> -->
          </el-tabs>
        </div>
        <div class="titleTopCenterRightBox" style="padding-top: 0">
          <el-input placeholder="搜索姓名/手机号" prefix-icon="el-icon-search" v-model="patientKeyWord" @keyup.enter.native="patientSearch" clearable>
            <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="patientSearch"> </el-button>
          </el-input>
        </div>
        <div class="todayDetail">
          <div class="todayTitleTh todayTitleThTtitle">
            <div class="thwidth">患者姓名</div>
            <div class="thwidth">性别</div>
            <div class="thwidth">联系电话</div>
          </div>
          <div class="todayTitleTh todayTitleThDetail" v-for="(item, index) in patientList" :key="index" @click="goNextpatientStep(item)">
            <div class="thwidth">{{ item.name }}</div>
            <div class="thwidth">{{ item.sex ? "男" : "女" }}</div>
            <div class="thwidth">{{ item.phone }}</div>
          </div>
          <div class="pageBox">
            <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="handleCurrentChange" :current-page.sync="patientcurrentPage" :total="patientTotal"> </el-pagination>
          </div>
        </div>
      </div>
      <div class="titleTopCenterRight" v-if="stepIndex == 2">
        <div class="titleTopCenterRightBox" style="padding-bottom: 10px">
          <el-tabs v-model="activerightName" @tab-click="handleClick2">
            <el-tab-pane label="辨证论治" name="1">
              <div class="titleSize16" v-if="ResultLatestData.recordResults">
                <div>
                  <div>
                    <span>{{ patientDetail.name }}</span
                    >，<span>{{ patientDetail.sex ? "男" : "女" }}</span
                    >，<span>{{ patientDetail.phone }}</span>
                  </div>
                  <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 20px; margin-bottom: 10px">
                    <div @click.stop="goResultByzhu(ResultLatestData)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>主症：{{ ResultLatestData.primarySymptomName }}</div>
                    <div>{{ ResultLatestData.diagnosisTime.slice(0, 10) }}</div>
                  </div>
                  <div class="ResultLateline">
                    您的症状清单：<span style="color: #df7d1f" v-for="(itemsym, indexitemsym) in ResultLatestData.symptoms" :key="indexitemsym"> {{ "【" + itemsym + "】" }}</span>
                  </div>
                </div>
                <div style="margin-top: 20px;cursor: pointer;" v-for="(item, index) in ResultLatestData.recordResults" :key="index" @click.stop="goResultByAnalysis(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.syndromeName }}</div>
                    <div>{{ item.matchingRate }}%匹配度</div>
                  </div>
                  <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="titleSize16" v-else>
                <div style="margin-top: 20px">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  <p style="text-align: center">暂无测试结果~</p>
                </div>
              </div>
              <div style="text-align: center; padding-top: 20px; border-top: 1px dashed #e6e6e6">
                <el-button type="primary" @click="getBZResultLatestData" plain>刷新</el-button>
                <el-button type="primary" @click="goIndexBZ">开始诊断</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="辨病论治" name="2">
              <div class="titleSize16" v-if="ResultLatestData.recordResults">
                <div>
                  <div>
                    <span>{{ patientDetail.name }}</span
                    >，<span>{{ patientDetail.sex ? "男" : "女" }}</span
                    >，<span>{{ patientDetail.phone }}</span>
                  </div>
                  <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 20px; margin-bottom: 10px">
                    <div @click.stop="goResultByzhu(ResultLatestData)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>主症：{{ ResultLatestData.primarySymptomName }}</div>
                    <div>{{ ResultLatestData.diagnosisTime.slice(0, 10) }}</div>
                  </div>
                  <div class="ResultLateline">
                    您的症状清单：<span style="color: #df7d1f" v-for="(itemsym, indexitemsym) in ResultLatestData.symptoms" :key="indexitemsym"> {{ "【" + itemsym + "】" }}</span>
                  </div>
                </div>
                <div style="margin-top: 20px;cursor: pointer;" v-for="(item, index) in ResultLatestData.recordResults" :key="index" @click.stop="goResultByAnalysis(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.syndromeName }}</div>
                    <div>{{ item.matchingRate }}%匹配度</div>
                  </div>
                  <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="titleSize16" v-else>
                <div style="margin-top: 20px">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  <p style="text-align: center">暂无测试结果~</p>
                </div>
              </div>
              <div style="text-align: center; padding-top: 20px; border-top: 1px dashed #e6e6e6">
                <el-button type="primary" @click="getBBResultLatestData" plain>刷新</el-button>
                <el-button type="primary" @click="goIndexBZ">开始诊断</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="虚症辨证" name="3">
              <!-- <div class="titlerightBox2 flex-l-b">
                <div :class="ResultByUserIndex == '0' ? 'titleRight2title' : 'notitleRight2title'" @click="goResultByUserList(0, '中医体质')">体质辨识</div>
                <div :class="ResultByUserIndex == '1' ? 'titleRight2title' : 'notitleRight2title'" @click="goResultByUserList(1, '中医虚证')">虚症辨证</div>
              </div> -->
              <div class="titleSize16" v-if="ResultByUserList[0].questionnaireDetermines.length > 0 && ResultByUserList.length > 0">
                <div style="margin-top: 10px">
                  <span>{{ patientDetail.name }}</span
                  >，<span>{{ patientDetail.sex ? "男" : "女" }}</span
                  >，<span>{{ patientDetail.phone }}</span>
                </div>
                <div style="margin-top: 10px">体质测试结果：{{ ResultByUserList[0].questionnaireDetermines[0].name }}</div>
                <div class="ResultLateline">
                    您的症状清单：<span style="color: #df7d1f" v-for="(itemsym, indexitemsym) in ResultByUserList[0].userSymptom.split('|')" :key="indexitemsym"> <template v-if="itemsym">{{ "【" + itemsym + "】" }}</template></span>
                  </div>
                <div style="margin-top: 15px;cursor: pointer;" v-for="(item, index) in ResultByUserList[0].questionnaireDetermines" :key="index" @click="goResultByAnalysisXZ(item)">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.name }}</div>
                    <div>{{ item.scores + "分" }}</div>
                  </div>
                  <!-- <div class="titleRight2Content1 titleSize14">
                    <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                      {{ "【" + itemq.name + "】" }}
                    </span>
                  </div> -->
                </div>
              </div>
              <div v-else>
                <div style="margin-top: 20px">
                  <img src="@/assets/img/interrogation/nodata.png" alt="" srcset="" />
                  <p style="text-align: center">暂无测试结果~</p>
                </div>
              </div>
              <!-- <div v-if="ResultByUserIndex == '0'" style="text-align: center; padding-top: 20px; border-top: 1px dashed #e6e6e6;">
                <el-button type="primary" @click="goResultByUserList(0, '中医体质')" plain>刷新</el-button>
                <el-button type="primary" @click="goIndex(0, '中医体质')">开始诊断</el-button>
              </div> -->
              <div style="text-align: center; padding-top: 20px; border-top: 1px dashed #e6e6e6">
                <el-button type="primary" @click="goResultByUserList(1, '中医虚证')" plain>刷新</el-button>
                <el-button type="primary" @click="goIndex(1, '中医虚证')">开始诊断</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="titleTopCenterRight" v-if="stepIndex == 3">
        <div class="titleTopCenterRightBox" style="padding-bottom: 10px">
          <el-tabs v-model="activeright3Name" @tab-click="handleClick3">
            <el-tab-pane label="推荐处方" name="first">
              <div class="firstRight3Box">
                <div class="flex-l-b" v-for="(item, index) in zHerbalList" :key="index">
                  <div style="padding: 10px 0" @click="goherbalDetail(item)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.name }}</div>
                </div>
                <div class="flex-l-b" v-for="(item, index) in tHerbalList" :key="index">
                  <div style="padding: 10px 0" @click="goherbalDetail(item)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.name }}</div>
                </div>
              </div>
              <div class="firstRight3BottomBox">
                <div class="firstRight3BottomBoxDiv">{{ JChooseName ? JChooseName : "暂未选择" }}</div>
                <el-table :data="herbalDetail.compositions" v-loading="loading" style="width: 100%">
                  <el-table-column prop="compositionName" label="中药材" width="80">
                    <template slot-scope="scope">
                      <div data-size="14" v-html="scope.row.compositionName1"></div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" label="用量"> </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                      {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="remark" label="炮制方法"> </el-table-column>
                </el-table>
              </div>
              <div></div>
              <div style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="常用处方" name="second">
              <div class="flex-l-b">
                <el-input placeholder="搜索常用处方..." prefix-icon="el-icon-search" style="width: 80%" v-model="MyCommonKeyword" @input="MyCommonKeywordInput"></el-input>
                <!-- <el-select v-model="scope.row.compositionId" v-if="scope.row.edit"
                                    filterable remote size="small" reserve-keyword placeholder="请输入成分"
                                    ref="selectLabel" :remote-method="SelectComposition"
                                    @change="ConstituteChange($event,scope.row)">
                                    <el-option v-for="item in EditDrugCompositio" :key="item.compositionId"
                                        :label="item.compositionName" :value="item.compositionId">
                                    </el-option>
                                </el-select> -->
                <el-select v-model="drugOrgName" placeholder="请选择" style="width: 140px; margin-left: 10px" @change="MyCommonConstituteChange">
                  <el-option v-for="item in options3RightData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="firstRight3Box">
                <div class="todayDetail">
                  <div class="todayTitleTh todayTitleThTtitle">
                    <div class="thwidthL">处方名称</div>
                    <div class="thwidthL">类型</div>
                  </div>
                  <div v-for="(itemMyCommon, indexMyCommon) in MyCommonPrescriptionList" :key="indexMyCommon" @click="goMyCommonPrescrip(itemMyCommon, indexMyCommon)">
                    <div :class="MyCommonindex == indexMyCommon ? 'todayTitleTh todayTitleThDetail titleTopLeftTableC' : 'todayTitleTh todayTitleThDetail'" style="padding: 10px 5px">
                      <div class="thwidthL1"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ itemMyCommon.prescriptionName }}</div>
                      <div class="thwidthL">{{ drugOrgName }}</div>
                    </div>
                  </div>

                  <!-- <div class="todayTitleTh todayTitleThDetail" style="padding:10px 5px">
                                    <div class="thwidthL"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right:5px"></i>参附汤</div>
                                    <div class="thwidthL">个人</div>
                                </div> -->
                </div>
              </div>
              <!-- <div class="pageBox">
                            <el-pagination
                                background
                                layout="prev, pager, next"
                                :page-size="ZCpatientSize"
                                @current-change="ZChandleCurrentChange" :current-page.sync="ZCpatientcurrentPage" :total="ZCpatientTotal">
                            </el-pagination>
                        </div> -->
              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="MyCommonCurrentChange" :current-page.sync="MyCommoncurrentPage" :total="MyCommonPageTotal"> </el-pagination>
              </div>
              <div class="firstRight3BottomBox" v-if="tab3ClickData == 0">
                <div class="firstRight3BottomBoxDiv">{{ GChooseName ? GChooseName : "暂未选择" }}</div>
                <el-table :data="MyCommonPrescripTableData.mzPrescriptionDetail" style="width: 100%">
                  <el-table-column prop="drugOrgName" label="中药材" width="100"> </el-table-column>
                  <el-table-column prop="weight" label="用量" width="100"> </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                      {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="usage" label="炮制方法"> </el-table-column>
                  <el-table-column prop="decoct" label="煎药方法">
                    <template slot-scope="scope">
                      {{ scope.row.decoct ? scope.row.decoct : "--" }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="firstRight3BottomBox" v-if="tab3ClickData == 1">
                <div class="firstRight3BottomBoxDiv">{{ GChooseName ? GChooseName : "暂未选择" }}</div>
                <el-table :data="MyCommonPrescripTableData.mzPrescriptionDetail" style="width: 100%">
                  <el-table-column prop="drugOrgName" label="药品" width="100"> </el-table-column>
                  <el-table-column prop="usage" label="用法" width="100"> </el-table-column>
                  <el-table-column prop="dosage" label="单次剂量">
                    <template slot-scope="scope">
                      {{ scope.row.dosage + scope.row.dosageUnit }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="firstRight3BottomBox" v-if="tab3ClickData == 2">
                <div class="firstRight3BottomBoxDiv">{{ GChooseName ? GChooseName : "暂未选择" }}</div>
                <div v-for="(item, index) in MyCommonPrescripTableDataSY.mzPrescriptionGroup" :key="index">
                  <div class="firstRight3BottomBoxDiv">{{ "组" + index + 1 }}</div>
                  <el-table :data="MyCommonPrescripTableDataSY.mzPrescriptionGroup" style="width: 100%">
                    <el-table-column prop="usage" label="用法" width="100"> </el-table-column>
                    <el-table-column prop="frequency" label="用药频率" width="100"> </el-table-column>
                    <el-table-column prop="drippingSpeed" label="开药量">
                      <template slot-scope="scope">
                        {{ scope.row.drippingSpeed + scope.row.drippingSpeedUnit }}
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-table :data="item.mzPrescriptionDetailSY" style="width: 100%">
                    <el-table-column prop="drugOrgName" label="药品" width="100"> </el-table-column>
                    <el-table-column prop="dosage" label="单次剂量" width="100">
                      <template slot-scope="scope">
                        {{ scope.row.dosage + scope.row.dosageUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="defaultDosage" label="开药量">
                      <template slot-scope="scope">
                        {{ scope.row.defaultDosage + scope.row.defaultDosageUnit }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div v-if="tab3ClickData == 0" style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription1(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
              <div v-if="tab3ClickData == 1" style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.CZcompositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription2(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
              <div v-if="tab3ClickData == 2" style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.SYcompositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription3(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="最近处方" name="third">
      
                        <div class="flex-l-s titleTopLeftTableC" style="margin-top:15px;cursor: pointer;">
                                <div>2023-08-18 15:42</div>
                                <div> 男</div>
                                <div>20岁</div>
                                <div>鼻炎</div>
                            </div>
                            <div class="flex-l-s" style="margin-top:15px;cursor: pointer;">
                                <div>2023-08-18 15:42</div>
                                <div> 男</div>
                                <div>20岁</div>
                                <div>鼻炎</div>
                            </div>
                            <div class="flex-l-s" style="margin-top:15px;padding-bottom:10px;cursor: pointer;">
                                <div>2023-08-18 15:42</div>
                                <div> 男</div>
                                <div>20岁</div>
                                <div>鼻炎</div>
                            </div>
                            <div class="thirdrightBox">
                                <div class="flex-l" >
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">诊断</div>
                                </div>
                                <div style="padding: 10px 0"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right:5px"></i>冠心病</div>
                                <div class="flex-l">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">成药处方1</div>
                                </div>
                                <div style="padding: 10px 0">布洛芬片</div>
                        </div>
                    </el-tab-pane> -->
            <el-tab-pane label="经典方" name="fourth">
              <el-input placeholder="输入处方名称" prefix-icon="el-icon-search" v-model="HerbalValue" @input="inputChange"></el-input>
              <div class="firstRight3Box">
                <div class="flex-l-b" v-for="(item, index) in HerbalList" :key="index">
                  <div style="padding: 10px 0" @click="goherbalDetail(item)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.herbalPrescriptionName }}</div>
                </div>
              </div>
              <div class="firstRight3BottomBox">
                <div class="firstRight3BottomBoxDiv">{{ JChooseName ? JChooseName : "暂未选择" }}</div>
                <el-table :data="herbalDetail.compositions" v-loading="loading" style="width: 100%">
                  <el-table-column prop="compositionName" label="中药材" width="80">
                    <template slot-scope="scope">
                      <div data-size="14" v-html="scope.row.compositionName1"></div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" label="用量"> </el-table-column>
                  <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                      {{ scope.row.recommendDosage ? scope.row.recommendDosage : "--" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="remark" label="炮制方法"> </el-table-column>
                </el-table>
              </div>
              <div>
                用法：
                <div data-size="14" v-html="herbalDetail.medicineinfo"></div>
              </div>
              <div style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
                <div v-for="(item, index) in patientFormPre.compositionList" :key="index" style="margin-top: 10px">
                  <el-button type="primary" @click="addgetLoadPrescription(index)">导入处方{{ index * 1 + 1 }}</el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <el-dialog :title="recordResultsInfo.syndromeName" :visible.sync="setInfoDialog" width="30%">
      <h4>鉴别分析</h4>
      <p v-if="recordResultsInfo.analysis" v-html="math(recordResultsInfo.analysis.toString().replace(/\n/g, '</p> <p>'))"></p>
      <p v-else>暂无</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setInfoDialog = false">取 消</el-button>
        <el-button type="primary" @click="goResultBy(recordResultsInfo.item)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- <div style="width:100%;height:200px;background: #ffffff;"></div> -->
    <!-- 检查治疗项 -->
    <el-dialog title="检查治疗项" :visible.sync="checkItemsVisible" width="40%" center>
      <div class="flex-l checkItemsBox" v-for="(itemcheckItems, index) in checkItemsList" :key="index">
        <el-select style="width: 70%" v-model="itemcheckItems.checkItemValue" value-key="id" filterable remote reserve-keyword placeholder="请输入检查治疗项" @change="checkItemsChange(itemcheckItems)">
          <el-option v-for="item in checkItemsOptions" :key="item.id" :label="item.itemName" :value="item"> </el-option>
        </el-select>
        <!-- <el-input v-model="checkItemsValue" class="stepInput" ></el-input> -->
        <el-input v-model="itemcheckItems.checkItemsNum" @input="InputcheckItemsNum(itemcheckItems)" style="width: 8%; margin-right: 10px; margin-left: 10px"></el-input>
        <div class="inputTag">次</div>
        <div>￥{{ itemcheckItems.tradingPrice }}</div>
        <div><i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="godetcheckItem(index)"></i></div>
      </div>
      <el-button size="mini" type="primary" icon="el-icon-plus" @click="goaddcheckItem"> </el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkItemsVisible = false">取消</el-button>
        <el-button type="primary" @click="submitCheckItemsVisible">提交</el-button>
      </span>
    </el-dialog>
    <!-- 处方审核选择医生 -->
    <el-dialog title="选择审核医生" :visible.sync="doctorDialogVisible" width="60%">
      <div class="">
        <div class="">
          <div class="flex-l">
            <el-input v-model="auditkeyWord" placeholder="姓名/电话" style="width: 50%; margin: 0 10px"></el-input>
            <el-select v-model="depvalue" placeholder="请选择科室" @change="depvalueChange" clearable>
              <el-option style="width: 50%; margin: 0 10px" v-for="(item, index) in depoptions" :key="index" :label="item.name" :value="item.id"> </el-option>
            </el-select>
            <el-button style="margin: 0 10px" @click="auditkeySearch" type="primary">搜索</el-button>
          </div>
          <el-table ref="singleTable" :data="AuditDoctorList" style="width: 100%" highlight-current-row @current-change="handleSelectionChangeAudit">
            <el-table-column type="index" width="55"> </el-table-column>
            <el-table-column label="姓名" width="120">
              <template slot-scope="scope">{{ scope.row.userName }}</template>
            </el-table-column>
            <el-table-column prop="adviceContent" label="科室">
              <template slot-scope="scope">{{ scope.row.department.fullName }}</template>
            </el-table-column>
            <el-table-column prop="addTime" label="联系方式" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.phone }}</template>
            </el-table-column>
            <el-table-column prop="auditDiagnoseMoney" label="审核费用" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="danger">
                  {{ scope.row.auditDiagnoseMoney ? scope.row.auditDiagnoseMoney + "元" : 0 }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="AuditCurrentChange" :current-page.sync="AuditcurrentPage" :total="auditTotal"> </el-pagination>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doctorDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="adddialogVisible">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入体质辨识结果 -->
    <!-- <el-dialog
            title="导入体质辨识结果"
            :visible.sync="resultDialogVisible"
            width="60%"
           >
            <div class="resultDialogBox">
                    <div class="flex-l-s resultDialogtopBox">
                        <el-input class="resultInput" v-model="input" placeholder="姓名/电话"></el-input>
                        <div class="flex-l">
                            <div>时间：</div>
                            <el-date-picker
                                v-model="resultValue"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </div>
                        <el-button type="primary">搜索</el-button>
                    </div>
                    <div style="width: 100%;position: relative;">
                        <div class="todayDetailR">
                            <div class="todayTitleTh todayTitleThTtitle">
                                <div class="thwidthR">姓名</div>
                                <div class="thwidthR">性别</div>
                                <div class="thwidthR">电话</div>
                                <div class="thwidthR">测试时间</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="1" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="6" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="7" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="2" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="3" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="4" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                            <div class="todayTitleTh todayTitleThDetailR">
                                <div class="thwidthR"><el-radio v-model="resultRadio" label="5" >楚云飞</el-radio></div>
                                <div class="thwidthR">男</div>
                                <div class="thwidthR">15038193019</div>
                                <div class="thwidthR">2023-9-25  10:12</div>
                            </div>
                        </div>
                        <div class="paginationBoxR">
                            <el-pagination
                                layout="prev, pager, next"
                                :total="50">
                            </el-pagination>
                        </div>
                    </div>
            
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
    <el-dialog title="提示" :visible.sync="comDialogVisible" width="60%">
      <div class="pei-bottom">
        <ul class="tab-tilte clearfix">
          <li @click="cur = 0" :class="[{ active: cur == 0 }, total > 0 ? '' : 'greenbg']">
            <i :class="total > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>全部</em><span>{{ total }}</span>
          </li>
          <li @click="cur = 1" :class="[{ active: cur == 1 }, similarTotal > 0 ? '' : 'greenbg']">
            <i :class="similarTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>重复用药</em><span>{{ similarTotal }}</span>
          </li>
          <li @click="cur = 2" :class="[{ active: cur == 2 }, compatibilityTotal > 0 ? '' : 'greenbg']">
            <i :class="compatibilityTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>相互作用</em><span>{{ compatibilityTotal }}</span>
          </li>
          <li @click="cur = 3" :class="[{ active: cur == 3 }, crowdTotal > 0 ? '' : 'greenbg']">
            <i :class="crowdTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>特殊人群</em><span>{{ crowdTotal }}</span>
          </li>
          <li @click="cur = 4" :class="[{ active: cur == 4 }, dietTotal > 0 ? '' : 'greenbg']">
            <i :class="dietTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>饮食禁忌</em><span>{{ dietTotal }}</span>
          </li>
          <li @click="cur = 5" :class="[{ active: cur == 5 }, avoidTotal > 0 ? '' : 'greenbg']">
            <i :class="avoidTotal > 0 ? 'el-icon-warning' : 'el-icon-s-flag'"></i><em>用药禁忌</em><span>{{ avoidTotal }}</span>
          </li>
        </ul>
        <div class="tab-content">
          <ul v-show="cur == 0">
            <li v-for="(similar, index) in similarList" :key="index + '1'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <h3>
                  【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                </h3>
                <div class="show-more">
                  <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="peitab-mes">
                <p v-html="similarhtmlRaw(similar)"></p>
              </div>
            </li>
            <li v-for="(compare, index) in compatibilityList" :key="index + '2'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <div v-if="compare.compatibilityType == 7">
                  <h3 v-for="(tip, index1) in compare.tips" :key="index1">
                    【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
              <div v-if="compare.tips" class="peitab-mes">
                <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + '3'">
                  <div v-if="types.keys <= 3">
                    <p v-for="(val, index2) in types.value" :key="index2 + '4'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 4">
                    <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + '6'">
                      <p v-html="namehtmlraw(val)"></p>
                    </div>
                  </div>
                  <div v-if="types.keys == 5">
                    <p v-for="(val, index2) in types.value" :key="index2 + '7'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 7">
                    <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                  </div>
                  <div v-if="types.keys == 8">
                    <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + '8'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail" @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">查看详情</p>
                </div>
              </div>
              <div v-else class="peitab-mes">
                <div>
                  <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                </div>
              </div>
            </li>
            <li v-for="(ins, index) in getcrowdList()" :key="index + '9'">
              <div v-for="(item3, index1) in ins.value" :key="index1 + 'a'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【特殊人群1】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(val, index2) in item3.items" :key="index2 + 'b'" v-html="contenthtmlRaw(val)"></p>
                </div>
              </div>
            </li>
            <template v-if="getNumByType(instructions, 2) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 'e'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【饮食禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'f'">
                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                  </div>
                </div>
              </li>
            </template>
            <template v-if="getNumByType(instructions, 1) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'c'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【用药禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(item, index1) in ins.items" :key="index1 + 'd'" v-html="contenthtmlRaw(item)"></p>
                </div>
              </li>
            </template>
          </ul>
          <div v-show="cur == 1">
            <li v-for="(similar, index) in similarList" :key="index + 'h'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <h3>
                  【重复用药】<span>{{ similar.sourceDrugName }} 与 {{ similar.targetDrugName }} 存在重复用药情况，请谨慎使用！</span>
                </h3>
                <div class="show-more">
                  <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="peitab-mes">
                <p v-html="similarhtmlRaw(similar)"></p>
              </div>
            </li>
          </div>
          <div v-show="cur == 2">
            <li v-for="(compare, index) in compatibilityList" :key="index + 'i'">
              <div class="peitab-tit" @click="showCourseInfoList($event)">
                <div v-if="compare.compatibilityType == 7">
                  <h3 v-for="(tip, index1) in compare.tips" :key="index1 + 'j'">
                    【相互作用】<span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    【相互作用】<span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                  </h3>
                  <div class="show-more">
                    <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
              <div v-if="compare.tips" class="peitab-mes">
                <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + 'k'">
                  <div v-if="types.keys <= 3">
                    <p v-for="(val, index2) in types.value" :key="index2 + 'l'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 4">
                    <div v-for="(val, index2) in types.value.filter((i) => i.title == '临床建议')" :key="index2 + 'n'">
                      <p v-html="namehtmlraw(val)"></p>
                    </div>
                  </div>
                  <div v-if="types.keys == 5">
                    <p v-for="(val, index2) in types.value" :key="index2 + 'o'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <div v-if="types.keys == 7">
                    <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                  </div>
                  <div v-if="types.keys == 8">
                    <p v-for="(val, index2) in types.value.filter((i) => i.title == '结果')" :key="index2 + 'p'" v-html="namehtmlraw(val)"></p>
                  </div>
                  <p v-if="types.keys != 7 && types.keys != 0" class="linkDetail" @click="toCompatibilityDetail(compare.sourceType, compare.sourceHashKey, compare.targetType, compare.targetHashKey)">查看详情</p>
                </div>
              </div>
              <div v-else class="peitab-mes">
                <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
              </div>
            </li>
          </div>
          <div v-show="cur == 3">
            <li v-for="(ins, index) in getcrowdList()" :key="index + 'q'">
              <div v-for="(item3, index1) in ins.value" :key="index1 + 'r'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【特殊人群】<span>{{ item3.name }} ({{ ins.tag }}) </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(val, index2) in item3.items" :key="index2 + 's'" v-html="contenthtmlRaw(val)"></p>
                </div>
              </div>
            </li>
          </div>
          <div v-show="cur == 4">
            <template v-if="getNumByType(instructions, 2) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 2)[0].items" :key="index + 't'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【饮食禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <div v-for="(item, index1) in ins.items.filter((i) => i.target == null)" :key="index1 + 'u'">
                    <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                  </div>
                  <div v-for="(item, index1) in dietGroup(ins.items.filter((i) => i.target != null))" :key="index1 + 'v'">
                    <p v-html="contenthtmlRaw(item, true)"></p>
                  </div>
                </div>
              </li>
            </template>
          </div>
          <div v-show="cur == 5">
            <template v-if="getNumByType(instructions, 1) > 0">
              <li v-for="(ins, index) in instructions.filter((i) => i.type == 1)[0].items" :key="index + 'w'">
                <div class="peitab-tit" @click="showCourseInfoList($event)">
                  <div>
                    <h3>
                      【用药禁忌】<span>{{ ins.name }} </span>
                    </h3>
                    <div class="show-more">
                      <span class="show-text">{{ showCourseInfo ? "收起" : "展开" }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
                <div class="peitab-mes">
                  <p v-for="(item, index1) in ins.items" :key="index1 + 'x'" v-html="contenthtmlRaw(item)"></p>
                </div>
              </li>
            </template>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="comDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="comDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="comDialogAdviceVisible" width="60%">
      <el-table ref="multipleTable" :data="AdviceList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="名称" width="120">
          <template slot-scope="scope">{{ scope.row.adviceName }}</template>
        </el-table-column>
        <el-table-column prop="adviceContent" label="内容"> </el-table-column>
        <el-table-column prop="addTime" label="添加时间" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.addTime | timefilters }}</template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination background layout="prev, pager, next" :page-size="15" @current-change="AdvicCurrentChange" :current-page.sync="AdvicurrentPage" :total="AdvicePageTotal"> </el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="comDialogAdviceVisible = false">取 消</el-button>
        <el-button type="primary" @click="comDialogAdviceVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="电子签名" :visible.sync="esigndialogVisible" width="30%">
      <vue-esign style="border: 1px solid #ddd; height: 200px" ref="esign" :isCrop="isCrop" :width="600" :height="200" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"></vue-esign>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleReset">清空签名</el-button>
        <el-button @click="esigndialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleGenerate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="支付界面" :visible.sync="paydialogVisible" :close-on-click-modal="false" width="30%">
      <el-form class="dialog-form" :model="charge" ref="chargeForm">
        <el-form-item label="应收金额" style="color: #ff0000" class="numT">
          <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ? charge.totalMoney.toFixed(2) - charge.totalPayMoney.toFixed(2) : "0.00" }}</span>
        </el-form-item>
        <template v-if="charge.credit">
          <el-form-item label="姓名" prop="debtUserName">
            <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="debtUserPhone">
            <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="挂账金额" prop="debtMoney">
            <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1" :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式">
          <el-radio-group v-model="charge.payWay">
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
          </el-radio-group>
        </el-form-item>
        <template v-if="charge.payWay == 0">
          <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd" show-password style="width: 200px"></el-input><span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span> </el-form-item>
        </template>
        <template v-if="charge.payWay == 1">
          <div class="flex-space-between-columns" v-if="qRcodeUrl">
            <img style="width: 120px; height: 120px" :src="qRcodeUrl" />
            <div>
              <span>打开微信扫码支付~ </span>
              <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
            </div>
          </div>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleSaveBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 开始诊断 -->
    <el-drawer class="el-drawer1" :direction="direction" size="90%" custom-class="demo-drawer" :visible.sync="isShowZD">
      <div class="flex-center" style="position: relative; height: calc(100vh - 92px)">
        <iframe ref="myIframe" style="border: none; position: absolute; top: 0; left: 0; right: 0; margin-top: -125px; height: calc(100% + 125px)" width="100%" height="100%" :src="`${pathStr}/#/zdrugconsultation/zDrugStart`" v-if="activerightName == 1"></iframe>
        <iframe ref="myIframe" style="border: none; position: absolute; top: 0; left: 0; right: 0; margin-top: -125px; height: calc(100% + 125px)" width="100%" height="100%" :src="`${pathStr}/#/diseaseConsultation/diseaseStart`" v-if="activerightName == 2"></iframe>
        <iframe ref="myIframe" style="border: none; position: absolute; top: 0; left: 0; right: 0; margin-top: -80px; height: calc(100% + 80px)" width="100%" height="100%" :src="`${pathStr}/#/company/startTest?titleName=中医虚证`" v-if="activerightName == 3"></iframe>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { shoppingMall } from "../../components/shoppingMall/shoppingMall";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { getckOptionsNames, addCompare, nameConvertValue, getCompatibilityList, removeCompare, updateCompare, removeCompareAll, namehtmlraw, tipGroup, dietGroup, contenthtmlRaw, getcrowdList } from "../../utils/Compatibility";
import { Drug } from "../../components/Drug/Drug";
import { Compatibility } from "../../components/Compatibility/Compatibility";
import { tocompareDetail } from "../../utils/relation";
import { Outpatient } from "@/components/Drug/Outpatient";
import { Test } from "../../components/company/test";
import storage from "@/utils/customstorage";
import servicesData from "../../../public/services.json";
import { zdrugconsultationMain } from "@/components/zdrugconsultation/zdrugconsultation.js";
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
export default {
  inject: ["reload"],
  components: {
    intNavheader,
    waitNumber,
    quillEditor,
  },
  data() {
    var test = new Test(this.TokenClient, this.Services.Statics);
    var shoppingMalltem = new shoppingMall(this.TokenClient, this.Services.shoppingMall);
    var shoppingMalStatics = new shoppingMall(this.TokenClient, this.Services.Statics);
    var shoppingMalDisease = new shoppingMall(this.TokenClient, this.Services.Disease);
    var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
    var UserDistributorltemDrug = new shoppingMall(this.TokenClient, this.Services.Drug);
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var patientIDCard = new patientMain(this.TokenClient, this.Services.IDCard);
    var drug = new Drug(this.TokenClient, this.Services.Drug);
    var statics = new patientMain(this.TokenClient, this.Services.Statics);
    var DiseasePatient = new patientMain(this.TokenClient, this.Services.Disease);
    var compatibility = new Compatibility(this.TokenClient, this.Services.Drug);
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var outpatientAuthorization = new Outpatient(this.TokenClient, this.Services.Authorization);
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    return {
      editorOption: {
        placeholder: "编辑文章内容",
      },
      zdrugconsultationMain: zdrugconsultation,
      patientDomain: patient,
      IDCardDomain: patientIDCard,
      drugpatientDomain: drugpatient,
      shoppingMalStaticsDomain: shoppingMalStatics,
      shoppingMalDiseaseDomain: shoppingMalDisease,
      outpatientAuthorizationDomain: outpatientAuthorization,
      UserDistributorltemDrugMain: UserDistributorltemDrug,
      OutpatientDomain: outpatient,
      DrugDomain: drug,
      staticsDomain: statics,
      DiseaseDomain: DiseasePatient,
      testDomain: test,
      UserDistributorMain: UserDistributorltem,
      userInfo: this.$store.state.localData.userInfo,
      shoppingMallMain: shoppingMalltem,
      FileUploadUrl: this.Services.Authorization + "/api/File/upload",
      compatibilityDomain: compatibility,
      // 配伍数据
      cur: 0,
      similarTotal: 0, //同类药条数
      compatibilityTotal: 0, //配伍条数
      crowdTotal: 0, //老人、儿童、妊娠、哺乳 总条数
      dietTotal: 0, //饮食条数
      avoidTotal: 0, //禁忌条数
      total: 0, //总条数
      compatibilityList: [], //配伍
      similarList: [], //同类药
      instructions: [], // 用药须知    禁忌1   饮食2   老人3    儿童4    妊娠5   哺乳6
      types: [],
      crowdList: [], // 特殊人群
      fruit: getCompatibilityList(),
      stepIndex: 1,
      patientType: 1, // 固定值
      organitionId: 0, // 固定值
      patientList: [],
      todayPatientList: [],
      ZCPatientList: [],
      ZCpatientTotal: 0,
      ZCpatientSize: 0,
      patientTotal: 0,
      patientPageIndex: 1,
      ZCDiagnoseindex: 1,
      compositionId: 0,
      // 是否选择检查治疗项
      isCheckItem: false,
      esigndialogVisible: false,
      lineWidth: 6, //画笔粗细
      lineColor: "#000000", //画笔颜色
      bgColor: "", //画布背景色，为空时画布背景透明
      imgurl: "", //签名生成的图片
      isCrop: false, //是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      checkItemResult: "",
      AuditDoctorList: [],
      auditkeyWord: "",
      depoptions: [],
      auditTotal: 0,
      auditPageIndex: 1,
      AdvicurrentPage: 0,
      AuditcurrentPage: 0,
      AlreadyId: "",
      diseasOptions: [],
      departmentId: 0,
      checkItemsVisible: false,
      ShowPhoto: false,
      ShowPhotocf: false,
      checkItemValue: {
        itemName: "",
      },
      patientcurrentPage: 1,
      checkItemsKeyWord: "",
      checkItemsOptions: [],
      checkItemsList: [
        {
          value: "",
          checkItemsNum: 1,
          tradingPrice: 0,
        },
      ],
      EditDrugCompositio: [],
      ImageContentList: [], //图片上传控件加载的数据
      DiagnoseContent: [], //图片列表
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: "",
      },
      showCheckItemsList: [],
      Showtemperature: false,
      isAlready: false,
      todayStepIndex: 1,
      checkItemsNum: 0,
      checkItemsValue: "",
      patientKeyWord: "",
      AdviceList: [],
      AdvicePageIndex: 1,
      AdvicePageTotal: 0,
      comDialogAdviceVisible: false,
      activeName: "first",
      doctorDialogVisible: false,
      resultDialogVisible: false,
      comDialogVisible: false,
      leftTable: 1,
      resultValue: "",
      doctorRadio: "1",
      resultRadio: "1",
      activerightName: "1",
      activeright3Name: "first",
      tab3ClickData: 0,
      todayPatient: "",
      patientFormRules: {
        name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
        age: [{ required: true, message: "请输入患者年龄", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入患者联系方式", trigger: "blur" },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" },
        ],
      },
      patientForm: {
        name: "",
        age: "",
        phone: "",
        idNo: "",
        address: "",
        diseaseName: "",
        sex: true,
      },
      patientDetail: {},
      patientFormDes: {
        main: "",
        family: "",
        // height:0,
        // heightTension: 0,
        // lowTension:0,
        // breathe:0,
        // pulse:0,
        // temperature:0,
        // weight:0,
        height: "",
        heightTension: "",
        lowTension: "",
        breathe: "",
        pulse: "",
        temperature: "",
        weight: "",
        irritability: "",
        otherCheck: "",
        past: "",
        diseaseDiagnose: [],
        discriminatoryAnalysis: '',
        discriminatoryAnalysisList:[]
      },
      patientFormPre: {
        compositionList: [
          {
            mzPrescriptionDetail: [
              {
                drugOrgId: "",
                drugOrgName: "",
                dosage: "",
                dosageUnit: "g",
                usage: "",
                weight: "",
                remark: "",
                decoct: "",
                recommendDosage: "",
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                dosage: "",
                dosageUnit: "g",
                usage: "",
                weight: "",
                remark: "",
                decoct: "",
                recommendDosage: "",
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                dosage: "",
                dosageUnit: "g",
                usage: "",
                weight: "",
                remark: "",
                decoct: "",
                recommendDosage: "",
              },
            ],
            usage: "",
            remark: "",
            dosage: "",
            dosageNumber: "",
            frequency: "",
            prescriptionName: "",
            request: "",
            type: 0,
            prescriptionType: 1,
            isDenoction: true,
            isdeliveryPrice: 0,
          },
        ],
        CZcompositionList: [
          //       {
          //     mzPrescriptionDetail:[
          //     {
          //         drugOrgId:'',
          //         drugOrgName:'',
          //         dosageList:{
          //             dosage:'',
          //             dosageUnit:'',
          //         },
          //         defaultDosageList:{
          //             defaultDosage:'',
          //             defaultDosageUnit:'',
          //         },
          //         groupNumber:'',
          //         frequency:'',
          //         usage:'',
          //         remark:'',
          //     },
          //     {
          //         drugOrgId:'',
          //         drugOrgName:'',
          //         frequency:'',
          //         dosageList:{
          //             dosage:'',
          //             dosageUnit:'',
          //         },
          //         defaultDosageList:{
          //             defaultDosage:'',
          //             defaultDosageUnit:'',
          //         },
          //         groupNumber:'',
          //         usage:'',
          //         remark:'',
          //     },
          //     {
          //         drugOrgId:'',
          //         drugOrgName:'',
          //         frequency:'',
          //         dosageList:{
          //             dosage:'',
          //             dosageUnit:'',
          //         },
          //         defaultDosageList:{
          //             defaultDosage:'',
          //             defaultDosageUnit:'',
          //         },
          //         groupNumber:'',
          //         usage:'',
          //         remark:'',
          //     }
          //     ],
          //     prescriptionName:'',
          //     type:1,
          //     prescriptionType:1
          // }
        ],
        SYcompositionList: [
          //     {
          //     prescriptionName:'',
          //     type:2,
          //     prescriptionType:1,
          //     zucompositionList: [{
          //         prescriptionName:'',
          //         mzPrescriptionTitle:[{
          //             usage:'',
          //         frequency:'',
          //         drippingSpeedList:{
          //             drippingSpeed:'',
          //             drippingSpeedUnit:''
          //         },
          //         startTime:'',
          //         days:'',
          //         }],
          //         mzPrescriptionDetail:[{
          //             drugOrgId:'',
          //             drugOrgName:'',
          //             st:'',
          //             dosageList:{
          //             dosage:'',
          //             dosageUnit:'',
          //             },
          //             defaultDosageList:{
          //                 defaultDosage:'',
          //                 defaultDosageUnit:'',
          //             },
          //         },
          //         {
          //             drugOrgId:'',
          //             drugOrgName:'',
          //             st:'',
          //             dosageList:{
          //             dosage:'',
          //             dosageUnit:'',
          //             },
          //             defaultDosageList:{
          //                 defaultDosage:'',
          //                 defaultDosageUnit:'',
          //             },
          //         },
          //         {
          //             drugOrgId:'',
          //             drugOrgName:'',
          //             st:'',
          //             dosageList:{
          //             dosage:'',
          //             dosageUnit:'',
          //             },
          //             defaultDosageList:{
          //                 defaultDosage:'',
          //                 defaultDosageUnit:'',
          //             },
          //         }]
          //     }]
          // }
        ],
        MZOtherCharges: [
          {
            chargeName: "",
            money: "",
          },
        ],
        doctorAdvice: "",
        isSelef: "2",
        isSelefPrice: 0,
      },
      ageOptions: [
        {
          value: "岁",
          label: "岁",
        },
      ],
      options3RightData: [
        {
          value: "个人",
          label: "个人",
        },
        {
          value: "机构",
          label: "机构",
        },
      ],
      ageValue: "岁",
      tableData: [
        {
          date: "药材",
          name: "9g",
          address: "去节",
        },
        {
          date: "药材",
          name: "9g",
          address: "去节",
        },
        {
          date: "药材",
          name: "9g",
          address: "去节",
        },
        {
          date: "药材",
          name: "9g",
          address: "去节",
        },
      ],
      remarkOption: ["口服", "外用", "滴眼", "静脉注射", "静脉滴注", "肌内注射", "皮下注射", "皮内注射", "腔内注射", "椎管注射", "动脉注射", "心内注射", "球内注射", "皮下埋植", "口腔喷雾", "口腔吸入", "口腔黏膜给药", "滴鼻", "鼻腔喷雾", "鼻饲", "鼻腔吸入", "滴耳", "眼科外用", "涂于眼睑内", "含漱", "含服", "舌下含服", "直肠给药", "直肠塞入", "肛门涂抹", "阴道给药", "阴道塞入", "阴道擦洗"],
      frequencyOption: ["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
      usageOption: ["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
      // requestOption: ["水煎服；分早晚两次空腹温服", "水煎服；分早晚两次饭前温服", "水煎服；分早晚两次饭后温服", "水煎服；分早晚凉服", "水煎服；1日3次空腹温服", "水煎服；1日3次饭前温服", "水煎服；1日3次饭后温服"],
      requestOption: ["水煎服；分早晚两次空腹温服"],
      kgOption: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
      SpeeOption: ["g/分钟", "ml/分钟", "ml/小时", "分钟/次", "ug/分钟", "滴/分钟"],
      usage: ["口服", "外用"],
      MyCommonPrescriptionList: [],
      MyCommonPageindex: 1,
      MyCommonPageTotal: 1,
      MyCommonindex: -1,
      MyCommonPrescripTableData: [],
      MyCommonPrescripTableDataSY: [],
      drugOrgName: "个人",
      MyCommonKeyword: "",
      allTotalMoney: 0,
      CheckItem: 0,
      ResultByUserList: [
        {
          questionnaireDetermines: [],
        },
      ],
      ResultByUserIndex: 0,
      HerbalValue: "",
      herbalDetail: "",
      HerbalList: [],
      jdcompositionList: [],
      loading: false,
      ResultLatestData: [],
      esignimgurl: "",
      ImageContent: [], //图片列表
      ImageContentList: [], //图片上传控件加载的数据
      DiagnoseContent: [], //图片列表,
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: "",
      },
      RegionData: [],
      MessageList: [],
      messageindex: 0,
      messagePatinet: {},
      // 查询药品
      drugIdList: [],
      HerbaldetailList: [],
      serchdrugIdList: [],
      zserchdrugIdList: [],
      // 推荐处方
      zHerbalList: [],
      tHerbalList: [],
      MyCommoncurrentPage: 0,
      designImg: "",
      auditSignImg: "",
      paydialogVisible: false,
      // 支付
      charge: {
        id: 0,
        details: [],
        discountsMoney: 0, //优惠金额
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0, //挂账总金额
        payWay: 1, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        payMoney: 0, //支付金额
        totalMoney: 0,
        payment: null, //输入金额
        change: 0, //找零
        credit: false,
      },
      timer: null,
      UserBalance: {
        assetsBalance: 0,
      },
      qRcodeUrl: "",
      weChatInfo: "",
      assetsBalanceIsTrue: false,
      // 传参储存
      mzPrescriptionData: [],
      mzOtherChargesData: [],
      waitAuditUserId: 0,
      depvalue: "",
      diseaseIndex: 0,
      showCourseInfo: false,
      ShowPhotocfList: [],
      ShowPhotoList: [],
      fitDialogVisible: false,
      fitDialogVisibleImgUrl: "",
      auditDiagnoseMoney: 0,
      servicesDataId: servicesData,
      waitDataTotal: 0,
      asstoken: "",
      TChooseName: "",
      GChooseName: "",
      JChooseName: "",
      isShowZD: false,
      direction: "ltr",
      pathStr: "",
      setInfoDialog: false,
      recordResultsInfo: {
        syndromeName: "",
        analysis: "",
        step: "",
        item: {},
      },
    };
  },
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + this.asstoken,
      };
    },
  },
  watch: {
    // showCheckItemsList: {
    //   handler(newValue) {
    //     this.CheckItem = 0;
    //     this.showCheckItemsList.map((item, index) => {
    //       this.CheckItem += item.tradingPrice;
    //     });
    //   },
    // },
    // + this.CheckItem;
    patientFormPre: {
      handler(newValue) {
        if (this.patientFormPre.isSelef == "2") {
          this.allTotalMoney = this.patientFormPre.isSelefPrice + 0;
        } else {
          this.allTotalMoney = 0;
        }
        this.patientFormPre.compositionList.map((item, index, arr) => {
          if (item.isDenoction) {
            item.isdeliveryPrice = item.isdeliveryPrice ? item.isdeliveryPrice : 0;
            this.allTotalMoney += item.dosage * item.isdeliveryPrice;
          }
          item.mzPrescriptionDetail.map((item1, index1, arr1) => {
            if (item1.tradingPrice) {
              item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0;

              this.allTotalMoney += item.dosage * (item1.weight ? parseFloat(item1.weight) : 0) * item1.tradingPrice;
            }
          });
        });
        this.patientFormPre.CZcompositionList.map((item, index, arr) => {
          item.mzPrescriptionDetail.map((item1, index1, arr1) => {
            if (item1.tradingPrice) {
              item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0;
              this.allTotalMoney += item1.tradingPrice * item1.defaultDosageList.defaultDosage;
            }
          });
        });
        this.patientFormPre.SYcompositionList.map((item, index, arr) => {
          item.zucompositionList.map((item1, index1, arr1) => {
            item1.mzPrescriptionDetail.map((item2, index2, arr2) => {
              if (item2.tradingPrice) {
                item2.tradingPrice = item2.tradingPrice ? item2.tradingPrice : 0;
                this.allTotalMoney += item2.tradingPrice * item2.defaultDosageList.defaultDosage;
              }
            });
          });
        });
        this.patientFormPre.MZOtherCharges.map((item, index, arr) => {
          if (item.money) {
            item.money = item.money ? parseFloat(item.money) : 0;
            this.allTotalMoney += item.money;
          }
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.pathStr = window.location.origin;
    this.getPatientListdata();
    this.asstoken = await this.TokenClient.AccessToken();
    window.sessionStorage.removeItem("bianzhenchooseUser");
    window.sessionStorage.removeItem("chooseUser");
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    onEditorChange({ editor, html, text }) {
      this.content = html;
    },
    replaceContent2(content) {
      content = content.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
      return content;
    },
    math(info) {
      return this.replaceContent2(info);
    },
    gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
          _this.getTakeRequestList();
        },
        function (error) {
          _this.waitDataTotal = 0;
          _this.getTakeRequestList();
        }
      );
    },
    refPatientForm() {
      var _this = this;
      _this.patientForm = {
        name: "",
        age: "",
        phone: "",
        idNo: "",
        address: "",
        sex: true,
        diseaseName: "",
      };
    },
    goIdDomain() {
      var _this = this;
      // 身份证
      // 18889/api/connect
      _this.IDCardDomain.GetIdConcetDetail(
        function (data) {
          console.log(data);
        },
        function (error) {
          let errData = JSON.parse(error);
          if (errData.resultFlag == "0") {
            _this.IDCardDomain.GetIdcardDetailTrue(
              function (data) {
                console.log(data);
              },
              function (error) {
                let errDataTrue = JSON.parse(error);
                if (errDataTrue.resultFlag == "0") {
                  _this.IDCardDomain.GetIdcardDetail(
                    function (data) {
                      console.log(data);
                    },
                    function (error) {
                      let errContentData = JSON.parse(error);
                      if (errContentData.resultFlag == "0") {
                        let IDval = errContentData.resultContent.certNumber.length;
                        let IDiden = errContentData.resultContent.certNumber;
                        let IDmyDate = new Date();
                        let IDmonth = IDmyDate.getMonth() + 1;
                        let IDday = IDmyDate.getDate();
                        let IDage = 0;
                        if (IDval == 18) {
                          IDage = IDmyDate.getFullYear() - IDiden.substring(6, 10) - 1;
                          if (IDiden.substring(10, 12) < IDmonth || (IDiden.substring(10, 12) == IDmonth && IDiden.substring(12, 14) <= IDday)) IDage++;
                        }
                        if (IDval == 15) {
                          IDage = IDmyDate.getFullYear() - IDiden.substring(6, 8) - 1901;
                          if (IDiden.substring(8, 10) < IDmonth || (IDiden.substring(8, 10) == IDmonth && IDiden.substring(10, 12) <= IDday)) IDage++;
                        }
                        let randnum = Math.random() * 10000000000000;
                        randnum = Math.floor(randnum);
                        let fileName = "zhu/" + randnum + ".jpg";
                        let esignimgurlFile = _this.dataURLtoFile("data:image/png;base64," + errContentData.resultContent.identityPic, fileName);
                        let dataform = new FormData();
                        let myauditSignImg = "";
                        dataform.append("file", esignimgurlFile);
                        _this
                          .$axios({
                            method: "POST",
                            url: _this.FileUploadUrl,
                            headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + _this.asstoken },
                            data: dataform,
                          })
                          .then((res) => {
                            myauditSignImg = res.data[0].completeFilePath;
                            _this.patientForm = {
                              name: errContentData.resultContent.partyName,
                              age: IDage,
                              phone: "",
                              idNo: errContentData.resultContent.certNumber,
                              address: errContentData.resultContent.certAddress,
                              sex: errContentData.resultContent.certType,
                              photo: myauditSignImg,
                              diseaseName: "",
                            };
                            // _this.getNextCompletePrescription();
                          })
                          .catch((err) => {
                            // _this.$message.error("上传签名失败");
                          });
                      } else {
                        _this.$message({
                          type: "error",
                          message: errContentData.errorMsg,
                        });
                      }
                    }
                  );
                }
              }
            );
          } else {
            _this.$message({
              type: "error",
              message: "请检查硬件设备",
            });
          }
        }
      );
    },
    dataChange(e) {
      console.log(e);
    },
    goTodayDetail(item) {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        item.patientId,
        function (data) {
          if (data.data.patientDiagnose.length > 0) {
            for (let i = 0; i < data.data.patientDiagnose.length; i++) {
              let f = data.data.patientDiagnose[i].addTime.split("T");
              data.data.patientDiagnose[i].addTime = f[0];
            }
          }
          _this.patientDetail = data.data;
          _this.patientForm = data.data;
        },
        function (error) {
          console.log(error);
          _this.patientDetail = [];
        }
      );
    },
    goZcDetail(item) {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        item.patientId,
        function (data) {
          if (data.data.patientDiagnose.length > 0) {
            for (let i = 0; i < data.data.patientDiagnose.length; i++) {
              let f = data.data.patientDiagnose[i].addTime.split("T");
              data.data.patientDiagnose[i].addTime = f[0];
            }
          }
          _this.patientDetail = data.data;
          _this.patientForm = data.data;
          _this.checkItemResult = item.id;
          _this.patientFormDes.breathe = item.breathe ? item.breathe : 0;
          _this.patientFormDes.family = item.family ? item.family.toString() : "";
          _this.patientFormDes.height = item.height ? item.height : 0;
          _this.patientFormDes.heightTension = item.heightTension ? item.heightTension : 0;
          _this.patientFormDes.irritability = item.irritability ? item.irritability.toString() : "";
          _this.patientFormDes.lowTension = item.lowTension ? item.lowTension : 0;
          _this.patientFormDes.main = item.main ? item.main.toString() : "";
          _this.patientFormDes.otherCheck = item.otherCheck ? item.otherCheck.toString() : "";
          _this.patientFormDes.past = item.past ? item.past.toString() : "";
          _this.patientFormDes.pulse = item.pulse ? item.pulse : 0;
          _this.patientFormDes.temperature = item.temperature ? item.temperature : 0;
          _this.patientFormDes.weight = item.weight ? item.weight : 0;
          _this.patientFormDes.PatientId = item.patientId;
          _this.patientFormDes.diseaseDiagnose = item.diseaseDiagnose.split(",");
          _this.patientFormDes.discriminatoryAnalysis = data.data.discriminatoryAnalysis ? data.data.discriminatoryAnalysis : "";
          _this.ShowPhotocfList = item.prescriptionImgList;
          _this.ShowPhotoList = item.diagnoseImgList;
          _this.stepIndex = 2;
          _this.isCheckItem = true;
          _this.getBZResultLatestData();
        },
        function (error) {
          console.log(error);
          _this.patientDetail = [];
        }
      );
    },
    getPayPwd() {
      const href = this.$router.resolve("../personal/resetPassword");
      window.open(href.href, "_blank");
    },
    cancleSaveBtn() {
      var _this = this;
      _this.$message({
        type: "error",
        message: "取消支付!",
      });
      _this.paydialogVisible = false;
      _this.$router.push({
        name: "orderList",
      });
    },
    SaveBtn() {
      let _this = this;
      let form = {
        id: _this.charge.id,
        details: _this.charge.details,
        payments: [
          {
            discountsMoney: _this.charge.discountsMoney,
            debtUserName: _this.charge.debtUserName,
            debtUserPhone: _this.charge.debtUserPhone,
            debtMoney: _this.charge.debtMoney,
            payWay: _this.charge.payWay,
            payMoney: _this.charge.totalMoney.toFixed(2) - _this.charge.totalPayMoney.toFixed(2),
            totalMoney: _this.charge.totalMoney.toFixed(2) - _this.charge.totalPayMoney.toFixed(2),
          },
        ],
      };
      if (_this.charge.payWay == 0) {
        _this.outpatientAuthorizationDomain.getIsSetPayPassword(
          function (data) {
            _this.outpatientAuthorizationDomain.getIsPayPassword(
              {
                payPassword: _this.charge.payPwd,
              },
              function (data) {
                _this.OutpatientDomain.DrugPayBalancePayment(form, function (data) {
                  _this.$message({
                    type: "success",
                    message: "支付成功!",
                  });
                  _this.paydialogVisible = false;
                  _this.reload();
                });
              },
              function (error) {
                _this.$message({
                  type: "info",
                  message: "支付密码错误",
                });
              }
            );
          },
          function (error) {
            _this
              .$confirm("未设置支付密码？去设置", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const href = _this.$router.resolve("../personal/resetPassword");
                window.open(href.href, "_blank");
              });
          }
        );
      } else {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            _this.qRcodeUrl = _this.Services.Drug + data.data.qRcodeUrl;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timer = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  _this.weChatInfo,
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timer);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.paydialogVisible = false;
                      _this.reload();
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      }
    },
    getAllUserBalance() {
      var _this = this;
      _this.UserDistributorMain.getUserDistributor(
        function (data) {
          _this.UserBalance = data.data;
          if (_this.UserBalance.assetsBalance * 1 < _this.charge.totalMoney * 1) {
            _this.assetsBalanceIsTrue = true;
            _this.charge.payWay = 1;
          } else {
            _this.assetsBalanceIsTrue = false;
          }
        },
        function (err) {
          _this.UserBalance = [];
          console.log(err);
        }
      );
    },
    goResultByzhu(item) {
      this.patientFormDes.main = item.primarySymptomName + ";" + item.symptoms;
    },
    diseasChange(e) {
      this.drugIdList = e;
    },
    remoteMethod(e) {
      this.getDiseasesSearchData(e);
    },
    getDiseasesSearchData(keyWord) {
      var _this = this;
      _this.DiseaseDomain.getDiseasesSearch(
        "全部",
        "1",
        "0",
        keyWord,
        function (data) {
          _this.diseasOptions = data.data.results;
        },
        function (err) {
          _this.diseasOptions = [];
          console.log(err);
        }
      );
    },

    getResultDrugsData() {
      var _this = this;
      let params = [];
      // _this.serchdrugIdList.map((item, index) => {
      //   params.push(item.syndromeId);
      // });
      let params1 = _this.patientFormDes.diseaseDiagnose.split(",");
      params1.map((item1, index1) => {
        _this.serchdrugIdList.map((item, index) => {
          if (item1 == item.syndromeName) {
            params.push(item.syndromeId);
          }
        });
      });
      _this.shoppingMalDiseaseDomain.getResultDrugs(
        params,
        function (data) {
          _this.getcheckItemstDataPreD();
          _this.tHerbalList = [];
          data.data.forEach((item, index) => {
            if (item.drugs.length > 0) {
              item.drugs.forEach((item1, index1) => {
                _this.tHerbalList.push(item1);
              });
            }
          });
        },
        function (error) {
          _this.getcheckItemstDataPreD();
          _this.tHerbalList = [];
        }
      );
    },
    getzResultDrugsData() {
      var _this = this;
      let params = [];
      let params1 = _this.patientFormDes.diseaseDiagnose.split(",");
      params1.map((item1, index1) => {
        _this.zserchdrugIdList.map((item, index) => {
          if (item1 == item.name) {
            params.push(item.questionnaireResultId);
          }
        });
        _this.shoppingMalStaticsDomain.ZgetResultDrugs(
          params,
          function (data) {
            _this.getResultDrugsData();
            _this.zHerbalList = [];
            data.data.forEach((item, index) => {
              _this.zHerbalList.push(item.drug);
            });
          },
          function (error) {
            _this.getResultDrugsData();
            _this.zHerbalList = [];
          }
        );
      });
    },
    AllMessages() {
      var _this = this;
      _this.shoppingMallMain.getReceivingAddress(
        function (data) {
          if (data.data) {
            _this.MessageList = data.data;
            _this.messagePatinet = data.data[0];
          }
          _this.getDrugCompositio();
        },
        function (err) {
          _this.MessageList = [];
          _this.getDrugCompositio();
          console.log(err);
        }
      );
    },
    beforeAvatarUpload(felr) {
      console.log(felr);
    },
    DiagnoseImageUpload(response, file, fileList) {
      var _this = this;
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileHash: file.fileHash,
          fileExt: file.fileExtension,
        };

        _this.DiagnoseContent.push(imgarr);
      });
      _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
    },
    DiagnoseImageRemove(file, fileList) {
      var _this = this;
      const filePath = file.response[0].completeFilePath;
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.DiagnoseContent.findIndex((x) => x.completeFilePath == filePath);
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.DiagnoseContent.splice(i, 1);
      _this.patientFormDes.DiagnoseImgList = _this.DiagnoseContent;
    },
    DiagnoseImagePreview(file) {
      this.Image.dialogImageUrl = file.url;
      this.Image.dialogVisible = true;
    },
    handleImagePreview(file) {
      this.Image.dialogImageUrl = file.url;
      this.Image.dialogVisible = true;
    },
    handleImageRemove(file, fileList) {
      var _this = this;
      const filePath = file.response[0].completeFilePath;
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.ImageContent.findIndex((x) => x.completeFilePath == filePath);
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.ImageContent.splice(i, 1);
      _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
    },
    handleImageUpload(response, file, fileList) {
      var _this = this;
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileHash: file.fileHash,
          fileExt: file.fileExtension,
        };

        _this.ImageContent.push(imgarr);
      });
      _this.patientFormDes.PrescriptionImgList = _this.ImageContent;
    },
    toCompatibilityDetail(sourceType, sourceHashKey, targetType, targetHashKey) {
      tocompareDetail(sourceType, sourceHashKey, targetType, targetHashKey);
    },
    compatibility(peiwuList) {
      var _this = this;
      _this.types = [0, 1, 2, 3, 4, 5, 6];
      _this.compatibilityList = [];
      _this.similarList = [];
      _this.instructions = [];
      _this.dietTotal = 0;
      _this.avoidTotal = 0;
      _this.total = 0;
      _this.crowdTotal = 0;
      _this.compatibilityTotal = 0;
      _this.similarTotal = 0;
      let params = {};
      params.items = peiwuList;
      (params.types = _this.types),
        _this.compatibilityDomain.MZCompatibilitys(
          params,
          function (data) {
            _this.oldName = getCompatibilityList()
              .filter((x) => x.state === "1")
              .map((x) => x.value)
              .join("---");
            _this.compatibilityList = data.data.compatibility.filter((x) => x.compatibilityType !== 7);
            _this.similarList = data.data.similar;
            _this.instructions = data.data.instructions;
            _this.types = data.data.types;
            _this.totalprocess();
          },
          function (error) {
            console.log("配伍请求异常:", error);
          }
        );
    },
    totalprocess() {
      this.similarTotal = this.types.indexOf(0) != -1 && this.similarList != null ? this.similarList.length : 0;
      this.compatibilityTotal = this.types.indexOf(0) != -1 ? this.compatibilityList.length : 0;
      this.crowdTotal = this.getNumByType(this.instructions, 3) + this.getNumByType(this.instructions, 4) + this.getNumByType(this.instructions, 5) + this.getNumByType(this.instructions, 6);

      this.dietTotal = this.getNumByType(this.instructions, 2);
      this.avoidTotal = this.getNumByType(this.instructions, 1);
      this.total = this.avoidTotal + this.dietTotal + this.crowdTotal + this.compatibilityTotal + this.similarTotal;
    },
    similarhtmlRaw(item) {
      var info = "";
      if (item.sourceDrugCategory != item.targetDrugCategory) {
        // var reg = new RegExp(item.sourceDrugComposition.replace(/\s/g, "").split("").join("|"), "ig");
        // var reg1=new RegExp(',','g')
        // console.log(item.targetDrugComposition.match(reg).join().replace(reg1,''))
        info = `${item.sourceDrugName}:${item.sourceDrugComposition}<br> ${item.targetDrugName}:${item.targetDrugComposition} <br> ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `;
      } else {
        info = `${item.sourceDrugName} 与 ${item.targetDrugName} 同属于 ${item.sourceDrugCategory}(${item.sourceDrugCategoryName})`;
      }
      // info=`${item.sourceDrugName}:${item.sourceDrugComposition}<br>
      //     ${item.targetDrugName}:${item.targetDrugComposition} <br>
      //     ${item.sourceDrugName} 与 ${item.targetDrugName} 含有相同成分 `
      return info;
    },
    namehtmlraw(item) {
      return namehtmlraw(item);
    },
    //tip分组
    tipGroup(list) {
      return tipGroup(list);
    },
    //内容HTML转换
    contenthtmlRaw(item, isdiet = false) {
      return contenthtmlRaw(item, isdiet);
    },
    //饮食禁忌 分组
    dietGroup(list) {
      return dietGroup(list);
    },
    //特殊人群列表
    getcrowdList() {
      return getcrowdList(this.instructions);
    },
    showCourseInfoList(e) {
      const target = e.currentTarget;
      // 寻找父元素的兄弟元素进行显示与隐藏控制
      $(target).siblings().toggle();
      // 切换样式-图标
      $(target).find("i").toggleClass("el-icon-arrow-down").addClass("el-icon-arrow-up");
      if ($(target).siblings().is(":hidden")) {
        $(target).find(".show-text").html("展开");
      } else {
        $(target).find(".show-text").html("收起");
      }
    },
    // 配伍
    getNumByType(array, type) {
      let num = 0;
      if (array.filter((i) => i.type == type).length > 0) {
        num = array.filter((i) => i.type == type)[0].items.length;
      }
      return num;
    },
    getTakeRequestList() {
      var _this = this;
      _this.drugpatientDomain.TakeRequestList(
        "%20",
        "1",
        function (data) {
          _this.requestOption = data.data.results;
          _this.getTodayPatientData();
        },
        function (err) {
          _this.EditDrugCompositio = [];
          _this.getTodayPatientData();
          console.log(err);
        }
      );
    },
    getDrugCompositio() {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
        "%20",
        function (data) {
          for (let i = 0; i < data.data.length; i++) {
            var label = `${data.data[i].mzDrug.drugName}（${data.data[i].mzDrug.specification}/${data.data[i].mzDrug.specificationUnit}-${data.ata[i].mzDrug.productFactory}）`;
            _this.EditDrugCompositio.push({
              drugOrgId: data.data[i].id,
              drugOrgName: label,
              drugOrgName1: data.data[i].mzDrug.drugName,
              drug: data.data[i],
            });
          }
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      );
    },
    getBZResultLatestData() {
      var _this = this;
      _this.ResultLatestData = [];
      _this.DiseaseDomain.getBZResultLatest(
        _this.patientDetail.phone,
        function (data) {
          _this.ResultLatestData = data.data;
          let contantList = [];
          for (let i = 0; i < _this.ResultLatestData.recordResults.length; i++) {
            if (_this.ResultLatestData.recordResults[i].pulseSymptoms == null) {
              _this.ResultLatestData.recordResults[i].pulseSymptoms = [];
            } else if (_this.ResultLatestData.recordResults[i].symptoms == null) {
              _this.ResultLatestData.recordResults[i].symptoms = [];
            } else if (_this.ResultLatestData.recordResults[i].tongueSymptoms == null) {
              _this.ResultLatestData.recordResults[i].tongueSymptoms = [];
            } else {
              console.log("123");
            }
            contantList = _this.ResultLatestData.recordResults[i].pulseSymptoms.concat(_this.ResultLatestData.recordResults[i].symptoms.concat(_this.ResultLatestData.recordResults[i].tongueSymptoms));
            _this.ResultLatestData.recordResults[i].contantLists = contantList;
            let newsyndromeSymptoms1 = [];
            for (let k = 0; k < _this.ResultLatestData.recordResults[i].syndromeSymptoms.length; k++) {
              newsyndromeSymptoms1.push({
                name: _this.ResultLatestData.recordResults[i].syndromeSymptoms[k],
                checked: false,
              });
            }
            _this.ResultLatestData.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
          }
          for (let m = 0; m < _this.ResultLatestData.recordResults.length; m++) {
            for (let n = 0; n < _this.ResultLatestData.recordResults[m].contantLists.length; n++) {
              for (let j = 0; j < _this.ResultLatestData.recordResults[m].newsyndromeSymptoms.length; j++) {
                if (_this.ResultLatestData.recordResults[m].newsyndromeSymptoms[j].name == _this.ResultLatestData.recordResults[m].contantLists[n]) {
                  _this.ResultLatestData.recordResults[m].newsyndromeSymptoms[j].checked = true;
                }
              }
            }
          }
        },
        function (error) {
          _this.ResultLatestData = [];
        }
      );
    },

    getBBResultLatestData() {
      var _this = this;
      _this.ResultLatestData = [];
      _this.DiseaseDomain.getBBResultLatest(
        _this.patientDetail.phone,
        function (data) {
          _this.ResultLatestData = data.data;
          let contantList = [];
          for (let i = 0; i < _this.ResultLatestData.recordResults.length; i++) {
            if (_this.ResultLatestData.recordResults[i].pulseSymptoms == null) {
              _this.ResultLatestData.recordResults[i].pulseSymptoms = [];
            } else if (_this.ResultLatestData.recordResults[i].symptoms == null) {
              _this.ResultLatestData.recordResults[i].symptoms = [];
            } else if (_this.ResultLatestData.recordResults[i].tongueSymptoms == null) {
              _this.ResultLatestData.recordResults[i].tongueSymptoms = [];
            } else {
              console.log("123");
            }
            contantList = _this.ResultLatestData.recordResults[i].pulseSymptoms.concat(_this.ResultLatestData.recordResults[i].symptoms.concat(_this.ResultLatestData.recordResults[i].tongueSymptoms));
            _this.ResultLatestData.recordResults[i].contantLists = contantList;
            let newsyndromeSymptoms1 = [];
            for (let k = 0; k < _this.ResultLatestData.recordResults[i].syndromeSymptoms.length; k++) {
              newsyndromeSymptoms1.push({
                name: _this.ResultLatestData.recordResults[i].syndromeSymptoms[k],
                checked: false,
              });
            }
            _this.ResultLatestData.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
          }
          for (let m = 0; m < _this.ResultLatestData.recordResults.length; m++) {
            for (let n = 0; n < _this.ResultLatestData.recordResults[m].contantLists.length; n++) {
              for (let j = 0; j < _this.ResultLatestData.recordResults[m].newsyndromeSymptoms.length; j++) {
                if (_this.ResultLatestData.recordResults[m].newsyndromeSymptoms[j].name == _this.ResultLatestData.recordResults[m].contantLists[n]) {
                  _this.ResultLatestData.recordResults[m].newsyndromeSymptoms[j].checked = true;
                }
              }
            }
          }
        },
        function (error) {
          _this.ResultLatestData = [];
        }
      );
    },
    addgetLoadPrescription(index) {
      var _this = this;
      if (_this.herbalDetail.compositions) {
        let params = [];
        for (let i = 0; i < _this.herbalDetail.compositions.length; i++) {
          let content = _this.herbalDetail.compositions[i].compositionName;
          let reg = /\[\[(.*?)\]\]/g;
          let result = [];
          while (true) {
            let itemArr = reg.exec(content);
            if (itemArr === null) {
              break;
            }
            result.push(itemArr[0]); //[[麸炒|Dict|48111]]
          }
          let longestString = ""; // 存放最长字符串的变量
          if (result.length > 1) {
            result.map((item) => {
              if (item.length > longestString.length) {
                item = longestString;
              }
            });
          } else {
            longestString = result[0]; // 存放最长字符串的变量
          }
          params.push({
            drugName: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1"),
            remark: _this.herbalDetail.compositions[i].remark,
            weight: _this.herbalDetail.compositions[i].dosage ? parseFloat(_this.herbalDetail.compositions[i].dosage) : 0,
            drugHashKey: longestString.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$3"),
            preHashKey: _this.herbalDetail.hashKey,
            materialType: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$2"),
            recommendDosage: _this.herbalDetail.compositions[i].recommendDosage ? _this.herbalDetail.compositions[i].recommendDosage : "",
          });
          params.map((item, index) => {
            if (item.drugName == item.drugHashKey || !item.drugHashKey || item.drugHashKey == "Search") {
              item.drugHashKey = "";
            } else {
              item.drugHashKey = item.drugHashKey;
            }
          });
        }
        _this.drugpatientDomain.getLoadPrescription(
          params,
          function (data) {
            _this.patientFormPre.compositionList[index].mzPrescriptionDetail = [];
            if (_this.herbalDetail.herbalPrescriptionName) {
              if (_this.patientFormPre.compositionList[index].prescriptionName.indexOf(_this.herbalDetail.herbalPrescriptionName) == -1) {
                _this.patientFormPre.compositionList[index].prescriptionName += _this.herbalDetail.herbalPrescriptionName + ",";
              }
            }
            _this.jdcompositionList = _this.jdcompositionList.concat(data.data);
            // let arr = _this.jdcompositionList;
            // for (let m = 0; m < arr.length; m++) {
            //   for (let n = m + 1; n < arr.length; n++) {
            //     if (arr[m].drugOrgName == arr[n].drugOrgName && arr[m].remark == arr[n].remark) {
            //       n = ++m;
            //     }
            //   }
            //   params1.push(arr[m]);
            // }
            const uniqueArray = _this.jdcompositionList.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
            _this.patientFormPre.compositionList[index].mzPrescriptionDetail = uniqueArray;
          },
          function (error) {
            _this.jdcompositionList = [];
          }
        );
      }
    },
    addgetLoadPrescription1(index) {
      var _this = this;
      let params1 = {};
      let priceParams = [];
      let priceParamsres = [];
      _this.MyCommonPrescripTableData.mzPrescriptionDetail.map((item, index) => {
        priceParams.push({
          drugOrgId: item.drugOrgId,
          drugOrgName: item.drugOrgName,
          remark: item.remark,
          weight: item.weight,
          decoct: item.decoct,
          recommendDosage: item.recommendDosage,
        });
      });
      params1.prescriptionName = _this.MyCommonPrescripTableData.prescriptionName;
      params1.request = _this.MyCommonPrescripTableData.request;
      params1.usage = _this.MyCommonPrescripTableData.usage;
      params1.frequency = _this.MyCommonPrescripTableData.frequency;
      params1.dosage = _this.MyCommonPrescripTableData.dosage;
      params1.dosageNumber = _this.MyCommonPrescripTableData.dosageNumber;
      params1.mzPrescriptionDetail = priceParams;
      _this.drugpatientDomain.getAddLoadPrescriptionCommon(params1, function (data) {
        data.data.mzPrescriptionDetail.map((item1, index) => {
          priceParamsres.push({
            drugOrgId: item1.drugOrgId,
            drugOrgName: item1.drugOrgName,
            remark: item1.remark,
            weight: item1.weight,
            decoct: item1.decoct,
            recommendDosage: item1.recommendDosage,
            tradingPrice: item1.tradingPrice,
            inventoryNumber: item1.drugInventoryNumber,
          });
        });
        if (_this.patientFormPre.compositionList[index].prescriptionName.indexOf(_this.MyCommonPrescripTableData.prescriptionName) == -1) {
          _this.patientFormPre.compositionList[index].prescriptionName += _this.MyCommonPrescripTableData.prescriptionName + ",";
        }
        _this.patientFormPre.compositionList[index].mzPrescriptionDetail = _this.patientFormPre.compositionList[index].mzPrescriptionDetail.concat(priceParamsres);
        const uniqueArray = _this.patientFormPre.compositionList[index].mzPrescriptionDetail.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
        _this.patientFormPre.compositionList[index].mzPrescriptionDetail = uniqueArray;
      });
    },
    addgetLoadPrescription2(index) {
      var _this = this;
      let params1 = {};
      let priceParams = [];
      let priceParamsres = [];
      _this.MyCommonPrescripTableData.mzPrescriptionDetail.map((item, index) => {
        priceParams.push({
          drugOrgId: item.drugOrgId,
          drugOrgName: item.drugOrgName,
          groupNumber: item.groupNumber,
          frequency: item.frequency,
          usage: item.usage,
          remark: item.remark,
          dosage: item.dosage,
          dosageUnit: item.dosageUnit,
          defaultDosage: item.defaultDosage,
          defaultDosageUnit: item.defaultDosageUnit,
        });
      });
      params1.prescriptionName = _this.MyCommonPrescripTableData.prescriptionName;
      params1.mzPrescriptionDetail = priceParams;
      _this.drugpatientDomain.getAddLoadPrescriptionCommon(params1, function (data) {
        data.data.mzPrescriptionDetail.map((item1, index) => {
          priceParamsres.push({
            drugOrgId: item1.drugOrgId,
            drugOrgName: item1.drugOrgName,
            groupNumber: item1.groupNumber,
            frequency: item1.frequency,
            usage: item1.usage,
            remark: item1.remark,
            dosageList: {
              dosage: item1.dosage,
              dosageUnit: item1.dosageUnit,
            },
            defaultDosageList: {
              defaultDosage: item1.defaultDosage,
              defaultDosageUnit: item1.defaultDosageUnit,
            },
            tradingPrice: item1.tradingPrice,
            inventoryNumber: item1.drugInventoryNumber,
          });
        });
        if (_this.patientFormPre.CZcompositionList[index].prescriptionName.indexOf(_this.MyCommonPrescripTableData.prescriptionName) == -1) {
          _this.patientFormPre.CZcompositionList[index].prescriptionName += _this.MyCommonPrescripTableData.prescriptionName + ",";
        }
        _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail = _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail.concat(priceParamsres);
        const uniqueArray = _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
        _this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail = uniqueArray;
      });
    },
    addgetLoadPrescription3(index) {
      var _this = this;
      let CYdata12 = {};
      let params1 = {
        prescriptionName: _this.MyCommonPrescripTableDataSY.prescriptionName,
        type: 2,
        prescriptionType: 1,
        mzPrescriptionGroup: [],
      };
      CYdata12 = {
        prescriptionName: _this.MyCommonPrescripTableDataSY.prescriptionName,
        type: 2,
        prescriptionType: 1,
        mzPrescriptionGroup: [],
      };
      _this.MyCommonPrescripTableDataSY.mzPrescriptionGroup.map((item, index) => {
        CYdata12.mzPrescriptionGroup.push({
          days: item.days,
          drippingSpeed: item.drippingSpeed,
          drippingSpeedUnit: item.drippingSpeedUnit,
          frequency: item.frequency,
          usage: item.usage,
          startTime: item.startTime,
          mzPrescriptionDetailSY: [],
        }),
          item.mzPrescriptionDetailSY.map((item1, index1) => {
            CYdata12.mzPrescriptionGroup.map((itemCY, indexCY) => {
              itemCY.mzPrescriptionDetailSY.push({
                drugOrgId: item1.drugOrgId,
                drugOrgName: item1.drugOrgName,
                st: item1.st,
                dosage: item1.dosage,
                dosageUnit: item1.dosageUnit,
                defaultDosage: item1.defaultDosage,
                defaultDosageUnit: item1.defaultDosageUnit,
              });
            });
          });
      });
      // CYdata = CYdata.concat(_this.MyCommonPrescripTableDataSY).concat(_this.patientFormPre.SYcompositionList[index].mzPrescriptionDetail)
      params1.mzPrescriptionGroup = CYdata12.mzPrescriptionGroup;
      _this.drugpatientDomain.getAddLoadPrescriptionCommon(params1, function (data) {
        let priceParamsres = [];
        data.data.mzPrescriptionGroup.map((item1, index1) => {
          item1.mzPrescriptionDetailSY.map((item, index) => {
            params1.mzPrescriptionGroup.map((item1SY) => {
              item1SY.mzPrescriptionDetailSY.map((itemSY) => {
                itemSY.inventoryNumber = item.drugInventoryNumber;
                itemSY.tradingPrice = item.tradingPrice;
              });
            });
          });
        });
        _this.patientFormPre.SYcompositionList.map((item, index) => {
          item.zucompositionList.map((itemZ) => {
            params1.mzPrescriptionGroup.map((itemRe) => {
              itemZ.mzPrescriptionTitle[0].usage = itemRe.usage;
              itemZ.mzPrescriptionTitle[0].days = itemRe.days;
              itemZ.mzPrescriptionTitle[0].frequency = itemRe.frequency;
              itemZ.mzPrescriptionTitle[0].startTime = itemRe.startTime;
              itemZ.mzPrescriptionTitle[0].drippingSpeedList = {
                drippingSpeed: itemRe.drippingSpeed,
                drippingSpeedUnit: itemRe.drippingSpeedUnit,
              };
              itemRe.mzPrescriptionDetailSY.map((itemReSY) => {
                priceParamsres.push({
                  drugOrgId: itemReSY.drugOrgId,
                  drugOrgName: itemReSY.drugOrgName,
                  st: itemReSY.st,
                  dosageList: {
                    dosage: itemReSY.dosage,
                    dosageUnit: itemReSY.dosageUnit,
                  },
                  defaultDosageList: {
                    defaultDosage: itemReSY.defaultDosage,
                    defaultDosageUnit: itemReSY.defaultDosageUnit,
                  },
                });
              });
            });
          });
        });
        if (_this.patientFormPre.SYcompositionList[index].prescriptionName.indexOf(_this.MyCommonPrescripTableDataSY.prescriptionName) == -1) {
          _this.patientFormPre.SYcompositionList[index].prescriptionName += _this.MyCommonPrescripTableDataSY.prescriptionName + ",";
        }
        for (let i = 0; i < _this.patientFormPre.SYcompositionList[index].zucompositionList.length; i++) {
          _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail = _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail.concat(priceParamsres);
          const uniqueArray = _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail.filter((item, index, self) => self.findIndex((t) => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
          _this.patientFormPre.SYcompositionList[index].zucompositionList[i].mzPrescriptionDetail = uniqueArray;
        }
      });
    },
    goherbalDetail(item) {
      var _this = this;
      _this.loading = true;
      let dishashKey = "";
      dishashKey = item.hashKey;
      _this.JChooseName = item.herbalPrescriptionName;
      _this.drugpatientDomain.getHerbalPrescriptionDetail(
        item.hashKey,
        function (data) {
          _this.herbalDetail = data.data;
          _this.herbalDetail.compositions.map((item) => {
            item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1");
            item.compositionNamehashKey = dishashKey;
            return;
          });
          _this.herbalDetail.infos.map((item, index) => {
            if (item.title == "用法") {
              _this.herbalDetail.medicineinfo = item.info;
            }
          });
          _this.loading = false;
        },
        function (error) {
          _this.herbalDetail = {};
        }
      );
    },
    inputChange() {
      this.GetHerbalPrescriptionListdata();
    },
    GetHerbalPrescriptionListdata() {
      var _this = this;
      _this.drugpatientDomain.GetHerbalPrescriptionList(
        "0",
        "1",
        _this.HerbalValue,
        function (data) {
          _this.HerbalList = data.data.results;
        },
        function (error) {
          _this.HerbalList = [];
        }
      );
    },
    goResultByUserList(index, name) {
      var _this = this;
      _this.ResultByUserIndex = index;
      let queId = 0;
      // _this.patientDetail.phone
      _this.staticsDomain.Questionnaire(
        function (data) {
          var data = data.data.filter((i) => i.name.includes(name));
          // _this.ResultByUserList = data.data.results
          queId = data[0].id;
          _this.staticsDomain.getUserResultByUser(
            _this.userInfo.id,
            "%20",
            "1",
            "%20",
            "%20",
            queId,
            _this.patientDetail.phone,
            function (data) {
              if (data.data.results.length > 0) {
                data.data.results.map((item, index) => {
                  item.questionnaireDetermines.sort((a, b) => {
                    return b.scores - a.scores;
                  });
                });
                _this.ResultByUserList = data.data.results;
              } else {
                _this.ResultByUserList = [
                  {
                    questionnaireDetermines: [],
                  },
                ];
              }
            },
            function (error) {
              _this.ResultByUserList = [
                {
                  questionnaireDetermines: [],
                },
              ];
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
    },
    getUserResultByUserData() {
      let queId = 1;
      _this.staticsDomain.getUserResultByUser(
        this.userInfo.id,
        "%20",
        "%20",
        "%20",
        queId,
        this.patientDetail.phone,
        function (data) {
          _this.ResultByUserList = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    godetailNext(item, index) {
      var _this = this;
      _this.diseaseIndex = index;
      _this.patientDomain.GetPatientDiagnoseDetail(
        item.id,
        function (data) {
          console.log(data.data);
          _this.patientFormDes.breathe = data.data.breathe ? data.data.breathe : 0;
          _this.patientFormDes.family = data.data.family ? data.data.family.toString() : "";
          _this.patientFormDes.height = data.data.height ? data.data.height : 0;
          _this.patientFormDes.heightTension = data.data.heightTension ? data.data.heightTension : 0;
          _this.patientFormDes.irritability = data.data.irritability ? data.data.irritability.toString() : "";
          _this.patientFormDes.lowTension = data.data.lowTension ? data.data.lowTension : 0;
          _this.patientFormDes.main = data.data.main ? data.data.main.toString() : "";
          _this.patientFormDes.otherCheck = data.data.otherCheck ? data.data.otherCheck.toString() : "";
          _this.patientFormDes.past = data.data.past ? data.data.past.toString() : "";
          _this.patientFormDes.pulse = data.data.pulse ? data.data.pulse : 0;
          _this.patientFormDes.temperature = data.data.temperature ? data.data.temperature : 0;
          _this.patientFormDes.weight = data.data.weight ? data.data.weight : 0;
          _this.patientFormDes.PatientId = data.data.patientId;
          _this.patientFormDes.diseaseDiagnose = data.data.diseaseDiagnose.split(",");
          _this.ShowPhotocfList = data.data.prescriptionImgList;
          _this.ShowPhotoList = data.data.diagnoseImgList;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    goAdddiseaseDiagnose(name) {
      this.patientFormDes.diseaseDiagnose.push(name);
      this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.filter((item, index, self) => {
        return self.indexOf(item) == index;
      });
    },
    //过滤html标签
    filter(html) {
      return html
        .replace(/<(?:.|\n)*?>/gm, "")
        .replace(/(&rdquo;)/g, '"')
        .replace(/&ldquo;/g, '"')
        .replace(/&mdash;/g, "-")
        .replace(/&nbsp;/g, "")
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/<[\w\s"':=\/]*/, "");
    },
    handleClose(tag) {
        this.patientFormDes.discriminatoryAnalysisList.splice(this.patientFormDes.discriminatoryAnalysisList.indexOf(tag), 1);
        this.patientFormDes.discriminatoryAnalysis=this.patientFormDes.discriminatoryAnalysisList.toString()
      },
    goResultBy(name) {
      this.serchdrugIdList.push(name);
      if(this.activerightName==3){
        this.patientFormDes.diseaseDiagnose.push(name.name);
      }else{
        this.patientFormDes.diseaseDiagnose.push(name.syndromeName);
      }
      this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.filter((item, index, self) => {
        return self.indexOf(item) == index;
      });
      let str = this.math(this.filter(this.recordResultsInfo.analysis.toString()));
      if (str) {
        this.patientFormDes.discriminatoryAnalysisList.push(str);
        this.patientFormDes.discriminatoryAnalysisList = this.patientFormDes.discriminatoryAnalysisList.filter((item, index, self) => {
          return self.indexOf(item) == index;
        });
        this.patientFormDes.discriminatoryAnalysis=this.patientFormDes.discriminatoryAnalysisList.toString()
      }

      this.setInfoDialog = false;
    },
    goResultByAnalysis(name) {
      var _this = this;
      _this.recordResultsInfo.analysis = "";
      _this.recordResultsInfo.step = "";
      _this.recordResultsInfo.item = "";
      _this.recordResultsInfo.syndromeName = "";
      _this.zdrugconsultationMain.getSelfDiagnosisSyndrome(
        name.syndromeId,
        function (data) {
          let res = data.data.syndromeInfo;
          if (res.length > 0) {
            let resInfo1 = res.filter((i) => i.title == "鉴别分析");
            if (resInfo1.length > 0) {
              _this.recordResultsInfo.analysis = resInfo1[0].info;
            }
            _this.recordResultsInfo.item = name;
            _this.recordResultsInfo.syndromeName = name.syndromeName;
            _this.setInfoDialog = true;
          } else {
            _this.$message({
              message: "未查询到数据",
              type: "error",
            });
          }
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    goResultByAnalysisXZ(item) {
      var _this = this;
      _this.recordResultsInfo.analysis = "";
      _this.recordResultsInfo.item = "";
      _this.recordResultsInfo.syndromeName = "";
      _this.testDomain.getDetailById(
        item.questionnaireResultId,
        function (data) {
          let res = data.data.infos;
          if (res.length > 0) {
            let resInfo1 = res.filter((i) => i.title == "分析");
            if (resInfo1.length > 0) {
              _this.recordResultsInfo.analysis = resInfo1[0].info;
            }
            _this.recordResultsInfo.item = item;
            _this.recordResultsInfo.syndromeName = item.name;
            _this.setInfoDialog = true;
          } else {
            _this.$message({
              message: "未查询到数据",
              type: "error",
            });
          }
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    goResultByZ(name) {
      this.zserchdrugIdList.push(name);
      this.patientFormDes.diseaseDiagnose.push(name.name);
      this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.filter((item, index, self) => {
        return self.indexOf(item) == index;
      });
    },
    // allTotalMoney(){
    //     let  allTotalMoney = 0
    //    for (let i=0; i<this.patientFormPre.compositionList.length; i++) {
    //     for (let j=0;j< this.patientFormPre.compositionList[i].mzPrescriptionDetail.length; j++) {
    //         this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice = this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice ? this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice : 0
    //         allTotalMoney += this.patientFormPre.compositionList[i].mzPrescriptionDetail[j].tradingPrice
    //     }

    //    }
    // //    this.patientFormPre.compositionList.forEach((item,index,arr)=>{
    // //     item.mzPrescriptionDetail.forEach((item1,index1,arr1)=>{
    // //             item1.tradingPrice = item1.tradingPrice ? item1.tradingPrice : 0
    // //             allTotalMoney += item1.tradingPrice
    // //     })
    // //    })
    //    return allTotalMoney
    // },
    handleSelectionChange(val) {
      let params = [];
      for (let i = 0; i < val.length; i++) {
        params.push(val[i].adviceContent + ";");
      }
      this.patientFormPre.doctorAdvice = params.toString();
      // val.forEach((item)=> {

      // })
    },
    getHerbalPrescriptionData() {
      var _this = this;
      _this.drugpatientDomain.getHerbalPrescription(
        function (data) {
          _this.AdviceList = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    goGetDoctorAdviceListData() {
      this.comDialogAdviceVisible = true;
      this.GetDoctorAdviceListData();
    },
    GetDoctorAdviceListData() {
      var _this = this;
      _this.drugpatientDomain.GetDoctorAdviceList(
        "%20",
        _this.AdvicePageIndex,
        function (data) {
          _this.AdviceList = data.data.results;
          _this.AdvicePageTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    goAddCharges() {
      let row = {
        chargeName: "",
        money: "",
      };
      this.patientFormPre.MZOtherCharges.push(row);
    },
    deleteCharges(index) {
      this.patientFormPre.MZOtherCharges.splice(index, 1);
    },
    adddialogVisible() {
      var _this = this;
      if (_this.waitAuditUserId == "0") {
        _this.$message({
          message: "暂未选择审核人",
          type: "error",
        });
        return;
      }
      let paramsMZOtherCharges = [];
      for (let h = 0; h < _this.patientFormPre.MZOtherCharges.length; h++) {
        if (_this.patientFormPre.MZOtherCharges[h].chargeName && _this.patientFormPre.MZOtherCharges[h].money) {
          paramsMZOtherCharges.push({
            patientDiagnoseId: _this.patientDiagnoseId * 1,
            diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
            chargeName: _this.patientFormPre.MZOtherCharges[h].chargeName,
            money: _this.patientFormPre.MZOtherCharges[h].money,
            orderType: 3,
          });
        }
      }
      if (_this.auditDiagnoseMoney != 0) {
        paramsMZOtherCharges.push({
          patientDiagnoseId: _this.patientDiagnoseId * 1,
          diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
          chargeName: "审方费用",
          money: _this.auditDiagnoseMoney,
          orderType: 5,
        });
      }
      if (_this.patientFormPre.isSelef == "2" && _this.patientFormPre.isSelefPrice != "0") {
        paramsMZOtherCharges.push({
          patientDiagnoseId: _this.patientDiagnoseId * 1,
          diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
          chargeName: "快递费用",
          money: _this.patientFormPre.isSelefPrice,
          orderType: 6,
        });
      }
      for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
        if (_this.patientFormPre.isSelef == "1") {
          _this.patientFormPre.compositionList[i].isSelef = true;
        } else {
          _this.patientFormPre.compositionList[i].isSelef = false;
          _this.patientFormPre.compositionList[i].takeUserName = _this.messagePatinet.addressee;
          _this.patientFormPre.compositionList[i].takePhone = _this.messagePatinet.phone;
          _this.patientFormPre.compositionList[i].regionId = _this.messagePatinet.regionId;
          _this.patientFormPre.compositionList[i].regionFull = _this.messagePatinet.regionName;
          _this.patientFormPre.compositionList[i].address = _this.messagePatinet.fullAddress;
        }
        _this.patientFormPre.compositionList[i].patientId = _this.patientDetail.id;
        _this.patientFormPre.compositionList[i].patientName = _this.patientDetail.name;
        _this.patientFormPre.compositionList[i].patientDiagnoseId = _this.patientDiagnoseId * 1;
        _this.patientFormPre.compositionList[i].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
        _this.patientFormPre.compositionList[i].patientPhone = _this.patientDetail.phone;
        _this.patientFormPre.compositionList[i].doctorAdvice = _this.patientFormPre.doctorAdvice;
        if (_this.patientFormPre.compositionList[i].isDenoction && _this.patientFormPre.compositionList[i].isdeliveryPrice != "0") {
          paramsMZOtherCharges.push({
            patientDiagnoseId: _this.patientDiagnoseId * 1,
            diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
            chargeName: "代煎",
            money: (parseFloat(_this.patientFormPre.compositionList[i].isdeliveryPrice) * parseFloat(_this.patientFormPre.compositionList[i].dosage)).toFixed(2),
            orderType: 4,
          });
        }
      }
      let resultCom = [];
      this.patientFormPre.compositionList.map((res) => {
        res.mzPrescriptionDetail.map((res1) => {
          if (res1.drugOrgName) {
            resultCom.push(res1);
          }
        });
        res.mzPrescriptionDetail = resultCom;
      });
      for (let m = 0; m < _this.patientFormPre.CZcompositionList.length; m++) {
        if (_this.patientFormPre.isSelef == "1") {
          _this.patientFormPre.CZcompositionList[m].isSelef = true;
        } else {
          _this.patientFormPre.CZcompositionList[m].isSelef = false;
          _this.patientFormPre.CZcompositionList[m].takeUserName = _this.messagePatinet.addressee;
          _this.patientFormPre.CZcompositionList[m].takePhone = _this.messagePatinet.phone;
          _this.patientFormPre.CZcompositionList[m].regionId = _this.messagePatinet.regionId;
          _this.patientFormPre.CZcompositionList[m].regionFull = _this.messagePatinet.regionName;
          _this.patientFormPre.CZcompositionList[m].address = _this.messagePatinet.fullAddress;
        }
        if (!_this.patientFormPre.CZcompositionList[m].prescriptionName) {
          _this.patientFormPre.CZcompositionList[m].prescriptionName = "成药处方1";
        }
        _this.patientFormPre.CZcompositionList[m].patientId = _this.patientDetail.id;
        _this.patientFormPre.CZcompositionList[m].patientName = _this.patientDetail.name;
        _this.patientFormPre.CZcompositionList[m].patientDiagnoseId = _this.patientDiagnoseId * 1;
        _this.patientFormPre.CZcompositionList[m].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
        _this.patientFormPre.CZcompositionList[m].patientPhone = _this.patientDetail.phone;
        _this.patientFormPre.CZcompositionList[m].doctorAdvice = _this.patientFormPre.doctorAdvice;
        for (let n = 0; n < _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail.length; n++) {
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosage;
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosageUnit;
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosage;
          _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosageUnit;
        }
      }
      let resultComCY = [];
      this.patientFormPre.CZcompositionList.map((res) => {
        res.mzPrescriptionDetail.map((res1) => {
          if (res1.drugOrgName) {
            resultComCY.push(res1);
          }
        });
        res.mzPrescriptionDetail = resultComCY;
      });
      let params = [];
      let params1 = [];
      for (let x = 0; x < _this.patientFormPre.SYcompositionList.length; x++) {
        params.push(_this.patientFormPre.SYcompositionList[x]);
      }
      for (let y = 0; y < params.length; y++) {
        params[y].mzPrescriptionGroup = params[y].zucompositionList;
        params1.push({
          mzPrescriptionDetail: params[y].mzPrescriptionDetail,
          prescriptionName: params[y].prescriptionName,
          prescriptionType: params[y].prescriptionType,
          type: params[y].type,
          mzPrescriptionGroup: [],
          // isSelef : true ,
          patientId: _this.patientDetail.id,
          patientName: _this.patientDetail.name,
          patientPhone: _this.patientDetail.phone,
          patientDiagnoseId: _this.patientDiagnoseId * 1,
          diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
          doctorAdvice: _this.patientFormPre.doctorAdvice,
          waitAuditUserId: params[y].waitAuditUserId,
          waitAuditUserName: params[y].waitAuditUserName,
          waitAuditUserPhone: params[y].waitAuditUserPhone,
        });
      }
      for (let z = 0; z < params.length; z++) {
        for (let o = 0; o < params[z].zucompositionList.length; o++) {
          for (let p = 0; p < params[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
            params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage;
            params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit;
            params[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage;
            params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit;
          }
          for (let w = 0; w < params1.length; w++) {
            params1[w].mzPrescriptionGroup.push({
              usage: params[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
              days: params[z].zucompositionList[o].mzPrescriptionTitle[0].days,
              drippingSpeed: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
              drippingSpeedUnit: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
              frequency: params[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
              startTime: params[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
              mzPrescriptionDetailSY: params[z].zucompositionList[o].mzPrescriptionDetail,
            });
          }
        }
      }
      let patientFormPreList = [];
      let patientFormPreCZList = [];
      let patientFormPreSyList = [];
      _this.patientFormPre.compositionList.forEach((item, index) => {
        if (item.prescriptionName) {
          patientFormPreList.push(item);
        }
      });
      _this.patientFormPre.CZcompositionList.forEach((item, index) => {
        patientFormPreCZList.push(item);
      });
      params1.forEach((item, index) => {
        if (_this.patientFormPre.isSelef == "1") {
          item.isSelef = true;
        } else {
          item.isSelef = false;
          item.takeUserName = _this.messagePatinet.addressee;
          item.takePhone = _this.messagePatinet.phone;
          item.regionId = _this.messagePatinet.regionId;
          item.regionFull = _this.messagePatinet.regionName;
          item.address = _this.messagePatinet.fullAddress;
        }
      });
      params1.forEach((item, index) => {
        if (item.prescriptionName) {
          patientFormPreSyList.push(item);
        }
      });
      let paramsData = patientFormPreList.concat(patientFormPreCZList).concat(patientFormPreSyList);
      if (paramsData.length == 0) {
        _this.$message({
          message: "请完善处方信息",
          type: "error",
        });
        return;
      }
      if (!_this.messagePatinet.phone && _this.patientFormPre.isSelef == "2") {
        _this.$message({
          message: "暂未填写快递信息",
          type: "error",
        });
        return;
      }
      _this.drugpatientDomain.getMomentPrescription(
        {
          mzPrescription: paramsData,
          mzOtherCharges: paramsMZOtherCharges,
        },
        function (data) {
          _this.$message({
            message: "提交审核成功",
            type: "success",
          });
          _this.$router.push({
            name: "PremangePatientList",
          });
        },
        function (error) {
          console.log(error);
        }
      );
    },
    handleSelectionChangeAudit(val) {
      var _this = this;
      _this.waitAuditUserId = val.id;
      _this.auditDiagnoseMoney = val.auditDiagnoseMoney;
      if (_this.patientFormPre.compositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
          _this.patientFormPre.compositionList[i].waitAuditUserId = val.id;
          _this.patientFormPre.compositionList[i].waitAuditUserName = val.userName;
          _this.patientFormPre.compositionList[i].waitAuditUserPhone = val.phone;
        }
      }
      if (_this.patientFormPre.CZcompositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.CZcompositionList.length; i++) {
          _this.patientFormPre.CZcompositionList[i].waitAuditUserId = val.id;
          _this.patientFormPre.CZcompositionList[i].waitAuditUserName = val.userName;
          _this.patientFormPre.CZcompositionList[i].waitAuditUserPhone = val.phone;
        }
      }
      if (_this.patientFormPre.SYcompositionList.length > 0) {
        for (let i = 0; i < _this.patientFormPre.SYcompositionList.length; i++) {
          _this.patientFormPre.SYcompositionList[i].waitAuditUserId = val.id;
          _this.patientFormPre.SYcompositionList[i].waitAuditUserName = val.userName;
          _this.patientFormPre.SYcompositionList[i].waitAuditUserPhone = val.phone;
        }
      }
    },
    handleReset() {
      if (this.$refs.esign) {
        this.$refs.esign.reset();
      }
    },

    handleGenerate() {
      let _this = this;
      _this.$refs.esign
        .generate()
        .then((res) => {
          let randnum = Math.random() * 10000000000000;
          randnum = Math.floor(randnum);
          let fileName = "zhu/" + randnum + ".jpg";
          let esignimgurlFile = _this.dataURLtoFile(res, fileName);
          let dataform = new FormData();
          dataform.append("file", esignimgurlFile);
          // Object.keys(esignimgurlFile).forEach(item => { dataform.append("file",esignimgurlFile); })
          _this
            .$axios({
              method: "POST",
              url: _this.FileUploadUrl,
              headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + _this.asstoken },
              data: dataform,
            })
            .then((res) => {
              _this.auditSignImg = res.data[0].completeFilePath;
              _this.esigndialogVisible = false;
              _this.mzPrescriptionData.map((item, index) => {
                item.auditSignImg = _this.auditSignImg;
              });
              _this.getNextCompletePrescription();
            })
            .catch((err) => {
              _this.$message.error("上传签名失败");
            });
        })
        .catch((err) => {
          _this.$message.error("请签名之后提交！");
        });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    getuploadData(params) {
      let dataform = new FormData();
      dataform.append("file", params);
      var _this = this;
      _this.patientDomain.getupload(
        dataform,
        function (data) {
          _this.$refs.esign.reset();
        },
        function (error) {
          console.log(error);
          _this.ZCPatientList = [];
        }
      );
    },
    goNextDataSuc(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramsMZOtherCharges = [];
          for (let h = 0; h < _this.patientFormPre.MZOtherCharges.length; h++) {
            if (_this.patientFormPre.MZOtherCharges[h].chargeName && _this.patientFormPre.MZOtherCharges[h].money) {
              paramsMZOtherCharges.push({
                patientDiagnoseId: _this.patientDiagnoseId * 1,
                diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
                chargeName: _this.patientFormPre.MZOtherCharges[h].chargeName,
                money: _this.patientFormPre.MZOtherCharges[h].money,
                orderType: 3,
              });
            }
          }
          if (_this.patientFormPre.isSelef == "2" && _this.patientFormPre.isSelefPrice != "0") {
            paramsMZOtherCharges.push({
              patientDiagnoseId: _this.patientDiagnoseId * 1,
              diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
              chargeName: "快递费用",
              money: _this.patientFormPre.isSelefPrice,
              orderType: 6,
            });
          }
          for (let i = 0; i < _this.patientFormPre.compositionList.length; i++) {
            if (_this.patientFormPre.isSelef == "1") {
              _this.patientFormPre.compositionList[i].isSelef = true;
            } else {
              _this.patientFormPre.compositionList[i].isSelef = false;
              _this.patientFormPre.compositionList[i].takeUserName = _this.messagePatinet.addressee;
              _this.patientFormPre.compositionList[i].takePhone = _this.messagePatinet.phone;
              _this.patientFormPre.compositionList[i].regionId = _this.messagePatinet.regionId;
              _this.patientFormPre.compositionList[i].regionFull = _this.messagePatinet.regionName;
              _this.patientFormPre.compositionList[i].address = _this.messagePatinet.fullAddress;
            }
            _this.patientFormPre.compositionList[i].patientId = _this.patientDetail.id;
            _this.patientFormPre.compositionList[i].patientName = _this.patientDetail.name;
            _this.patientFormPre.compositionList[i].patientPhone = _this.patientDetail.phone;
            _this.patientFormPre.compositionList[i].patientDiagnoseId = _this.patientDiagnoseId * 1;
            _this.patientFormPre.compositionList[i].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
            _this.patientFormPre.compositionList[i].doctorAdvice = _this.patientFormPre.doctorAdvice;
            if (_this.patientFormPre.compositionList[i].isDenoction && _this.patientFormPre.isdeliveryPrice != "0") {
              paramsMZOtherCharges.push({
                patientDiagnoseId: _this.patientDiagnoseId * 1,
                diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
                chargeName: "代煎",
                money: (parseFloat(_this.patientFormPre.compositionList[i].isdeliveryPrice) * parseFloat(_this.patientFormPre.compositionList[i].dosage)).toFixed(2),
                orderType: 4,
              });
            }
          }
          for (let m = 0; m < _this.patientFormPre.CZcompositionList.length; m++) {
            if (_this.patientFormPre.isSelef == "1") {
              _this.patientFormPre.CZcompositionList[m].isSelef = true;
            } else {
              _this.patientFormPre.CZcompositionList[m].isSelef = false;
              _this.patientFormPre.CZcompositionList[m].takeUserName = _this.messagePatinet.addressee;
              _this.patientFormPre.CZcompositionList[m].takePhone = _this.messagePatinet.phone;
              _this.patientFormPre.CZcompositionList[m].regionId = _this.messagePatinet.regionId;
              _this.patientFormPre.CZcompositionList[m].regionFull = _this.messagePatinet.regionName;
              _this.patientFormPre.CZcompositionList[m].address = _this.messagePatinet.fullAddress;
            }
            _this.patientFormPre.CZcompositionList[m].patientId = _this.patientDetail.id;
            _this.patientFormPre.CZcompositionList[m].patientName = _this.patientDetail.name;
            _this.patientFormPre.CZcompositionList[m].patientDiagnoseId = _this.patientDiagnoseId * 1;
            _this.patientFormPre.CZcompositionList[m].patientPhone = _this.patientDetail.phone;
            _this.patientFormPre.CZcompositionList[m].diseaseDiagnose = _this.patientFormDes.diseaseDiagnose;
            _this.patientFormPre.CZcompositionList[m].doctorAdvice = _this.patientFormPre.doctorAdvice;
            for (let n = 0; n < _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail.length; n++) {
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosage;
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].defaultDosageList.defaultDosageUnit;
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosage = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosage;
              _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageUnit = _this.patientFormPre.CZcompositionList[m].mzPrescriptionDetail[n].dosageList.dosageUnit;
            }
          }
          let params = [];
          let params1 = [];
          for (let x = 0; x < _this.patientFormPre.SYcompositionList.length; x++) {
            params.push(_this.patientFormPre.SYcompositionList[x]);
          }
          for (let y = 0; y < params.length; y++) {
            params[y].mzPrescriptionGroup = params[y].zucompositionList;
            params1.push({
              mzPrescriptionDetail: params[y].mzPrescriptionDetail,
              prescriptionName: params[y].prescriptionName,
              prescriptionType: params[y].prescriptionType,
              type: params[y].type,
              mzPrescriptionGroup: [],
              patientId: _this.patientDetail.id,
              patientName: _this.patientDetail.name,
              patientPhone: _this.patientDetail.phone,
              patientDiagnoseId: _this.patientDiagnoseId * 1,
              diseaseDiagnose: _this.patientFormDes.diseaseDiagnose,
              doctorAdvice: _this.patientFormPre.doctorAdvice,
            });
          }
          for (let z = 0; z < params.length; z++) {
            for (let o = 0; o < params[z].zucompositionList.length; o++) {
              for (let p = 0; p < params[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
                params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage;
                params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit;
                params[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage;
                params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit;
              }
              for (let w = 0; w < params1.length; w++) {
                params1[w].mzPrescriptionGroup.push({
                  usage: params[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
                  days: params[z].zucompositionList[o].mzPrescriptionTitle[0].days,
                  drippingSpeed: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
                  drippingSpeedUnit: params[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
                  frequency: params[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
                  startTime: params[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
                  mzPrescriptionDetailSY: params[z].zucompositionList[o].mzPrescriptionDetail,
                });
              }
            }
          }
          let patientFormPreList = [];
          let patientFormPreCZList = [];
          let patientFormPreSyList = [];
          _this.patientFormPre.compositionList.map((item, index) => {
            if (item.prescriptionName) {
              patientFormPreList.push(item);
            }
          });
          _this.patientFormPre.CZcompositionList.map((item, index) => {
            patientFormPreCZList.push(item);
          });
          params1.forEach((item, index) => {
            if (_this.patientFormPre.isSelef == "1") {
              item.isSelef = true;
            } else {
              item.isSelef = false;
              item.takeUserName = _this.messagePatinet.addressee;
              item.takePhone = _this.messagePatinet.phone;
              item.regionId = _this.messagePatinet.regionId;
              item.regionFull = _this.messagePatinet.regionName;
              item.address = _this.messagePatinet.fullAddress;
            }
          });
          params1.map((item, index) => {
            if (item.prescriptionName) {
              patientFormPreSyList.push(item);
            }
          });
          let paramsData = patientFormPreList.concat(patientFormPreCZList).concat(patientFormPreSyList);

          if (paramsData.length == 0) {
            _this.$message({
              message: "请完善处方信息",
              type: "error",
            });
            return;
          }
          if (patientFormPreList.length > 0) {
            for (let q = 0; q < patientFormPreList.length; q++) {
              for (let w = 0; w < patientFormPreList[q].mzPrescriptionDetail.length; w++) {
                let res1 = patientFormPreList[q].mzPrescriptionDetail[w];
                if (res1.weight > res1.inventoryNumber || (res1.inventoryNumber == "0" && !res1.drugInventoryNumber) || (res1.drugInventoryNumber == "0" && !res1.inventoryNumber)) {
                  _this.$message({
                    message: "请检查药品库存",
                    type: "error",
                  });
                  return;
                }
              }
            }
          }
          if (patientFormPreCZList.length > 0) {
            for (let e = 0; e < patientFormPreCZList.length; e++) {
              for (let r = 0; r < patientFormPreCZList[e].mzPrescriptionDetail.length; r++) {
                let res1 = patientFormPreCZList[e].mzPrescriptionDetail[r];
                if (res1.defaultDosageList.defaultDosage > res1.inventoryNumber || (res1.inventoryNumber == "0" && !res1.drugInventoryNumber) || (res1.drugInventoryNumber == "0" && !res1.inventoryNumber)) {
                  _this.$message({
                    message: "请检查药品库存",
                    type: "error",
                  });
                  return;
                }
              }
            }
          }
          if (patientFormPreSyList.length > 0) {
            for (let t = 0; t < patientFormPreSyList.length; t++) {
              for (let y = 0; y < patientFormPreSyList[t].mzPrescriptionGroup.length; y++) {
                for (let p = 0; p < patientFormPreSyList[t].mzPrescriptionGroup[y].mzPrescriptionDetailSY.length; p++) {
                  let res1 = patientFormPreSyList[t].mzPrescriptionGroup[y].mzPrescriptionDetailSY[p];
                  if (res1.defaultDosageList.defaultDosage > res1.inventoryNumber || (res1.inventoryNumber == "0" && !res1.drugInventoryNumber) || (res1.drugInventoryNumber == "0" && !res1.inventoryNumber)) {
                    _this.$message({
                      message: "请检查药品库存",
                      type: "error",
                    });
                    return;
                  }
                }
              }
            }
            // patientFormPreSyList.some((res)=>{
            //   res.mzPrescriptionGroup.some((res2)=>{
            //     res2.mzPrescriptionDetailSY.some((res1)=>{
            //       if (res1.defaultDosageList.defaultDosage > res1.inventoryNumber ||  (res1.inventoryNumber == '0' && !res1.drugInventoryNumber) ||  (res1.drugInventoryNumber == '0' && !res1.inventoryNumber)) {
            //         _this.$message({
            //             message: "请检查药品库存",
            //             type: "error",
            //           });
            //           return false;
            //       }
            //     })
            //   })
            // })
          }
          if (!_this.messagePatinet.phone && _this.patientFormPre.isSelef == "2") {
            _this.$message({
              message: "暂未填写快递信息",
              type: "error",
            });
            return;
          }
          _this.esigndialogVisible = true;
          _this.mzPrescriptionData = paramsData;
          _this.mzOtherChargesData = paramsMZOtherCharges;
        }
      });
    },
    getNextCompletePrescription() {
      var _this = this;
      _this.drugpatientDomain.getCompletePrescription(
        {
          mzPrescription: _this.mzPrescriptionData,
          mzOtherCharges: _this.mzOtherChargesData,
        },
        function (data) {
          // _this.$message({
          //     message: '出药成功',
          //     type: 'success'
          //     });
          _this.drugpatientDomain.getOrderDiagnoseDetail(
            _this.patientDiagnoseId,
            function (data) {
              _this.charge = data.data;
              _this.charge.payMoney = data.data.totalMoney;
              _this.getAllUserBalance();
            },
            function (error) {
              console.log(error);
            }
          );
          _this.paydialogVisible = true;

          // _this.$router.push({
          //     name:'PremangePatientList'
          // })
        },
        function (error) {
          console.log(error);
        }
      );
    },

    // 审核
    goNextData() {
      var _this = this;
      (_this.auditkeyWord = ""), (_this.departmentId = 0), _this.GetAuditDoctorData();
      _this.doctorDialogVisible = true;
      // for (let i=0; i<_this.patientFormPre.MZOtherCharges.length; i++) {
      //     _this.patientFormPre.MZOtherCharges[i].patientDiagnoseId = _this.patientDiagnoseId * 1
      //     _this.patientFormPre.MZOtherCharges[i].diagnoseResult = _this.patientFormDes.diseaseDiagnose
      // }
      // // getCompletePrescription getMomentPrescription
      // _this.drugpatientDomain.getCompletePrescription({
      //     mzPrescription: this.patientFormPre.compositionList,
      //     mzOtherCharges:this.patientFormPre.MZOtherCharges
      // },function (data) {
      //     console.log(data)
      // }, function (error) {
      //     console.log(error)
      // })
    },
    handleClick3(e) {
      if (e.label == "常用处方") {
        if (this.drugOrgName == "个人") {
          this.GetMyCommonPrescriptionData();
        } else {
          this.GetorgMyCommonPrescriptionData();
        }
      }
      if (e.label == "经典方") {
        this.GetHerbalPrescriptionListdata();
      }
      if (e.label == "推荐处方") {
        this.JChooseName = "";
        this.herbalDetail = [];
      }
    },
    handleClick2(e) {
      if (e.label == "辨证论治") {
        this.ResultLatestData = [];
        this.getBZResultLatestData();
      }
      if (e.label == "辨病论治") {
        this.ResultLatestData = [];
        this.getBBResultLatestData();
      }
      if (e.label == "虚症辨证") {
        // this.goResultByUserList(0, "中医体质");
        this.goResultByUserList(1, "中医虚证");
      }
    },
    goMyCommonPrescription() {
      this.GetMyCommonPrescriptionData();
    },
    goMyCommonPrescrip(itemMyCommon, index) {
      this.MyCommonindex = index;
      this.GChooseName = itemMyCommon.prescriptionName;
      if (this.tab3ClickData != 2) {
        this.MyCommonPrescripTableData = itemMyCommon;
      } else {
        this.MyCommonPrescripTableDataSY = itemMyCommon;
      }
    },
    MyCommonKeywordInput() {
      if (this.drugOrgName == "个人") {
        this.GetMyCommonPrescriptionData();
      } else {
        this.GetorgMyCommonPrescriptionData();
      }
    },
    MyCommonCurrentChange(pageNum) {
      if (this.drugOrgName == "个人") {
        this.MyCommoncurrentPage = pageNum;
        this.MyCommonPageindex = pageNum;
        this.GetMyCommonPrescriptionData();
      } else {
        this.MyCommoncurrentPage = pageNum;
        this.MyCommonPageindex = pageNum;
        this.GetorgMyCommonPrescriptionData();
      }
    },
    // 获取个人常用处方
    GetMyCommonPrescriptionData() {
      let type = 0;
      var _this = this;
      if (_this.tab3ClickData == 0) {
        type = 1;
      }
      if (_this.tab3ClickData == 1) {
        type = 2;
      }
      if (_this.tab3ClickData == 2) {
        type = 3;
      }
      let keyword = _this.MyCommonKeyword ? _this.MyCommonKeyword : "%20";
      _this.drugpatientDomain.GetMyCommonPrescription(
        type,
        keyword,
        _this.MyCommonPageindex,
        15,
        function (data) {
          _this.MyCommonindex = -1;
          _this.MyCommonPrescriptionList = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    GetorgMyCommonPrescriptionData() {
      let type = -1;

      var _this = this;
      if (_this.tab3ClickData == 0) {
        type = 0;
      }
      if (_this.tab3ClickData == 1) {
        type = 1;
      }
      if (_this.tab3ClickData == 2) {
        type = 2;
      }
      let keyword = _this.MyCommonKeyword ? _this.MyCommonKeyword : "%20";
      _this.drugpatientDomain.GetPrecriptionListOrg(
        type,
        keyword,
        _this.MyCommonPageindex,
        function (data) {
          _this.MyCommonPrescriptionList = data.data.results;
          _this.MyCommonindex = -1;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    addpatientFormPreForm(indexCom) {
      var _this = this;
      let params = _this.patientFormPre.compositionList[indexCom];
      if (_this.patientFormPre.compositionList[indexCom].prescriptionName == "") {
        _this.$message({
          message: "暂未填写处方名称",
          type: "error",
        });
        return;
      }
      params.mzPrescriptionDetail.map((item, idnex) => {
        item.drugOrgId = item.drugOrgId ? item.drugOrgId : null;
      });
      _this.drugpatientDomain.getAddMZPrecriptionBatch(
        params,
        function (data) {
          _this.$message({
            message: "已存为常用处方",
            type: "success",
          });
        },
        function (error) {
          _this.$message({
            message: "请查看填写数据",
            type: "error",
          });
        }
      );
    },
    addCyFormPreForm(index) {
      var _this = this;
      if (_this.patientFormPre.CZcompositionList[index].prescriptionName == "") {
        _this.$message({
          message: "暂未填写处方名称",
          type: "error",
        });
        return;
      }
      let params = _this.patientFormPre.CZcompositionList[index];
      for (let i = 0; i < params.mzPrescriptionDetail.length; i++) {
        params.mzPrescriptionDetail[i].defaultDosage = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosage;
        params.mzPrescriptionDetail[i].defaultDosageUnit = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosageUnit;
        params.mzPrescriptionDetail[i].dosage = params.mzPrescriptionDetail[i].dosageList.dosage;
        params.mzPrescriptionDetail[i].dosageUnit = params.mzPrescriptionDetail[i].dosageList.dosageUnit;
      }
      _this.drugpatientDomain.getAddMZPrecriptionBatch(
        params,
        function (data) {
          _this.$message({
            message: "已存为常用处方",
            type: "success",
          });
        },
        function (error) {
          _this.$message({
            message: "请查看填写数据",
            type: "error",
          });
          console.log(error);
        }
      );
    },
    addSYFormPreForm(index) {
      var _this = this;
      console.log(_this.patientFormPre.SYcompositionList[index]);
      if (_this.patientFormPre.SYcompositionList[index].prescriptionName == "") {
        _this.$message({
          message: "暂未填写处方名称",
          type: "error",
        });
        return;
      }
      let params = _this.patientFormPre.SYcompositionList[index];
      params.mzPrescriptionDetail = [];
      params.mzPrescriptionGroup = params.zucompositionList;
      let params1 = {
        mzPrescriptionDetail: params.mzPrescriptionDetail,
        prescriptionName: params.prescriptionName,
        prescriptionType: params.prescriptionType,
        type: params.type,
        mzPrescriptionGroup: [],
      };
      for (let i = 0; i < params.zucompositionList.length; i++) {
        for (let j = 0; j < params.zucompositionList[i].mzPrescriptionDetail.length; j++) {
          params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosage = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage;
          params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit;
          params.zucompositionList[i].mzPrescriptionDetail[j].dosage = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosage;
          params.zucompositionList[i].mzPrescriptionDetail[j].dosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosageUnit;
        }
        params1.mzPrescriptionGroup.push({
          usage: params.zucompositionList[i].mzPrescriptionTitle[0].usage,
          days: params.zucompositionList[i].mzPrescriptionTitle[0].days,
          drippingSpeed: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
          drippingSpeedUnit: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
          frequency: params.zucompositionList[i].mzPrescriptionTitle[0].frequency,
          startTime: params.zucompositionList[i].mzPrescriptionTitle[0].startTime,
          mzPrescriptionDetailSY: params.zucompositionList[i].mzPrescriptionDetail,
        });
      }
      _this.drugpatientDomain.getAddMZPrecriptionBatch(
        params1,
        function (data) {
          _this.$message({
            message: "已存为常用处方",
            type: "success",
          });
          console.log(data);
        },
        function (error) {
          _this.$message({
            message: "请查看填写数据",
            type: "error",
          });
          console.log(error);
        }
      );
    },
    MyCommonConstituteChange(val) {
      if (val == "机构") {
        this.GetorgMyCommonPrescriptionData();
      } else {
        this.GetMyCommonPrescriptionData();
      }
    },
    ConstituteChange(val, item) {
      this.EditDrugCompositio.map((res) => {
        if (res.drugOrgId == val) {
          item.drugOrgId = res.drugOrgId;
          item.drugOrgName = res.drugOrgName1;
          item.inventoryNumber = res.drug.inventoryNumber;
          item.tradingPrice = res.drug.tradingPrice;
          item.recommendDosage = res.drug.mzDrug.recommendDosage ? res.drug.mzDrug.recommendDosage : "";
          item.dosage = res.drug.dosage ? res.drug.dosage : "";
          item.usage = res.drug.usage ? res.drug.usage : "";
          item.remark = res.drug.remark ? res.drug.remark : "";
          item.hashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          item.frequency = res.drug.frequency;
          item.dosageList.dosageUnit = res.drug.dosageUnit ? res.drug.dosageUnit : "";
          item.dosageList.dosage = res.drug.dosage ? res.drug.dosage : "";
          item.defaultDosageList.defaultDosage = res.drug.defaultDosage ? res.drug.defaultDosage : "";
          item.defaultDosageList.defaultDosageUnit = res.drug.isSeparate ? res.drug.separateNumberUnit : res.drug.mzDrug.defaultDosageUnit;
        }
      });
    },
    handleFocus() {
      this.EditDrugCompositio = [];
    },
    SelectComposition(val) {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
        val,
        function (data) {
          _this.EditDrugCompositio = data.data.map(function (item) {
            // var label = `[${item.categoryName}]${item.compositionName}`;
            var label = `${item.mzDrug.drugName}（${item.mzDrug.specification}/${item.mzDrug.specificationUnit}-${item.mzDrug.productFactory}）`;
            // var label = `${item.mzDrug.drugName}`;
            // if (item.compositionAlias) label += `${item.compositionAlias}`;
            return {
              drugOrgName1: item.mzDrug.drugName,
              drugOrgName: label,
              drugOrgId: item.id,
              recommendDosage: item.mzDrug.recommendDosage,
              drug: item,
              inventoryNumber: item.inventoryNumber,
            };
          });
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      );
    },
    gonaddPre(index) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosage: "",
        dosageUnit: "g",
        usage: "",
        weight: "",
        remark: "",
        decoct: "",
        recommendDosage: "",
      };
      this.patientFormPre.compositionList[index].mzPrescriptionDetail.push(row);
    },
    gondeletePre(index1, index) {
      // if(this.patientFormPre.compositionList[index1].mzPrescriptionDetail.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      let map = this.patientFormPre.compositionList[index1].mzPrescriptionDetail[index];
      let indexNum = this.jdcompositionList.indexOf(map);
      if (indexNum > -1) {
        this.jdcompositionList.splice(indexNum, 1);
      }
      this.patientFormPre.compositionList[index1].mzPrescriptionDetail.splice(index, 1);
    },
    gonaddPreCY(index) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
        groupNumber: "",
        frequency: "",
        usage: "",
        remark: "",
      };
      this.patientFormPre.CZcompositionList[index].mzPrescriptionDetail.push(row);
    },
    gondeletePreCY(index1, index) {
      // if(this.patientFormPre.CZcompositionList[index1].mzPrescriptionDetail.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.CZcompositionList[index1].mzPrescriptionDetail.splice(index, 1);
    },
    goaddSYList() {
      let row = {
        prescriptionName: "",
        type: 2,
        prescriptionType: 1,
        zucompositionList: [
          {
            prescriptionName: "组",
            mzPrescriptionTitle: [
              {
                usage: "",
                frequency: "",
                drippingSpeedList: {
                  drippingSpeed: "",
                  drippingSpeedUnit: "",
                },
                startTime: "",
                days: "",
              },
            ],
            mzPrescriptionDetail: [
              {
                drugOrgId: "",
                drugOrgName: "",
                st: "",
                dosageList: {
                  dosage: "",
                  dosageUnit: "",
                },
                defaultDosageList: {
                  defaultDosage: "",
                  defaultDosageUnit: "",
                },
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                st: "",
                dosageList: {
                  dosage: "",
                  dosageUnit: "",
                },
                defaultDosageList: {
                  defaultDosage: "",
                  defaultDosageUnit: "",
                },
              },
              {
                drugOrgId: "",
                drugOrgName: "",
                st: "",
                dosageList: {
                  dosage: "",
                  dosageUnit: "",
                },
                defaultDosageList: {
                  defaultDosage: "",
                  defaultDosageUnit: "",
                },
              },
            ],
          },
        ],
      };
      this.patientFormPre.SYcompositionList.push(row);
    },
    godeleteSYList(index) {
      // if(this.patientFormPre.SYcompositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.SYcompositionList.splice(index, 1);
    },
    goaddSYzuList(index) {
      let row = {
        prescriptionName: "组",
        mzPrescriptionTitle: [
          {
            usage: "",
            frequency: "",
            drippingSpeedList: {
              drippingSpeed: "",
              drippingSpeedUnit: "",
            },
            startTime: "",
            days: "",
          },
        ],
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
        ],
      };
      this.patientFormPre.SYcompositionList[index].zucompositionList.push(row);
    },
    godeleteSYzuList(index1, index) {
      // if(this.patientFormPre.SYcompositionList[index1].zucompositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.SYcompositionList[index1].zucompositionList.splice(index, 1);
    },
    goaddSYzuDrugList(index1, indexComSY1) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        st: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
      };
      this.patientFormPre.SYcompositionList[index1].zucompositionList[indexComSY1].mzPrescriptionDetail.push(row);
    },
    godeletSYzuDrugList(index1, index2, index) {
      // if(this.patientFormPre.SYcompositionList[index1].zucompositionList[index2].mzPrescriptionDetail.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.SYcompositionList[index1].zucompositionList[index2].mzPrescriptionDetail.splice(index, 1);
    },
    goaddCzcompositionList() {
      let row = {
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
            groupNumber: "",
            frequency: "",
            usage: "",
            remark: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            frequency: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
            groupNumber: "",
            usage: "",
            remark: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            frequency: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
            groupNumber: "",
            usage: "",
            remark: "",
          },
        ],
        prescriptionName: "",
        type: 1,
        prescriptionType: 1,
      };
      this.patientFormPre.CZcompositionList.push(row);
    },
    godeleteCzList(index) {
      // if(this.patientFormPre.CZcompositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.patientFormPre.CZcompositionList.splice(index, 1);
    },
    goAddcompositionList() {
      let row = {
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct: "",
            recommendDosage: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct: "",
            recommendDosage: "",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct: "",
            recommendDosage: "",
          },
        ],
        usage: "",
        remark: "",
        dosage: "",
        dosageNumber: "",
        frequency: "",
        prescriptionName: "",
        request: "",
        prescriptionType: 1,
        isDenoction: true,
        isdeliveryPrice: 0,
        // patientDiagnoseId:''
      };
      this.patientFormPre.compositionList.push(row);
      this.getcheckItemstDataPreD();
    },
    godeleteCompositionList(index) {
      // if(this.patientFormPre.compositionList.length == 1) {
      //     this.$message({
      //         message: '至少保留一项数据',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.jdcompositionList = [];
      this.patientFormPre.compositionList[index].mzPrescriptionDetail = [];
      this.patientFormPre.compositionList.splice(index, 1);
    },
    getMZDiagnoseListPatientData() {
      var _this = this;
      _this.patientDomain.getMZDiagnoseListPatient(
        _this.ZCDiagnoseindex,
        function (data) {
          _this.ZCPatientList = data.data.results;
          for (var i = 0; i < _this.ZCPatientList.length; i++) {
            var f = _this.ZCPatientList[i].addTime.split("T");
            _this.ZCPatientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
          _this.ZCpatientTotal = data.data.dataTotal;
          _this.ZCpatientSize = data.data.pageSize;
        },
        function (error) {
          console.log(error);
          _this.ZCPatientList = [];
        }
      );
    },
    AuditCurrentChange(pageNum) {
      this.AuditcurrentPage = pageNum;
      this.auditPageIndex = pageNum;
      this.GetAuditDoctorData();
    },
    AdvicCurrentChange(pageNum) {
      this.AdvicurrentPage = pageNum;
      this.AdvicePageIndex = pageNum;
      this.GetDoctorAdviceListData();
    },
    ZChandleCurrentChange(pageNum) {
      this.ZCpatientcurrentPage = pageNum;
      this.ZCDiagnoseindex = pageNum;
      this.getMZDiagnoseListPatientData();
    },
    DiagnoseImagePreview(file) {
      this.Image.dialogImageUrl = file.url;
      this.Image.dialogVisible = true;
    },
    DiagnoseImageRemove(file, fileList) {
      var _this = this;
      const filePath = file.response[0].completeFilePath;
      //2.从pics数组中找到图片对应的索引值
      //形参‘x’，是数组pic里的每一项
      const i = _this.DiagnoseContent.findIndex((x) => x.completeFilePath == filePath);
      //调用数组的splice方法，把图片的信息对象从pics数组中移除
      _this.DiagnoseContent.splice(i, 1);
      _this.patientFormDes.DiagnoseImgList = _this.DiagnoseContent;
    },
    DiagnoseImageUpload(response, file, fileList) {
      var _this = this;
      response.forEach(function (file) {
        var imgarr = {
          filePath: file.completeFilePath,
          fileName: file.fileName,
          fileHash: file.fileHash,
          fileExt: file.fileExtension,
        };

        _this.DiagnoseContent.push(imgarr);
      });
      _this.patientFormDes.DiagnoseImgList = _this.DiagnoseContent;
    },
    goShowPhoto() {
      this.ShowPhoto = !this.ShowPhoto;
    },
    goShowPhotocf() {
      this.ShowPhotocf = !this.ShowPhotocf;
    },
    goShowPhotocfCliclk(e) {
      console.log(e);
      this.fitDialogVisible = true;
      this.fitDialogVisibleImgUrl = e.filePath;
    },
    goShowtemperature() {
      this.Showtemperature = !this.Showtemperature;
    },
    InputcheckItemsNum(e) {
      e.tradingPrice = Number(e.checkItemsNum) * e.checkItemValue.tradingPrice;
    },
    checkItemsChange(e) {
      e.tradingPrice = e.checkItemValue.tradingPrice;
    },
    goaddcheckItem() {
      this.checkItemsList.push({
        itemcheckItems: "",
        checkItemsNum: 1,
        tradingPrice: 0,
      });
    },
    godetcheckItem(index) {
      this.checkItemsList.splice(index, 1);
    },
    submitCheckItemsVisible() {
      var _this = this;
      let params = {
        OrgId: _this.userInfo.organizationId,
        OrgName: _this.userInfo.organizationName,
        PatientId: _this.patientDetail.id,
        PatientName: _this.patientDetail.name,
        DoctorId: _this.userInfo.id,
        DoctorName: _this.userInfo.name,
        PatientDiagnoseId: "",
        DiagnoseResult: "",
        OrderType: 1,
        TotalMoney: 0,
        Details: [],
      };
      for (var i = 0; i < _this.checkItemsList.length; i++) {
        params.Details.push({
          OrderType: 2,
          EntityId: _this.checkItemsList[i].checkItemValue.id,
          EntityName: _this.checkItemsList[i].checkItemValue.itemName,
          Amount: _this.checkItemsList[i].checkItemsNum,
          Unit: "次",
          Price: _this.checkItemsList[i].checkItemValue.marketPrice,
          Money: _this.checkItemsList[i].checkItemValue.tradingPrice,
          CountMoney: _this.checkItemsList[i].tradingPrice,
        });
        params.TotalMoney += _this.checkItemsList[i].tradingPrice;
      }
      _this.showCheckItemsList = _this.checkItemsList;
      if (_this.isAlready == true) {
        params.PatientDiagnoseId = _this.AlreadyId;
        _this.patientDomain.checkItemAlreadyAdd(
          params,
          function (data) {
            _this.checkItemResult = data.data;
            _this.isCheckItem = true;
            _this.checkItemsVisible = false;
            _this.checkItemsList = [
              {
                value: "",
                checkItemsNum: 1,
                tradingPrice: 0,
              },
            ];
          },
          function (error) {
            console.log(error);
          }
        );
      } else {
        _this.patientDomain.checkItemAdd(
          params,
          function (data) {
            _this.checkItemResult = data.data;
            _this.isCheckItem = true;
            _this.checkItemsVisible = false;
            _this.checkItemsList = [
              {
                value: "",
                checkItemsNum: 1,
                tradingPrice: 0,
              },
            ];
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    goCheckItems() {
      this.checkItemsVisible = true;
      this.checkItemsList = [
        {
          value: "",
          checkItemsNum: 1,
          tradingPrice: 0,
        },
      ];
      this.getcheckItemstData();
    },
    gotodayChange(e) {
      this.todayStepIndex = e;
      if (e == 2) {
        this.getMZDiagnoseListPatientData();
      } else {
        this.getTodayPatientData();
      }
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.patientPageIndex = pageNum;
      this.getPatientListdata();
    },
    patientSearch() {
      this.patientPageIndex = 1;
      this.getPatientListdata();
    },
    getcheckItemstDataPreD() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        "%20",
        "4",
        function (data) {
          _this.getcheckItemstDataPreK();
          _this.patientFormPre.compositionList.map((res) => {
            res.isdeliveryPrice = data.data[0].tradingPrice;
          });
        },
        function (error) {
          _this.getcheckItemstDataPreK();
          _this.patientFormPre.compositionList.map((res) => {
            res.isdeliveryPrice = 0;
          });
        }
      );
    },
    getcheckItemstDataPreK() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        "%20",
        "6",
        function (data) {
          _this.patientFormPre.isSelefPrice = data.data[0].tradingPrice;
        },
        function (error) {
          _this.patientFormPre.isSelefPrice = 0;
        }
      );
    },
    getcheckItemstData() {
      var _this = this;
      _this.drugpatientDomain.GetCheckItemsTop10(
        _this.checkItemsKeyWord,
        "0",
        function (data) {
          _this.checkItemsOptions = data.data;
        },
        function (error) {
          console.log(error);
          _this.checkItemsOptions = [];
        }
      );
    },
    getPatientListdata() {
      var _this = this;
      _this.patientList = [];
      _this.patientDomain.getPatientList(
        _this.patientType,
        _this.organitionId,
        _this.userInfo.id,
        "",
        "",
        _this.patientKeyWord,
        _this.patientPageIndex,
        function (data) {
          _this.patientList = data.data.results;
          for (var i = 0; i < _this.patientList.length; i++) {
            var f = _this.patientList[i].addTime.split("T");
            _this.patientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
          _this.patientTotal = data.data.dataTotal;
          _this.gettWaitData();
        },
        function (error) {
          _this.gettWaitData();
          console.log(error);
        }
      );
    },
    goAddPatientSex() {
      this.patientForm.sex = !this.patientForm.sex;
    },
    getTodayPatientData() {
      var _this = this;
      _this.todayPatientList = [];
      let keyword = _this.todayPatient ? _this.todayPatient : "%20";
      _this.patientDomain.getTodayPatient(
        keyword,
        function (data) {
          _this.AllMessages();
          _this.todayPatientList = data.data;
          for (var i = 0; i < _this.todayPatientList.length; i++) {
            var f = _this.todayPatientList[i].latestTime.split("T");
            _this.todayPatientList[i].latestTime = f[1].slice(0, 8);
          }
        },
        function (error) {
          _this.AllMessages();
          console.log(error);
          _this.todayPatientList = [];
        }
      );
    },
    depvalueChange(e) {
      this.departmentId = e;
    },
    auditkeySearch() {
      this.GetAuditDoctorData();
    },
    auditkeyOption() {
      var _this = this;
      _this.patientDomain.departmentDoctor(
        _this.userInfo.organizationId,
        function (data) {
          _this.depoptions = data.data;
        },
        function (error) {
          console.log(error);
          _this.AuditDoctorList = [];
        }
      );
    },
    GetAuditDoctorData() {
      var _this = this;
      _this.patientDomain.GetAuditDoctor(
        _this.auditkeyWord,
        _this.departmentId ? _this.departmentId : "0",
        _this.auditPageIndex,
        function (data) {
          _this.auditkeyOption();
          _this.AuditDoctorList = data.data.results;
          _this.auditTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
          _this.auditkeyOption();
          _this.AuditDoctorList = [];
        }
      );
    },
    addPatientForm(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.patientForm.userId = _this.userInfo.id;
          _this.patientForm.PatientType = 1;
          _this.patientDomain.patientAdd(
            _this.patientForm,
            function (data) {
              // _this.patientList = data.data.results;
              // (_this.patientForm = {
              //   name: "",
              //   age: "",
              //   phone: "",
              //   idNo: "",
              //   address: "",
              //   sex: true,
              // }),
              _this.patientPageIndex = 1;
              _this.getPatientListdata();
              _this.stepIndex = 2;
              if (_this.patientForm.diseaseName) {
                _this.patientFormDes.past = _this.patientForm.diseaseName;
              }
              _this.patientDomain.GetPatientDetail(
                data.data,
                function (data) {
                  if (data.data.patientDiagnose.length > 0) {
                    for (let i = 0; i < data.data.patientDiagnose.length; i++) {
                      let f = data.data.patientDiagnose[i].addTime.split("T");
                      data.data.patientDiagnose[i].addTime = f[0];
                    }
                  }
                  _this.patientDetail = data.data;
                  _this.getBZResultLatestData();
                },
                function (error) {
                  console.log(error);
                  _this.todayPatientList = [];
                }
              );
            },
            function (error) {
              console.log(error);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goaddPatientForm() {
      var _this = this;
      var params = {};
      params.userId = _this.userInfo.id;
      params.PatientType = 1;
      params.name = _this.patientForm.name;
      params.age = _this.patientForm.age;
      params.phone = _this.patientForm.phone;
      params.idNo = _this.patientForm.idNo;
      params.address = _this.patientForm.address;
      params.sex = _this.patientForm.sex;
      params.name = _this.patientForm.name;
      params.diseaseName = _this.patientForm.diseaseName;
      params.id = _this.patientForm.id;
      _this.patientDomain.patientEdit(
        params,
        function (data) {
          _this.stepIndex = 2;
          if (_this.patientForm.diseaseName) {
            _this.patientFormDes.past = _this.patientForm.diseaseName;
          }
          _this.getBZResultLatestData();
        },
        function (error) {
          console.log(error);
        }
      );
    },
    alreadySubmitForm() {
      var _this = this;
      _this.patientFormDes.PatientId = _this.patientDetail.id;
      _this.patientFormDes.height = _this.patientFormDes.height ? _this.patientFormDes.height : 0;
      _this.patientFormDes.heightTension = _this.patientFormDes.heightTension ? _this.patientFormDes.heightTension : 0;
      _this.patientFormDes.lowTension = _this.patientFormDes.lowTension ? _this.patientFormDes.lowTension : 0;
      _this.patientFormDes.breathe = _this.patientFormDes.breathe ? _this.patientFormDes.breathe : 0;
      _this.patientFormDes.pulse = _this.patientFormDes.pulse ? _this.patientFormDes.pulse : 0;
      _this.patientFormDes.temperature = _this.patientFormDes.temperature ? _this.patientFormDes.temperature : 0;
      _this.patientFormDes.weight = _this.patientFormDes.weight ? _this.patientFormDes.weight : 0;
      _this.ShowPhotocfList = _this.patientFormDes.PrescriptionImgList;
      _this.ShowPhotoList = _this.patientFormDes.DiagnoseImgList;
        let params = [];
        _this.patientFormDes.diseaseDiagnose.map((item, index) => {
          if (item.diseaseName) {
            params.push(item.diseaseName);
          } else {
            params.push(item);
          }
        });
        _this.patientFormDes.diseaseDiagnose = params.toString();
      if (_this.isCheckItem) {
        _this.patientFormDes.Id = Number(_this.checkItemResult);
        _this.patientDomain.EditMZDDiagnose(
          _this.patientFormDes,
          function (data) {
            _this.AlreadyId = Number(data.data);
            _this.isAlready = true;
            _this.$message({
              message: "暂存成功",
              type: "success",
            });
            _this.getMZDiagnoseListPatientData();
            _this.stepIndex = 1;
          },
          function (error) {
            console.log(error);
          }
        );
      } else {
        _this.patientFormDes.diseaseDiagnose = params.toString();
        _this.patientDomain.AddMZDDiagnose(
          _this.patientFormDes,
          function (data) {
            _this.AlreadyId = data.data;
            _this.isAlready = true;
            _this.$message({
              message: "暂存成功",
              type: "success",
            });
            _this.isCheckItem = false;
            _this.stepIndex = 1;
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    addSubmitForm(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.patientFormDes.PatientId = _this.patientDetail.id;
          let params = [];
          // if (_this.serchdrugIdList.length > 0) {
          //     _this.serchdrugIdList.map((item,index)=>{
          //         params.push(item.syndromeName)
          //     })
          //     this.getResultDrugsData()
          // }
          // if (_this.zserchdrugIdList.length > 0) {
          //     _this.zserchdrugIdList.map((item,index)=>{
          //         params.push(item.name)
          //     })
          //     this.getzResultDrugsData()
          // }

          _this.patientFormDes.height = _this.patientFormDes.height ? _this.patientFormDes.height : 0;
          _this.patientFormDes.heightTension = _this.patientFormDes.heightTension ? _this.patientFormDes.heightTension : 0;
          _this.patientFormDes.lowTension = _this.patientFormDes.lowTension ? _this.patientFormDes.lowTension : 0;
          _this.patientFormDes.breathe = _this.patientFormDes.breathe ? _this.patientFormDes.breathe : 0;
          _this.patientFormDes.pulse = _this.patientFormDes.pulse ? _this.patientFormDes.pulse : 0;
          _this.patientFormDes.temperature = _this.patientFormDes.temperature ? _this.patientFormDes.temperature : 0;
          _this.patientFormDes.weight = _this.patientFormDes.weight ? _this.patientFormDes.weight : 0;
          _this.ShowPhotocfList = _this.patientFormDes.PrescriptionImgList;
          _this.ShowPhotoList = _this.patientFormDes.DiagnoseImgList;
          _this.patientFormDes.diseaseDiagnose.map((item, index) => {
            if (item.diseaseName) {
              params.push(item.diseaseName);
            } else {
              params.push(item);
            }
          });
          _this.patientFormDes.diseaseDiagnose = params.toString();
          if (_this.isCheckItem) {
            _this.patientDiagnoseId = Number(_this.checkItemResult);
            _this.patientFormDes.Id = Number(_this.checkItemResult);
            _this.patientDomain.EditMZDDiagnose(
              _this.patientFormDes,
              function (data) {
                _this.getzResultDrugsData();
                _this.stepIndex = 3;
              },
              function (error) {
                console.log(error);
              }
            );
          } else {
            _this.patientDomain.AddMZDDiagnose(
              _this.patientFormDes,
              function (data) {
                _this.patientDiagnoseId = data.data;
                _this.getzResultDrugsData();
                _this.stepIndex = 3;
              },
              function (error) {
                console.log(error);
              }
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goNextpatientStep(item) {
      var _this = this;
      //  _this.stepIndex = 2
      _this.patientDomain.GetPatientDetail(
        item.id,
        function (data) {
          if (data.data.patientDiagnose.length > 0) {
            for (let i = 0; i < data.data.patientDiagnose.length; i++) {
              let f = data.data.patientDiagnose[i].addTime.split("T");
              data.data.patientDiagnose[i].addTime = f[0];
            }
          }
          _this.patientDetail = data.data;
          _this.patientForm = data.data;
        },
        function (error) {
          console.log(error);
          _this.patientDetail = [];
        }
      );
    },
    //详情获取
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
    goNextStep(e) {
      this.stepIndex = e;
      if (this.stepIndex == 2) {
        this.patientFormDes.diseaseDiagnose = this.patientFormDes.diseaseDiagnose.split(",");
      }
      this.getPatientListdata();
    },
    requiredBox() {
      this.resultDialogVisible = true;
    },
    goleft2Patient(e) {
      this.leftTable = e;
    },
    go3tabClick(e) {
      this.tab3ClickData = e;
      this.MyCommonPrescripTableData = [];
      this.MyCommonPrescripTableDataSY = [];
      if (this.drugOrgName == "个人") {
        this.MyCommoncurrentPage = 1;
        this.MyCommonPageindex = 1;
        this.GetMyCommonPrescriptionData();
      } else {
        this.MyCommoncurrentPage = 1;
        this.MyCommonPageindex = 1;
        this.GetorgMyCommonPrescriptionData();
      }
      if (e == 1 && this.patientFormPre.CZcompositionList.length == 0) {
        let row = {
          mzPrescriptionDetail: [
            {
              drugOrgId: "",
              drugOrgName: "",
              dosageList: {
                dosage: "",
                dosageUnit: "",
              },
              defaultDosageList: {
                defaultDosage: "",
                defaultDosageUnit: "",
              },
              groupNumber: "",
              frequency: "",
              usage: "",
              remark: "",
            },
            {
              drugOrgId: "",
              drugOrgName: "",
              frequency: "",
              dosageList: {
                dosage: "",
                dosageUnit: "",
              },
              defaultDosageList: {
                defaultDosage: "",
                defaultDosageUnit: "",
              },
              groupNumber: "",
              usage: "",
              remark: "",
            },
            {
              drugOrgId: "",
              drugOrgName: "",
              frequency: "",
              dosageList: {
                dosage: "",
                dosageUnit: "",
              },
              defaultDosageList: {
                defaultDosage: "",
                defaultDosageUnit: "",
              },
              groupNumber: "",
              usage: "",
              remark: "",
            },
          ],
          prescriptionName: "",
          type: 1,
          prescriptionType: 1,
        };
        this.patientFormPre.CZcompositionList.push(row);
      }
      if (e == 2 && this.patientFormPre.SYcompositionList.length == 0) {
        let row = {
          prescriptionName: "",
          type: 2,
          prescriptionType: 1,
          zucompositionList: [
            {
              prescriptionName: "组",
              mzPrescriptionTitle: [
                {
                  usage: "",
                  frequency: "",
                  drippingSpeedList: {
                    drippingSpeed: "",
                    drippingSpeedUnit: "",
                  },
                  startTime: "",
                  days: "",
                },
              ],
              mzPrescriptionDetail: [
                {
                  drugOrgId: "",
                  drugOrgName: "",
                  st: "",
                  dosageList: {
                    dosage: "",
                    dosageUnit: "",
                  },
                  defaultDosageList: {
                    defaultDosage: "",
                    defaultDosageUnit: "",
                  },
                },
                {
                  drugOrgId: "",
                  drugOrgName: "",
                  st: "",
                  dosageList: {
                    dosage: "",
                    dosageUnit: "",
                  },
                  defaultDosageList: {
                    defaultDosage: "",
                    defaultDosageUnit: "",
                  },
                },
                {
                  drugOrgId: "",
                  drugOrgName: "",
                  st: "",
                  dosageList: {
                    dosage: "",
                    dosageUnit: "",
                  },
                  defaultDosageList: {
                    defaultDosage: "",
                    defaultDosageUnit: "",
                  },
                },
              ],
            },
          ],
        };
        this.patientFormPre.SYcompositionList.push(row);
      }
    },
    goAdress() {
      let url = this.$router.resolve({
        name: "adressList",
      });
      window.open(url.href, "_blank");
    },
    refAdress() {
      this.AllMessages();
    },
    goComtabClick() {
      this.comDialogVisible = true;
      var _this = this;
      let peiwuList = [];
      _this.patientFormPre.compositionList.forEach((item, index) => {
        item.mzPrescriptionDetail.forEach((item1, index1) => {
          if (item1.drugOrgId) {
            if (item1.materialType) {
              peiwuList.push({
                type: item1.materialType,
                // name: item1.drugOrgName,
                // id: item1.drugOrgId,
                key: item1.drugHashKey,
              });
            } else {
              peiwuList.push({
                type: "Material",
                // name: item1.drugOrgName,
                // id: item1.drugOrgId,
                key: item1.hashKey,
              });
            }
          }
        });
      });
      _this.patientFormPre.CZcompositionList.forEach((item, index) => {
        item.mzPrescriptionDetail.forEach((item1, index1) => {
          if (item1.drugOrgId) {
            peiwuList.push({
              type: "Drug",
              // name: item1.drugOrgName,
              // id: item1.drugOrgId,
              key: item1.hashKey ? item1.hashKey : item1.preHashKey,
            });
          }
        });
      });
      _this.patientFormPre.SYcompositionList.forEach((item, index) => {
        item.zucompositionList.forEach((item1, index1) => {
          item1.mzPrescriptionDetail.forEach((item2, index2) => {
            if (item2.drugOrgId) {
              peiwuList.push({
                type: "Drug",
                // name: item2.drugOrgName,
                // id: item2.drugOrgId,
                key: item2.hashKey ? item2.hashKey : item1.preHashKey,
              });
            }
          });
        });
      });

      if (peiwuList.length > 0) {
        peiwuList.forEach((res, index) => {
          switch (res.type) {
            case "Drug": //药品
              res.type = 1;
              break;
            case "Material": //中药
              res.type = 4;
              break;
            case "HerbalPrescription": //中药
              res.type = 2;
              break;
            case "FoodMaterialPrescription": //中药
              res.type = 3;
              break;
            case "HealthyDiet": //中药
              res.type = 5;
              break;
          }
        });
      }
      const uniqueArray = peiwuList.filter((item, index, self) => self.findIndex((t) => t.key === item.key) === index);
      // if (uniqueArray.length > 2) {
      //     _this.compatibility(uniqueArray)
      // } else  {
      //     _this.$message({
      //         message: '配伍数量不超过两个',
      //         type: 'error'
      //     });
      // }
      _this.compatibility(uniqueArray);
    },
    go2ClickPatient() {
      console.log(123123);
    },
    goIndexmess(item, index) {
      this.messageindex = index;
      this.messagePatinet = item;
    },
    goIndex(index, name) {
      var _this = this;
      let params = {
        userId: _this.userInfo.id,
        answerUserName: _this.patientDetail.name,
        answerSex: _this.patientDetail.sex ? 0 : 1,
        answerAge: 0,
        answerPhone: _this.patientDetail.phone,
        diseaseName: _this.patientDetail.diseaseName,
      };
      if (_this.patientDetail.age < 7) {
        params.answerAge = 0;
      } else if (_this.patientDetail.age < 13) {
        params.answerAge = 1;
      } else if (_this.patientDetail.age < 18) {
        params.answerAge = 2;
      } else if (_this.patientDetail.age < 46) {
        params.answerAge = 3;
      } else if (_this.patientDetail.age < 70) {
        params.answerAge = 4;
      } else {
        params.answerAge = 5;
      }
      _this.testDomain.MyselfAnswerInfo(
        "%20",
        (res) => {
          let resultList = [];
          res.data.map((item, index) => {
            if (item.answerUserName == params.answerUserName && item.answerPhone == params.answerPhone && item.answerSex == params.answerSex && item.answerAge == params.answerAge && item.diseaseName == params.diseaseName) {
              resultList.push(res.data[index]);
            }
          });
          var seesParams = {
            answerUserName: _this.patientDetail.name,
            answerPhone: _this.patientDetail.phone,
            answerSex: params.answerSex,
            answerAge: params.answerAge,
            diseaseName: _this.patientDetail.diseaseName,
            type: "bianzhen",
          };
          window.sessionStorage.setItem("bianzhenchooseUser", JSON.stringify(seesParams));
          if (resultList.length == 0) {
            _this.testDomain.AddAnswerUser(params, (res) => {
              if (_this.ResultByUserIndex == "0") {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: name,
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              } else {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: "中医虚证",
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              }
            });
          } else {
            if (_this.ResultByUserIndex == "0") {
              let url = _this.$router.resolve({
                name: "startTest",
                query: {
                  titleName: name,
                },
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            } else {
              let url = _this.$router.resolve({
                name: "startTest",
                query: {
                  titleName: "中医虚证",
                },
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            }
          }
        },
        (error) => {
          if (error.status == "404") {
            _this.testDomain.AddAnswerUser(params, (res) => {
              if (_this.ResultByUserIndex == "0") {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: name,
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              } else {
                let url = _this.$router.resolve({
                  name: "startTest",
                  query: {
                    titleName: "中医虚证",
                  },
                });
                // window.open(url.href, "_blank");
                _this.isShowZD = true;
              }
            });
          }
        }
      );
      // this.$router.push({
      // name:'index'
      // })

      let url = this.$router.resolve({
        name: "startTest",
        query: {
          titleName: name,
        },
      });
      // window.open(url.href, "_blank");
      _this.isShowZD = true;
    },
    goIndexBZ() {
      var _this = this;
      let params = {
        userId: _this.userInfo.id,
        answerUserName: _this.patientDetail.name,
        answerSex: _this.patientDetail.sex ? 0 : 1,
        answerAge: 0,
        answerPhone: _this.patientDetail.phone,
        diseaseName: _this.patientDetail.diseaseName,
      };
      if (_this.patientDetail.age < 7) {
        params.answerAge = 0;
      } else if (_this.patientDetail.age < 13) {
        params.answerAge = 1;
      } else if (_this.patientDetail.age < 18) {
        params.answerAge = 2;
      } else if (_this.patientDetail.age < 46) {
        params.answerAge = 3;
      } else if (_this.patientDetail.age < 70) {
        params.answerAge = 4;
      } else {
        params.answerAge = 5;
      }
      _this.testDomain.MyselfAnswerInfo(
        "%20",
        (res) => {
          let resultList = [];
          res.data.map((item, index) => {
            if (item.answerUserName == params.answerUserName && item.answerPhone == params.answerPhone && item.answerSex == params.answerSex && item.answerAge == params.answerAge && item.diseaseName == params.diseaseName) {
              resultList.push(res.data[index]);
            }
          });
          if (resultList.length == 0) {
            _this.testDomain.AddAnswerUser(params, (res) => {
              let url = _this.$router.resolve({
                name: "zDrugStart",
                //   query:{
                //   answerUserName: _this.patientDetail.name,
                //   answerPhone:_this.patientDetail.phone,
                //   answerSex:params.answerSex,
                //   answerAge:params.answerAge,
                //   type:'menzhen'
                // }
              });
              //     window.open(url.href, "_blank");
              _this.isShowZD = true;
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
          } else {
            let url = _this.$router.resolve({
              name: "zDrugStart",
              // query:{
              //   answerUserName: _this.patientDetail.name,
              //   answerPhone:_this.patientDetail.phone,
              //   type:'menzhen'
              // }
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
            // window.open(url.href, "_blank");
            _this.isShowZD = true;
          }
        },
        (error) => {
          if (error.status == "404") {
            _this.testDomain.AddAnswerUser(params, (res) => {
              let url = _this.$router.resolve({
                name: "zDrugStart",
                //   query:{
                //   answerUserName: _this.patientDetail.name,
                //   answerPhone:_this.patientDetail.phone,
                //   type:'menzhen'
                // }
              });
              // window.open(url.href, "_blank");
              _this.isShowZD = true;
            });
            var seesParams = {
              answerUserName: _this.patientDetail.name,
              answerPhone: _this.patientDetail.phone,
              answerSex: params.answerSex,
              answerAge: params.answerAge,
              diseaseName: _this.patientDetail.diseaseName,
              type: "menzhen",
            };
            window.sessionStorage.setItem("chooseUser", JSON.stringify(seesParams));
            window.open(url.href, "_blank");
          }
        }
      );
    },
  },
};
</script>

<style scoped>
::v-deep .el-drawer1 .el-drawer__close-btn .el-icon-close:before{
content: "返回诊断" !important;
color:#00cbb5 !important;
}
/* ::v-deep .el-form-item {
    margin-bottom: 0;
} */
.ResultLateline {
  border-bottom: 1px dashed #e6e6e6;
}
.checkItemsBox {
  margin-bottom: 10px;
}
.pageBox {
  text-align: center;
  margin-top: 5px;
}
/*滚动条样式*/
.titleTopCenterRight::-webkit-scrollbar {
  width: 4px;
}

.titleTopCenterRight::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  background: #ccc;
}

.titleTopCenterRight::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  border-radius: 10px;
  background: #ffffff;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  min-height: 1080px;
  overflow: auto;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.adressListBoxTop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.adressListBox {
  width: 300px;
  padding: 10px 9px 20px 15px;
  border: 2px solid #00afb5;
  color: #00afb5;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.adressListBoxno {
  width: 300px;
  padding: 10px 9px 20px 15px;
  border: 1px solid #cccccc;
  color: #999999;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.todayTitle {
  font-size: 16px;
  color: #06add7;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  cursor: pointer;
}
.todayTitleno {
  cursor: pointer;
  font-size: 16px;
  color: #cccccc;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}
.todayTitleTh1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* text-align: center; */
}
.todayTitleTh {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.todayTitleThTtitle {
  padding: 15px;
  background: #f5f7fa;
  color: #999999;
  font-size: 14px;
}
.todayTitleThDetail {
  padding: 15px;
  color: #666666;
  font-size: 16px;
  cursor: pointer;
}
.todayTitleThDetail:hover {
  background: #f5f7fa;
}
.todayTitleThDetailR {
  padding: 15px;
  color: #666666;
  font-size: 14px;
}
.todayDetail {
  display: flex;
  flex-direction: column;
}
.todayDetailR {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: auto;
}
.thwidth {
  width: 30%;
  text-align: center;
}
.thwidthL1 {
  width: 50%;
  text-align: left;
}
.thwidthL {
  width: 50%;
  text-align: center;
}
.thwidthR {
  width: 25%;
  text-align: center;
}
.titleTopCenterCenter {
  width: 63%;
  border-radius: 6px;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sortBox {
  width: 40px;
  height: 40px;
  background: rgba(172, 209, 254, 0);
  border: 1px solid #06afd3;
  border-radius: 50%;
  font-size: 20px;
  color: #06add7;
  text-align: center;
  line-height: 40px;
}
.nosortBox {
  width: 40px;
  height: 40px;
  background: rgba(172, 209, 254, 0);
  border: 1px solid #cccccc;
  border-radius: 50%;
  font-size: 20px;
  color: #cccccc;
  text-align: center;
  line-height: 40px;
}
.sortBoximg {
  width: 180px;
  height: 30px;
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background-image: url("../../assets/img/interrogation/numbg01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.nosortBoximg {
  width: 180px;
  height: 30px;
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background-image: url("../../assets/img/interrogation/nonumbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.sortBoximg img {
  width: 100%;
  height: 100%;
}
.titleTopCenterCenterContent {
  width: 100%;
  min-height: 1080px;
  margin-top: 6px;
  background: #ffffff;
  overflow-y: auto;
}
.Content1Title {
  padding: 40px 15%;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
}
.ageInput {
  width: 80%;
}
.titleTopCenterRight {
  width: 20%;
  min-height: 1080px;
  max-height: 1080px;
  overflow-y: auto;
  background: #ffffff;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.titleTopCenterRightBox {
  padding: 30px;
}
.doctorDialogBox {
  min-height: 300px;
  display: flex;
  justify-content: space-between;
}
.resultDialogBox {
  min-height: 300px;
}
.resultDialogtopBox {
  width: 80%;
  margin-bottom: 20px;
}
.resultInput {
  width: 50%;
}
.doctorDialogleftBox {
  width: 50%;
  padding: 0 10px;
}
.doctorDialogrightBox {
  width: 50%;
  position: relative;
}
.doctorDialogTitle {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.doctorDialogContent {
  font-size: 16px;
  color: #666666;
}
.paginationBox {
  position: absolute;
  bottom: 0;
  left: 40%;
  transform: translateX(-50%);
}
.paginationBoxR {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.stepInput {
  width: 60%;
  margin-right: 10px;
}
.inputTag {
  width: 50px;
}
.inputTag1 {
  width: 30px;
}
.avatar-uploader-icon {
  font-size: 22px;
  color: #00afb5;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #b9dcdd;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #f3f9f9;
}
.avatar-uploader-icon:hover {
  font-size: 22px;
  color: #00afb5;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-color: #409eff;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.titleTopLeftTable {
  padding: 20px 35px;
  font-size: 16px;
  border-bottom: 2px solid #e1e3e6;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.notitleTopLeftTableC {
  color: #999999;
  cursor: pointer;
}
.titleTopLeftTableTag {
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #07abda, #01c4af);
  border-radius: 50%;
  margin-right: 5px;
}
.titleTopLeftTableTag1 {
  width: 4px;
  height: 4px;
  background: #f82a2a;
  border-radius: 50%;
  margin-right: 5px;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.titleSize16 {
  font-size: 16px;
}
.titleSize14 {
  font-size: 14px;
}
.titleRight2Content1 {
  position: relative;
  padding: 16px;
  padding-bottom: 30px;
  background: #f4f4f4;
  border-radius: 6px;
}
.titleRight2Contentcopy {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  color: #00afb5;
}
.titlerightBox2 {
  width: 200px;
  height: 32px;
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}
.titleRight2title {
  width: 100px;
  height: 32px;
  background: #00afb5;
  border: 1px solid #b9dcdd;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}
.notitleRight2title {
  width: 100px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: #00afb5;
  font-size: 16px;
}
.thirdrightBox {
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  font-size: 16px;
}
.firstRight3Box {
  margin-top: 10px;
  border-bottom: 1px solid #e1e3e5;
  max-height: 300px;
  overflow-y: auto;
}
.firstRight3BottomBoxDiv {
  font-size: 18px;
  color: #00afb5;
}
.firstRight3BottomBox {
  padding: 20px 0px;
}
.Content3TitleBox {
}
.Content3TitleBoxRight {
  margin: 15px 0;
  background: #fff7f7;
  border: 1px solid #f4d3d3;
  border-radius: 6px;
  text-align: right;
  padding: 10px 30px;
}
.Content3TitleBoxRightSpan {
  font-size: 24px;
  color: #ee1515;
}
.tag3 {
  width: 110px;
  height: 40px;
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
  color: #00afb5;
  margin-right: 10px;
  cursor: pointer;
}
.tag3 i {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border: 1px dashed #00a693;
  margin-right: 5px;
}
.tag3Checked {
  width: 110px;
  height: 40px;
  background: #00afb5;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
  color: #fefefe;
  margin-right: 10px;
  cursor: pointer;
}
.tag3Checked i {
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border: 1px dashed #ffffff;
  margin-right: 5px;
}
.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}
.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}
.hardClick {
  cursor: pointer;
}
.pei-bottom {
  background: #ffffff;
  padding: 30px;
  margin-top: 20px;
}
.tab-tilte {
}

.tab-tilte li {
  float: left;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-right: 15px;
}

.tab-tilte li em {
  font-style: normal;
  margin: 0 5px;
  font-size: 14px;
}

/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte li.greenbg {
  border: 1px solid #1fb7a6 !important;
}

.tab-tilte li.greenbg em {
  color: #1fb7a6 !important;
}

.tab-tilte li.active {
  border: 1px solid #f46e6e;
}

.tab-tilte li.active em {
  color: #f46e6e;
}

.tab-tilte li i {
  color: #f46e6e;
}

.tab-tilte li.active span {
  color: #f46e6e;
}

.tab-tilte li.greenbg i {
  color: #1fb7a6;
}

.tab-tilte li.greenbg span {
  color: #1fb7a6;
}

.tab-content {
  margin-top: 30px;
}
.peitab-tit {
  position: relative;
  border-top: 1px solid #e5e5e5;
  padding: 20px 15px;
}

.peitab-tit h3 {
  display: inline-block;
  width: 830px;
  font-weight: normal;
  color: #333333;
  font-size: 18px;
}

.peitab-tit h3 span {
  color: #e94343;
}

.peitab-tit .show-more {
  display: inline-block;
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}
.peitab-mes {
  display: none;
  width: 830px;
  padding: 10px 25px;
}

.peitab-mes p {
  line-height: 30px;
  color: #666666;
}

.peitab-mes .linkDetail {
  display: inline-block;
  color: #01c2ac;
  cursor: pointer;
}

.peitab-mes .linkDetail:hover {
  text-decoration: underline;
}
.infoWarn {
  position: absolute;
  bottom: -13px;
  left: 0;
}
.infoWarn1 {
  position: absolute;
  bottom: 8px;
  right: 5px;
}
.drugOrgNameBox {
  height: 50px;
}
.drugOrgNameBox ::v-deep .el-select {
  /* padding-bottom: 15px; */
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}
.patientDiv ::v-deep .el-form-item {
  margin-bottom: 22px;
}
.newChecked {
  color: #df7d1f;
}
</style>
